/* eslint-disable no-underscore-dangle */

import { GeometryGhost } from '../geodesy/geometryGhost';
import { GeodesicGeoJson } from '../geodesy/create-geodesic-geojson';
import { extDisplay } from './extDisplay';
import { IMapDraw, IMapDrawEnv } from './IMapDraw';

export class ExtSimpleSelect extends IMapDraw {

  constructor(env: IMapDrawEnv, chain?: any) {
    super(env, chain);
  }

  clickOnVertex = function(t,e) {
    this.chain?.clickOnVertex?.call(this,t,e);
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    if(typeof this.env?.event === typeof function(){}) {
      this.env.event('clickOnVertex',t,e,this);
    }
  };

  toDisplayFeatures = function(state, geojson, display) {
    extDisplay(this,state, geojson, display);
  };


}

