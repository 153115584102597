// https://ionic.io/blog/start-build-deploy-your-first-capacitor-pwa-with-ionic

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LeafletmapModule } from './leafletmap/leafletmap.module';
import { FolderPageModule } from './folder/folder.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { BabymapModule } from './babymap/babymap.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FilePickerModule } from './file-picker/file-picker.module';
import { WindowModule } from './window/window.module';
import { OpenlayerModule } from './openlayer/openlayer.module';
import { FooterComponent } from './app-templates/footer/footer.component';
import { HeaderComponent } from './app-templates/header/header.component';
import { SplitterModule } from 'primeng/splitter';
import { ToolbarModule } from 'primeng/toolbar';
import { MapPageModule } from './map-page/map-page.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ATooltipModule } from './a-tooltip/a-tooltip.module';

//import { CesiumModule } from './cesium/cesium.module';

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent],
//  entryComponents: [ATooltipComponent,AwesomeTooltipComponent],
  imports: [
    BrowserModule,
    FolderPageModule,
    OverlayModule,
    LeafletmapModule,
    BabymapModule,
    MapPageModule,
    /*CesiumModule,*/
    IonicModule.forRoot(),
    SplitterModule,
    FilePickerModule,
    ToolbarModule,
    WindowModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ATooltipModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
