/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { GeodesicCircle } from './create-geodesic-circle';
import { GeodesicLine } from './create-geodesic-line';

const STEPS = 32;
const HANDLE_BEARING = 45;

export abstract class GeometryGhost {
  static init = (geojson: Record<string, any>) => {
    const ext: Record<string, any> = {};
    const properties: Record<string, any> = { _$ext: ext };
    if (geojson.properties === undefined) {
      geojson.properties = properties;
    }
    if (geojson.properties._$ext === undefined) {
      geojson.properties._$ext = ext;
    }
  };
  static setCircle = (geojson: any, v: boolean) => {
    GeometryGhost.init(geojson);
    geojson.properties._$ext.circle = v;
  };
  static isCircle = (geojson: any): boolean =>
    geojson.properties?._$ext?.circle || geojson.properties?.user__$ext?.circle;
  static setGeodesic = (geojson: Record<string, any>, v: boolean) => {
    GeometryGhost.init(geojson);
    const ext = geojson.properties._$ext;
    ext.geodesic = v;
  };
  static isGeodesic = (geojson: any): boolean =>
    geojson.properties?._$ext?.geodesic ||
    geojson.properties?.user__$ext?.geodesic;
  static geoGhost(
    geojson: any,
    options?: { grain360?: number; steps?: number; properties?: any }
  ): any {
    if (options === undefined) {
      options = {
        steps: STEPS,
      };
    }
    if (options.steps === undefined) {
      options.steps = STEPS;
    }
    switch (geojson.type) {
      case 'FeatureCollection':
        (geojson.features as []).forEach((item) => {
          GeometryGhost.geoGhost(item, options);
        });
        break;
      case 'Feature':
        GeometryGhost.geoGhost(geojson.geometry, {
          ...options,
          properties: { ...options?.properties, ...geojson.properties },
        });
        break;
      case 'GeometryCollection':
        (geojson.geometries as []).forEach((item) => {
          GeometryGhost.geoGhost(item, {
            ...options,
            properties: { ...options?.properties, ...geojson.properties },
          });
        });
        break;
      case 'MultiPoint':
        break;
      case 'MultiLineString':
        if (GeometryGhost.isGeodesic(options)) {
          geojson.ghostCoordinates = geojson.coordinates.map((subpolygon) =>
            GeodesicLine.createGeodesicLine(subpolygon, options.steps)
          );
        }
        break;
      case 'MultiPolygon':
        if (GeometryGhost.isCircle(options)) {
          geojson.ghostCoordinates = geojson.coordinates.map((subpolygon) => {
            const center = subpolygon[0][0];
            const radius = options.properties?._$ext?.circleRadius;
            const handleBearing = HANDLE_BEARING;
            const ghostCoordinates = GeodesicCircle.createGeodesicCircle(
              center,
              radius,
              handleBearing,
              options.steps * 4
            );
            return [ghostCoordinates];
          });
        } else if (GeometryGhost.isGeodesic(options)) {
          geojson.ghostCoordinate = geojson.coordinates.map((subpolygon) =>
            subpolygon.map((ssubpolygon) =>
              GeodesicLine.createGeodesicLine(ssubpolygon, options.steps)
            )
          );
        }
        break;

      case 'LineString':
        if (GeometryGhost.isGeodesic(options)) {
          // two next line to workaround a bug at storage
          geojson.coordinates = geojson.definedCoordinates ?? geojson.coordinates;
          delete geojson.definedCoordinates;
          geojson.ghostCoordinates = GeodesicLine.createGeodesicLine(
            geojson.coordinates,
            options.steps
          );
        }
        break;
      case 'Polygon':
          // two next line to workaround a bug at storage
          geojson.coordinates = geojson.definedCoordinates ?? geojson.coordinates;
          delete geojson.definedCoordinates;
          if (GeometryGhost.isCircle(options)) {
          const center = geojson.coordinates[0][0];
          const radius = options.properties?.circleRadius;
          const handleBearing = HANDLE_BEARING;
          const ghostCoordinates = GeodesicCircle.createGeodesicCircle(
            center,
            radius,
            handleBearing,
            options.steps * 4
          );
          geojson.ghostCoordinates = [ghostCoordinates];
        } else if (GeometryGhost.isGeodesic(options)) {
          geojson.ghostCoordinates = geojson.coordinates.map((subpolygon) =>
            GeodesicLine.createGeodesicLine(subpolygon, options.steps)
          );
        }
        break;
    }
  }
  static ghostGeometryForward(geojson: any) {
    switch (geojson.type) {
      case 'FeatureCollection':
        (geojson.features as Array<any>).forEach((item) => {
          GeometryGhost.ghostGeometryForward(item);
        });
        break;
      case 'Feature':
        GeometryGhost.ghostGeometryForward(geojson.geometry);
        break;
      case 'GeometryCollection':
        (geojson.geometries as []).forEach((item) => {
          GeometryGhost.ghostGeometryForward(item);
        });
        break;
      default:
        if (geojson.coordinates && geojson.ghostCoordinates) {
          geojson.definedCoordinates = geojson.coordinates;
          geojson.coordinates = geojson.ghostCoordinates;
        }
        break;
    }
  }
  static ghostGeometryBackward(geojson: any) {
    switch (geojson.type) {
      case 'FeatureCollection':
        (geojson.features as Array<any>).forEach((item) => {
          GeometryGhost.ghostGeometryBackward(item);
        });
        break;
      case 'Feature':
        GeometryGhost.ghostGeometryBackward(geojson.geometry);
        break;
      case 'GeometryCollection':
        (geojson.geometries as []).forEach((item) => {
          GeometryGhost.ghostGeometryBackward(item);
        });
        break;
      default:
        if (geojson.coordinates && geojson.definedCoordinates) {
          geojson.coordinates = geojson.definedCoordinates;
          delete geojson.definedCoordinates;
        }
        break;
    }
  }
}
