import * as BABYLON from '@babylonjs/core';

export class BabyContext {
  canvas: HTMLCanvasElement;
  engine: BABYLON.Engine;
  scene: BABYLON.Scene;
  dimension: number; // relative size

  private hPointerMove: ((
    evt: BABYLON.IPointerEvent,
    pickInfo: BABYLON.PickingInfo,
    type: BABYLON.PointerEventTypes
  ) => void)[] = [];

  private hPointerUp: ((
    evt: BABYLON.IPointerEvent,
    pickInfo: BABYLON.PickingInfo,
    type: BABYLON.PointerEventTypes
  ) => void)[] = [];

  private hPointerDown: ((
    evt: BABYLON.IPointerEvent,
    pickInfo: BABYLON.PickingInfo,
    type: BABYLON.PointerEventTypes
  ) => void)[] = [];

  constructor(canvas: HTMLCanvasElement,dim: number) {
    // The first step is to get the reference of the canvas element from our HTML document
    this.canvas = canvas;

    // Then, load the Babylon 3D engine:
    this.engine = new BABYLON.Engine(this.canvas, true);

    // create a basic BJS Scene object
    this.scene = new BABYLON.Scene(this.engine);

    this.scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);

    this.dimension = dim;

    this.scene.onPointerMove = (
      evt: BABYLON.IPointerEvent,
      pickInfo: BABYLON.PickingInfo,
      type: BABYLON.PointerEventTypes
    ) => {
      this.hPointerMove.forEach((h) => {
        new Promise(() => h(evt, pickInfo, type));
      });
    };

    this.scene.onPointerUp = (
      evt: BABYLON.IPointerEvent,
      pickInfo: BABYLON.PickingInfo,
      type: BABYLON.PointerEventTypes
    ) => {
      this.hPointerUp.forEach((h) => {
        new Promise(() => h(evt, pickInfo, type));
      });
    };

    this.scene.onPointerDown = (
        evt: BABYLON.IPointerEvent,
        pickInfo: BABYLON.PickingInfo,
        type: BABYLON.PointerEventTypes
      ) => {
        this.hPointerDown.forEach((h) => {
          new Promise(() => h(evt, pickInfo, type));
        });
      };
  }

  addPointerMoveHandler(
    h: (
      evt: BABYLON.IPointerEvent,
      pickInfo: BABYLON.PickingInfo,
      type: BABYLON.PointerEventTypes
    ) => void
  ) {
        this.hPointerMove.push(h);
  }

  addPointerUpHandler(
    h: (
      evt: BABYLON.IPointerEvent,
      pickInfo: BABYLON.PickingInfo,
      type: BABYLON.PointerEventTypes
    ) => void
  ) {
        this.hPointerUp.push(h);
  }

  addPointerDownHandler(
    h: (
      evt: BABYLON.IPointerEvent,
      pickInfo: BABYLON.PickingInfo,
      type: BABYLON.PointerEventTypes
    ) => void
  ) {
        this.hPointerDown.push(h);
  }

}
