
/* eslint-disable @typescript-eslint/naming-convention */
export enum GeoJSONConstants {

    FIELD_TYPE = 'type',
    FIELD_CRS = 'crs',
    FIELD_NAME = 'name',
    FIELD_BBOX = 'bbox',
    FIELD_COORDINATES = 'coordinates',
    FIELD_GEOMETRIES = 'geometries',
    FIELD_GEOMETRY = 'geometry',
    FIELD_PROPERTIES = 'properties',
    FIELD_FEATURES = 'features',
    FIELD_ID = 'id',

    TYPE_POINT = 'Point',
    TYPE_MULTI_POINT = 'MultiPoint',
    TYPE_LINE_STRING = 'LineString',
    TYPE_MULTI_LINE_STRING = 'MultiLineString',
    TYPE_POLYGON = 'Polygon',
    TYPE_MULTI_POLYGON = 'MultiPolygon',
    TYPE_GEOMETRY_COLLECTION = 'GeometryCollection',
    TYPE_FEATURE = 'Feature',
    TYPE_FEATURE_COLLECTION = 'FeatureCollection',

    FIELD_CRS_NAME = 'name',
    FIELD_CRS_LINK = 'link',

    // Default Named CRS string
    // OGC CRS URNs such as "urn:ogc:def:crs:OGC:1.3:CRS84" shall be preferred over legacy identifiers
    // such as "EPSG:4326"
    WGS84_CRS = 'urn:ogc:def:crs:OGC:1.3:CRS84',
    EPSG4326_CRS = 'EPSG:4326'
};
