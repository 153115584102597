<p-tabView [(activeIndex)]="viewActiveIndex">
  <ng-container *ngFor="let view of views | keyvalue; let viewIndex = index">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <span>{{ view.key }}</span>
      </ng-template>
      <fieldset style="border: 1px solid lightblue; padding: 20px">
        <p-tabView [(activeIndex)]="view.value.stpActiveIndex">
          <ng-container
            *ngFor="let fsp of view.value.fsps; let stpIndex = index"
          >
            <p-tabPanel>
              <ng-template pTemplate="header">
                <span>{{ stpIndex.toString() }}</span>
                <span
                  [hidden]="!view.value.fspStateMap.get(fsp).active"
                  class="badge1"
                  style="background-color: yellowgreen; color: red"
                  >{{ view.value.fspStateMap.get(fsp).matches }}</span
                >
              </ng-template>
              <fieldset style="border: 1px solid lightblue; padding: 20px">
                <legend>stacked scoped properties {{ fsp }}</legend>
                <app-st-properties
                  (event)="stpEvent(view.key, fsp, $event)"
                  [stalled]="view.value.stalled"
                  [watchCenter]="watchCenter"
                  [stpData]="view.value.fspDataMap.get(fsp)"
                  [properties]="properties"
                  [id]="fsp"
                  [clipboard]="clipboard"
                ></app-st-properties>
              </fieldset>
            </p-tabPanel>
          </ng-container>
        </p-tabView>
      </fieldset>
    </p-tabPanel>
  </ng-container>
</p-tabView>
