import * as BABYLON from '@babylonjs/core';
import { PickingInfo } from '@babylonjs/core';
import * as BABYLONGUI from '@babylonjs/gui';
import { ContextMenu } from 'primeng/contextmenu';
import { BabyContext } from './babycontext';
import { WebMercator } from './webmercator';

const nameDir = {
  camera1: 'camera1',
  light1: 'light1',
  light2: 'light2',
  geoSphere: 'sphere1',
};

export class BabyGeoScene {
  scene: BABYLON.Scene;
  camera: BABYLON.ArcRotateCamera;
  canvas: HTMLCanvasElement;
  engine: BABYLON.Engine;
  sphere: BABYLON.AbstractMesh;
  context: BabyContext;

  private oldRadius = -1;
  private oldAlpha = -400;
  private oldBeta = -400;
  private fullScreenUI: BABYLONGUI.AdvancedDynamicTexture;
  private nameTextBlock;

  constructor(
    context: BabyContext
  ) {
    this.context = context;
    this.canvas = context.canvas;
    this.engine = context.engine;
    this.scene = context.scene;
  }

  viewHasChanged() {
    const ret: boolean =
      this.camera.radius !== this.oldRadius ||
      this.camera.alpha !== this.oldAlpha ||
      this.camera.beta !== this.oldBeta;
    this.oldRadius = this.camera.radius;
    this.oldAlpha = this.camera.alpha;
    this.oldBeta = this.camera.beta;
    return ret;
  }

  setScene(): void {
    this.scene.ambientColor = new BABYLON.Color3(1, 1, 1);

/*    new BABYLON.DirectionalLight(
      'hemi',
      new BABYLON.Vector3(0, 0, 1).scale(this.context.dimension*5),
      this.scene
    );

    new BABYLON.DirectionalLight(
      'hemi',
      new BABYLON.Vector3(0, -1, -1).scale(this.context.dimension*5),
      this.scene
    );
*/
    this.camera = new BABYLON.ArcRotateCamera(
      nameDir.camera1,
      0,
      Math.PI / 2,
      3 * this.context.dimension,
      new BABYLON.Vector3(0, 0, 0),
      this.scene
    );
    //    this.camera.setPosition(new BABYLON.Vector3(0, 1, -2));
    this.camera.lowerRadiusLimit = 1.1 * this.context.dimension;
    this.camera.upperRadiusLimit = 3 * this.context.dimension;
    this.camera.wheelDeltaPercentage = 0.1;
    this.camera.minZ = 0.01 * this.context.dimension;
    this.camera.maxZ = 7 * this.context.dimension;
    this.camera.attachControl(this.canvas, true);

    const actionManager = new BABYLON.ActionManager(this.scene);

    // Our built-in 'sphere' shape. Params: name, subdivs, size, scene
    const sphere = BABYLON.CreateSphere(
      nameDir.geoSphere,
      { segments: 16, diameter: 1.99 * this.context.dimension},
      this.scene
    );
    sphere.position = new BABYLON.Vector3(0, 0, 0);
    sphere.isPickable = true;

    this.sphere = sphere;

    //this.createSun(0.1);

    sphere.actionManager = actionManager;

    const { called } = new (class {
      count = 0;
      called = () => {
        this.count++;
        if (this.count >= Number.MAX_SAFE_INTEGER) {
          this.count = 0;
        }
        return this.count;
      };
    })();

/*    actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOverTrigger,
        (ev) => {
          if (ev.meshUnderPointer) {
            //            this.nameTextBlock.text = ev.meshUnderPointer.name;
          } else {
            //            this.nameTextBlock.text = '';
          }
        }
      )
    );

    actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOutTrigger,
        (ev) => {
          this.nameTextBlock.text = '';
        }
      )
    );
*/
    this.scene.registerAfterRender(() => {
      this.camera.angularSensibilityX = this.camera.angularSensibilityY =
        2000/ Math.log2(this.camera.radius/this.context.dimension);
      //const c = called();
/*      if (this.engine.frameId % 20 === 0) {
        const pickResult = this.pickingInfo();
        if (pickResult.hit) {
          this.nameTextBlock.text = pickResult.pickedMesh.name;
          const lonLat = WebMercator.getXYZLonLat({
            x: pickResult.pickedPoint.x,
            y: pickResult.pickedPoint.z,
            z: pickResult.pickedPoint.y,
          });
        } else {
          this.nameTextBlock.text = '';
        }
            }
          */    });
  }
  /*
  createSun(size: number) {
    // create a built-in "sphere" shape; its constructor takes 4 params: name, subdivisions, radius, scene
    this.sun = BABYLON.MeshBuilder.CreateSphere(
      'sun',
      { diameterX: size, diameterY: size, diameterZ: size },
      this.scene
    );

    // create the material with its texture for the sphere and assign it to the sphere
    const sunMaterial = new BABYLON.StandardMaterial('sun_surface', this.scene);
    sunMaterial.diffuseTexture = new BABYLON.Texture(
      './assets/textures/sun.jpg',
      this.scene
    );
    this.sun.material = sunMaterial;

    this.sun.isPickable = false;

  }
*/
  /**
   * creates the world axes
   *
   * Source: https://doc.babylonjs.com/snippets/world_axes
   *
   * @param size number
   */
  public showWorldAxis(size: number): void {
    const makeTextPlane = (text: string, color: string, textSize: number) => {
      const dynamicTexture = new BABYLON.DynamicTexture(
        'DynamicTexture',
        50,
        this.scene,
        true
      );
      dynamicTexture.hasAlpha = true;
      dynamicTexture.drawText(
        text,
        5,
        40,
        'bold 36px Arial',
        color,
        'transparent',
        true
      );
      const plane = BABYLON.Mesh.CreatePlane(
        'TextPlane',
        textSize,
        this.scene,
        true
      );
      const material = new BABYLON.StandardMaterial(
        'TextPlaneMaterial',
        this.scene
      );
      material.backFaceCulling = false;
      material.specularColor = new BABYLON.Color3(0, 0, 0);
      material.diffuseTexture = dynamicTexture;
      plane.material = material;

      return plane;
    };

    const axisX = BABYLON.MeshBuilder.CreateLines(
      'axisX',
      {
        points: [
          BABYLON.Vector3.Zero(),
          new BABYLON.Vector3(size, 0, 0),
          new BABYLON.Vector3(size * 0.95, 0.05 * size, 0),
          new BABYLON.Vector3(size, 0, 0),
          new BABYLON.Vector3(size * 0.95, -0.05 * size, 0),
        ],
      },
      this.scene
    );

    axisX.color = new BABYLON.Color3(1, 0, 0);

    const xChar = makeTextPlane('X', 'red', size / 10);
    xChar.position = new BABYLON.Vector3(0.9 * size, -0.05 * size, 0);

    const axisY = BABYLON.MeshBuilder.CreateLines(
      'axisY',
      {
        points: [
          BABYLON.Vector3.Zero(),
          new BABYLON.Vector3(0, size, 0),
          new BABYLON.Vector3(-0.05 * size, size * 0.95, 0),
          new BABYLON.Vector3(0, size, 0),
          new BABYLON.Vector3(0.05 * size, size * 0.95, 0),
        ],
      },
      this.scene
    );
    axisY.color = new BABYLON.Color3(0, 1, 0);

    const yChar = makeTextPlane('Y', 'green', size / 10);
    yChar.position = new BABYLON.Vector3(0, 0.9 * size, -0.05 * size);

    const axisZ = BABYLON.MeshBuilder.CreateLines(
      'axisZ',
      {
        points: [
          BABYLON.Vector3.Zero(),
          new BABYLON.Vector3(0, 0, size),
          new BABYLON.Vector3(0, -0.05 * size, size * 0.95),
          new BABYLON.Vector3(0, 0, size),
          new BABYLON.Vector3(0, 0.05 * size, size * 0.95),
        ],
      },
      this.scene
    );
    axisZ.color = new BABYLON.Color3(0, 0, 1);

    const zChar = makeTextPlane('Z', 'blue', size / 10);
    zChar.position = new BABYLON.Vector3(0, 0.05 * size, 0.9 * size);
  }
}
