
//https://stackblitz.com/edit/angular-open-window-tbd3a4
//https://stackblitz.com/edit/portal-simple?file=src%2Fapp%2Fservices%2Fpopout.service.ts


import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';

@Component({
  selector: 'app-window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.scss'],
})
export class WindowComponent implements OnInit, OnDestroy, AfterViewInit {
  // STEP 1: get a reference to the portal
  @ViewChild(CdkPortal)
  private portal: CdkPortal;

  // STEP 2: save a reference to the window so we can close it
  private externalWindow = null;

  // STEP 3: Inject all the required dependencies for a PortalHost
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.externalWindow = window.open(
      '',
      '',
      'width=600,height=400,left=200,top=200'
    );

    if(!this.externalWindow) {
      return;
    }

    // give some time to open the new window
    setTimeout(() => {
      // Create a PortalOutlet with the body of the new window document
      const outlet = new DomPortalOutlet(
        this.externalWindow.document.body,
        this.componentFactoryResolver,
        this.applicationRef,
        this.injector);

        // Copy styles from parent window
      document.querySelectorAll('style').forEach(htmlElement => {
        this.externalWindow.document.head.appendChild(htmlElement.cloneNode(true));
      });

      // Copy stylesheet link from parent window
      const styleSheetElement = document.createElement('link');
      document.querySelectorAll('link').forEach(htmlElement => {
        if (htmlElement.rel === 'stylesheet') {
          const absoluteUrl = new URL(htmlElement.href).href;
          styleSheetElement.rel = 'stylesheet';
          styleSheetElement.href = absoluteUrl;
        }
      });

      this.externalWindow.document.head.appendChild(styleSheetElement);

      styleSheetElement.onload = () => {
        // Clear popout modal content
       // this.externalWindow.document.body.innerText = '';
        const host = new DomPortalOutlet(
          this.externalWindow.document.body,
          this.componentFactoryResolver,
          this.applicationRef,
          this.injector
        );

        // STEP 6: Attach the portal
        host.attach(this.portal);
      };
    }, 1000);

    // STEP 5.1: clone stylesheets and style tags to external window
    if(this.externalWindow) {
    document.querySelectorAll('style').forEach(htmlElement => {
      this.externalWindow.document.head.appendChild(htmlElement.cloneNode(true));
    });
  }
  }

  ngAfterViewInit() {
/*    setTimeout(() => {
      // STEP 4: create an external window
      const host = new DomPortalOutlet(
        this.externalWindow.document.body,
        this.componentFactoryResolver,
        this.applicationRef,
        this.injector
      );

      // STEP 6: Attach the portal
      host.attach(this.portal);
    });
  });
*/}

  ngOnDestroy() {
    // STEP 7: close the window when this component destroyed
    if(this.externalWindow) {
      this.externalWindow.close();
    }
  }

  private getStyleSheetElement() {
    const styleSheetElement = document.createElement('link');
    document.querySelectorAll('link').forEach(htmlElement => {
      if (htmlElement.rel === 'stylesheet') {
        const absoluteUrl = new URL(htmlElement.href).href;
        styleSheetElement.rel = 'stylesheet';
        styleSheetElement.href = absoluteUrl;
      }
    });
    //console.log(styleSheetElement.sheet);
    return styleSheetElement;
  }

}
