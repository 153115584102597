/* eslint-disable no-underscore-dangle */

import { compile } from 'jse-eval';

export class JSEeval {
    private fn: (context: any) => unknown;
    private _compileError = null;
    private _evalError = null;
    constructor(private expression: string) {
        try {
            this.fn = compile(expression);
        }
        catch(error) {
            this._compileError = error.message;
        }
    }
    get compileError(): string {
        return this._compileError;
    }
    get evalError(): string {
        return this._evalError;
    }
    evaluate(context: any) {
        if(this.fn) {
            try {
                const res = this.fn(context);
                this._evalError = null;
                return res;
            }
            catch(error) {
                this._evalError = error.message;
            }
        }
        return undefined;
    }
};
