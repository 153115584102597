/* eslint-disable @typescript-eslint/naming-convention */
import { Layer } from 'ol/layer';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Draw, Modify, Snap } from 'ol/interaction';
import Map from 'ol/Map';
import Geometry, { Type } from 'ol/geom/Geometry';
import { GeoJSON } from 'ol/format';
import Feature from 'ol/Feature';

export class EditLayer extends VectorLayer<VectorSource> {
  modify: Modify;
  draw: Draw;
  snap: Snap;
  map: Map;

  constructor(map: Map) {
    super({
      source: new VectorSource(),
      style: {
        'fill-color': 'rgba(255, 255, 255, 0.2)',
        'stroke-color': '#ffcc33',
        'stroke-width': 2,
        'circle-radius': 7,
        'circle-fill-color': '#ffcc33',
      },
    });
    this.map = map;
    this.modify = new Modify({ source: this.getSource() });
    this.map.addInteraction(this.modify);
    this.map.addLayer(this);
  }

  addInteractions(type: Type) {
    // Type{'Point'} {'LineString'} {'LinearRing'} {'Polygon'}
    // {'MultiPoint'} {'MultiLineString'} {'MultiPolygon'} {'GeometryCollection'}
    // {'Circle'}
    this.map.removeInteraction(this.draw);
    this.map.removeInteraction(this.snap);
    this.draw = new Draw({
      source: this.getSource(),
      type,
    });
    this.map.addInteraction(this.draw);
    this.snap = new Snap({ source: this.getSource() });
    this.map.addInteraction(this.snap);
  }

  getGeoJSON(): string {
    const writer = new GeoJSON();
    const geojsonStr = writer.writeFeatures(this.getSource().getFeatures());
    return geojsonStr;
  }

  addGeoJSON(jsonString: string) {
    this.requestUrl(jsonString,this.addGeoJSONString);
  }

  private addGeoJSONString(jsonString: string) {
    try {
      const jsonObject = JSON.parse(jsonString);
      const features: Feature<Geometry>[] = new GeoJSON().readFeatures(
        jsonObject
      );
      this.getSource().addFeatures(features);
    } catch (e) {
      return null;
    }
  }

  private requestUrl(url,callback: (result: string) => void) {

    const xhr = new XMLHttpRequest();

    xhr.open('GET', url, true);
    xhr.responseType = 'text';
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          callback(xhr.response);
        } else {
        }
      }
    };

    xhr.onerror = () => {
        callback(url);
    };

    xhr.ontimeout = () => {
        callback(url);
    };

    xhr.send(null);
  }

}

