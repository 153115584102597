import { GeometryGhost } from '../geodesy/geometryGhost';
import { GeodesicGeoJson } from '../geodesy/create-geodesic-geojson';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

export const extDisplay = (mode, state, geojson, display) => {
  if (state.circle) {
    const isActivePolygon = geojson.properties.id === state.circle.id;
    geojson.properties.active = isActivePolygon
      ? MapboxDraw.constants.activeStates.ACTIVE
      : MapboxDraw.constants.activeStates.INACTIVE;
  }
  const displayGeodesic = (geojson1) => {
    const geodesicGeojson = GeodesicGeoJson.createGeodesicGeojson(
      geojson1,
      // eslint-disable-next-line no-underscore-dangle
      { ctx: mode._ctx }
    );
    geodesicGeojson.forEach((gjson) => display(gjson));
  };
  const geodesic =
    state.geodesic ||
    GeometryGhost.isGeodesic(geojson) ||
    GeometryGhost.isCircle(geojson);
  const ndisplay = geodesic ? displayGeodesic : display;
  if (mode.chain?.toDisplayFeatures) {
    mode.chain.toDisplayFeatures.call(mode, state, geojson, ndisplay);
  } else {
    ndisplay(geojson);
  }
};
