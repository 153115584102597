import hat from 'hat';
import * as Constants from './constants';

export class CircleGeoJson {
static createCircle(center, radius, properties = {}) {
  if (!(radius > 0)) {
    throw new Error('Radius has to be greater then 0');
  }

  return {
    id: hat(),
    type: 'Feature',
    properties: {
      [Constants.properties.CIRCLE_RADIUS]: radius, // km
      ...properties
    },
    geometry: {
      type: 'Polygon',
      coordinates: [[center, center, center, center]] // valid polygon needs 3 vertices
    }
  };
}

static isCircleByTypeAndProperties(type, properties) {
  return type === 'Polygon' &&
    typeof properties[Constants.properties.CIRCLE_RADIUS] === 'number' &&
    properties[Constants.properties.CIRCLE_RADIUS] > 0;
}

static isCircle(geojson) {
  return CircleGeoJson.isCircleByTypeAndProperties(geojson.geometry.type, geojson.properties);
}

static getCircleCenter(geojson) {
  if (!CircleGeoJson.isCircle(geojson)) {
    throw new Error('GeoJSON is not a circle');
  }

  return geojson.geometry.coordinates[0][0];
}

static setCircleCenter(geojson, center) {
  if (!CircleGeoJson.isCircle(geojson)) {
    throw new Error('GeoJSON is not a circle');
  }

  geojson.geometry.coordinates = [[center, center, center, center]]; // valid polygon needs 3 vertices
}

static getCircleRadius(geojson) {
  if (!CircleGeoJson.isCircle(geojson)) {
    throw new Error('GeoJSON is not a circle');
  }

  return geojson.properties[Constants.properties.CIRCLE_RADIUS];
}

static setCircleRadius(geojson, radius) {
  if (!CircleGeoJson.isCircle(geojson)) {
    throw new Error('GeoJSON is not a circle');
  }

  geojson.properties[Constants.properties.CIRCLE_RADIUS] = radius;
}
}
