import {
  CdkConnectedOverlay,
  ConnectionPositionPair,
} from '@angular/cdk/overlay';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import * as WorldWind from './worldwind';

@Component({
  selector: 'app-worldwind',
  templateUrl: './worldwind.component.html',
  styleUrls: ['./worldwind.component.scss'],
})
export class WorldwindComponent implements AfterViewInit {
  @ViewChild('scene') scene: ElementRef;
  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  wwd: WorldWind.WorldWindow;
  globe3D: WorldWind.Globe;
  globe2D: WorldWind.Globe2D;
  layerManager: WorldWind.LayerManager;

  highlightedItems = [];

  overlay: {
    text: string;
    isOpen: boolean;
    offsetX: number;
    offsetY: number;
    positions: ConnectionPositionPair[];
  } = {
    text: '',
    isOpen: true,
    offsetX: 0,
    offsetY: 0,
    positions: [
      new ConnectionPositionPair(
        {
          originX: 'start', // start or end
          originY: 'top',
        },
        {
          // top or bottom
          overlayX: 'start',
          overlayY: 'top',
        }
      ),
    ],
  };

  constructor() {}

  ngAfterViewInit() {
    // const wwd = WorldWind.WorldWindow(this.scene.nativeElement);
    // This will work with the next release of WebWorldWind, which supports an
    // actual element instead of the ID as a string.

    // In the meantime, the ID must be used and makes the component not easily
    // reusable.
    this.wwd = new WorldWind.WorldWindow('scene');
    this.globe3D = new WorldWind.Globe(new WorldWind.EarthElevationModel());
    this.globe2D = new WorldWind.Globe2D();
    this.globe2D.projection = new WorldWind.ProjectionMercator();
    this.wwd.globe = this.globe3D;
    this.wwd.deepPicking = true;

    this.wwd.addLayer(new WorldWind.BMNGOneImageLayer());
    this.wwd.addLayer(new WorldWind.BMNGLandsatLayer());
    const osmil = new WorldWind.OpenStreetMapImageLayer();
    //this.wwd.addLayer(osmil);

    //MultiLineString test
    const countriesLayer = new WorldWind.RenderableLayer('Countries');

    const countriesGeoJSON = new WorldWind.GeoJSONParser(
      '/assets/geomap/world-administrative-boundaries.geojson'
    );

    countriesGeoJSON.load(
      null,
      this.shapeConfigurationCallback,
      countriesLayer
    );

    //MultiLineString test
    const multiLineStringLayer = new WorldWind.RenderableLayer(
      'Submarine Cables'
    );
    const multiLineStringGeoJSON = new WorldWind.GeoJSONParser(
      '/assets/geomap/cable-geo.json'
    );
    multiLineStringGeoJSON.load(
      null,
      this.shapeConfigurationCallback,
      multiLineStringLayer
    );
    this.wwd.addLayer(countriesLayer);
    this.wwd.addLayer(multiLineStringLayer);
    this.wwd.addLayer(new WorldWind.CompassLayer());
    this.wwd.addLayer(new WorldWind.CoordinatesDisplayLayer(this.wwd));
    this.wwd.addLayer(new WorldWind.ViewControlsLayer(this.wwd));

    // Create a layer manager for controlling layer visibility.
    /*    this.layerManager = new WorldWind.LayerManager(this.wwd);
    this.layerManager.synchronizeLayerList();
    this.layerManager.goToAnimator.goTo(new WorldWind.Location(38.72, 14.91));
*/

    // Listen for mouse clicks.
    const clickRecognizer = new WorldWind.ClickRecognizer(
      this.wwd,
      this.handleClick
    );

    // Listen for mouse mmoves.
    this.wwd.addEventListener('mousemove', (event) => {
      let eventHandled = false;
      const vec2 = new WorldWind.Vec2(event.offsetX, event.offsetY);
      vec2[0] = Math.round(vec2[0]);
      vec2[1] = Math.round(vec2[1]);

      this.handleClickXY(vec2);

      eventHandled = false;

      if (eventHandled) {
        event.preventDefault();
      }
    });
    // Listen for taps on mobile devices.
    const tapRecognizer = new WorldWind.TapRecognizer(
      this.wwd,
      this.handleClick
    );

    this.wwd.redraw();
  }

  hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  shapeConfigurationCallback = (geometry, properties) => {
    const configuration = {
      attributes: undefined,
      highlightAttributes: undefined,
      userProperties: {
        sourceProperties: properties,
        name: 'undefined',
      },
    };

    if (geometry.isPointType() || geometry.isMultiPointType()) {
      if (
        properties &&
        (properties.name || properties.Name || properties.NAME)
      ) {
        configuration.userProperties.name =
          properties.name || properties.Name || properties.NAME;
      }
      if (properties && properties.POP_MAX) {
        const population = properties.POP_MAX;
        configuration.attributes.imageScale = 0.01 * Math.log(population);
      }
    } else if (
      geometry.isLineStringType() ||
      geometry.isMultiLineStringType()
    ) {
      if (
        properties &&
        (properties.name || properties.Name || properties.NAME)
      ) {
        configuration.userProperties.name =
          properties.name || properties.Name || properties.NAME;
      }
      configuration.attributes = new WorldWind.ShapeAttributes(null);
      configuration.highlightAttributes = new WorldWind.ShapeAttributes(null);
      configuration.attributes.drawOutline = true;
      if (properties.color) {
        const color = this.hexToRgb(properties.color);
        configuration.attributes.outlineColor = new WorldWind.Color(
          0.01 * color.r,
          0.01 * color.g,
          0.01 * color.b,
          1.0
        );
      } else {
        configuration.attributes.outlineColor = new WorldWind.Color(
          0.1 * configuration.attributes.interiorColor.red,
          0.3 * configuration.attributes.interiorColor.green,
          0.7 * configuration.attributes.interiorColor.blue,
          1.0
        );
      }
      configuration.attributes.outlineWidth = 2.0;
      configuration.highlightAttributes.outlineColor =
        configuration.attributes.outlineColor;
      configuration.highlightAttributes.outlineWidth = 5.0;
    } else if (geometry.isPolygonType() || geometry.isMultiPolygonType()) {
      configuration.attributes = new WorldWind.ShapeAttributes(null);

      // Fill the polygon with a random pastel color.
      configuration.attributes.interiorColor = new WorldWind.Color(
        0.375 + 0.5 * Math.random(),
        0.375 + 0.5 * Math.random(),
        0.375 + 0.5 * Math.random(),
        0.5
      );
      // Paint the outline in a darker variant of the interior color.
      configuration.attributes.outlineColor = new WorldWind.Color(
        0.5 * configuration.attributes.interiorColor.red,
        0.5 * configuration.attributes.interiorColor.green,
        0.5 * configuration.attributes.interiorColor.blue,
        1.0
      );
    }

    return configuration;
  };

  parserCompletionCallback = (layer) => {
    this.wwd.addLayer(layer);
    this.layerManager?.synchronizeLayerList();
  };

  // The common gesture-handling function.
  handleClick = (recognizer) => {
    // Obtain the event location.
    const ovec2: WorldWind.Vec2 = this.wwd.canvasCoordinates(
      recognizer.clientX,
      recognizer.clientY
    );
    this.handleClickXY(ovec2);
  };

  handleClickXY(ovec2: WorldWind.Vec2) {
    // Perform the pick. Must first convert from window coordinates to canvas coordinates, which are
    // relative to the upper left corner of the canvas rather than the upper left corner of the page.
//    let pickList = this.wwd.pick(ovec2);
    const offset = 60;
    const rect = new WorldWind.Rectangle(Math.max(ovec2[0]-offset,0),Math.max(ovec2[1]-offset,0),2*offset,2*offset);
    let pickList = this.wwd.pickShapesInRegion(rect);
    if(!pickList || pickList.objects.length === 0) {
      return;
    }
    this.overlay.offsetX = ovec2[0] + 10;
    this.overlay.offsetY = ovec2[1] + 10;

    let redrawRequired = this.highlightedItems.length > 0;

    // De-highlight any highlighted placemarks.
    for (const item of this.highlightedItems) {
      item.highlighted = false;
    }
    this.highlightedItems = [];

    let selectedFeature = null;

    // If only one thing is picked and it is the terrain, try a rectangle.
    /*    if (
      pickList.objects.length === 1 &&
      pickList.objects[0].isTerrain &&
      false
    ) {
      const rectRadius = 50;
      const pickRectangle = new WorldWind.Rectangle(
        ovec2[0] - rectRadius,
        ovec2[1] + rectRadius,
        2 * rectRadius,
        2 * rectRadius
      );
      pickList = this.wwd.pickShapesInRegion(pickRectangle);
    }
*/ for (const op of pickList.objects) {
      if (!op.isTerrain) {
        if (op.isOnTop && !op.isTerrain) {
          selectedFeature = op.userObject;
        }
      }
    }

    // If only one thing is picked and it is the terrain, use a go-to animator to go to the picked location.
    if (selectedFeature == null) {
      //const position = pickList.objects[0].position;
      //goToAnimator.goTo(new WorldWind.Location(position.latitude, position.longitude));
      this.overlay.text = ovec2[0] + '   ' + ovec2[1];
      //this.overlay.text = '';
      //this.overlay.isOpen = false;
    } else {
      selectedFeature.highlighted = true;
      this.highlightedItems.push(selectedFeature);
      this.overlay.text = selectedFeature.userProperties?.name;
      this.overlay.isOpen = true;
      redrawRequired = true;
    }
    this.cdkConnectedOverlay.overlayRef.updatePosition();
    // Update the window if we changed anything.
    if (redrawRequired) {
      //this.wwd.redraw();
    }
  }
}
