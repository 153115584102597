/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FilePickerDirective } from './file-picker.directive';

@Component({
  selector: 'app-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
})
export class FilePickerComponent implements OnInit, AfterViewInit {

  ngOnInit(): void {
    const toto = 0;
  }
  ngAfterViewInit(): void {
  }

  _selectedFiles = [];
  _multiple = false;

  @ViewChild('buttonPicker', { static: true })
  _buttonPicker: FilePickerDirective;

  @ViewChild('dropZonePicker', { static: true })
  _dropZonePicker: FilePickerDirective;


  _onFilesChanged(files: FileList) {
    this._selectedFiles = [];
    for (let i = 0; i < files.length; i++) {
      this._selectedFiles.push(files[i]);
      files[i].arrayBuffer().then((a)=> {
        const x = a;
      });
    }
  }

  _onReset() {
    this._selectedFiles = [];
  }

  _reset() {
    this._buttonPicker.reset();
    this._dropZonePicker.reset();
  }
}
