/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import { Vector as VectorSource } from 'ol/source';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON';
import { Vector as VectorLayer } from 'ol/layer';
import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay';
import { Control, defaults as defaultControls } from 'ol/control';
import Geometry, { Type } from 'ol/geom/Geometry';
import BaseEvent from 'ol/events/Event';
import { EditLayer } from './editLayer';
import { EntitiesWatchCenter } from 'src/app/watch-center/entities-watch-center';

class RotateNorthControl extends Control {
  constructor(opt_options?) {
    const options = opt_options || {};

    const button = document.createElement('button');
    button.innerHTML = 'N';

    const element = document.createElement('div');
    element.className = 'rotate-north ol-unselectable ol-control';
    element.appendChild(button);

    super({
      element,
      target: options.target,
    });

    button.addEventListener('click', this.handleRotateNorth.bind(this), false);
  }

  handleRotateNorth() {
    this.getMap().getView().setRotation(0);
  }
}

@Component({
  selector: 'app-openlayer',
  templateUrl: './openlayer.component.html',
  styleUrls: ['./openlayer.component.scss'],
})
export class OpenlayerComponent implements OnInit, AfterViewInit {
  static styles = {
    Point: new Style({}),
    LineString: new Style({
      stroke: new Stroke({
        color: 'green',
        width: 1,
      }),
    }),
    MultiLineString: new Style({
      stroke: new Stroke({
        color: 'green',
        width: 1,
      }),
    }),
    MultiPoint: new Style({}),
    MultiPolygon: new Style({
      stroke: new Stroke({
        color: 'yellow',
        width: 1,
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 0, 0.1)',
      }),
    }),
    Polygon: new Style({
      stroke: new Stroke({
        color: 'blue',
        lineDash: [4],
        width: 3,
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 255, 0.1)',
      }),
    }),
    GeometryCollection: new Style({
      stroke: new Stroke({
        color: 'magenta',
        width: 2,
      }),
      fill: new Fill({
        color: 'magenta',
      }),
      image: new CircleStyle({
        radius: 10,
        fill: null,
        stroke: new Stroke({
          color: 'magenta',
        }),
      }),
    }),
    Circle: new Style({
      stroke: new Stroke({
        color: 'red',
        width: 2,
      }),
      fill: new Fill({
        color: 'rgba(255,0,0,0.2)',
      }),
    }),
  };

  private watchCenter: EntitiesWatchCenter;

  constructor() {
    const toto = 0;
    this.watchCenter = new EntitiesWatchCenter('name',['name','selected','style','tags']);
  }

  static styleFunction(feature: Feature): Style {
    let style: Style = new Style({});
    let selectStyle: Style;

    switch (feature.getGeometry().getType()) {
      case 'Point':
        style = new Style({});
        break;
      case 'LineString':
      case 'MultiLineString':
        style = new Style({
          stroke: new Stroke({
            color: feature.getProperties().color || 'green',
            width: 1,
          }),
        });
        selectStyle = new Style({
          stroke: new Stroke({
            color: feature.getProperties().color || 'green',
            width: 3,
          }),
        });
        break;
      case 'MultiPoint':
        style = new Style({});
        break;
      case 'MultiPolygon':
      case 'Polygon':
        style = new Style({
          stroke: new Stroke({
            color: feature.getProperties().color || 'white',
            width: 1,
          }),
          fill: new Fill({
            color: feature.getProperties().color || 'green',
          }),
        });
        break;
      case 'GeometryCollection':
        break;
      case 'Circle':
        new Style({
          stroke: new Stroke({
            color: 'red',
            width: 2,
          }),
          fill: new Fill({
            color: 'rgba(255,0,0,0.2)',
          }),
        });
        break;
    }
    if (selectStyle === undefined) {
      selectStyle = style;
    }
    feature.setProperties({ baseStyle: style, selectStyle });
    return style;
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    const map = new Map({
      controls: defaultControls().extend([new RotateNorthControl()]),
      layers: [],
      target: 'map',
      view: new View({
        center: [0, 0],
        zoom: 2,
      }),
    });

    /*    map.addLayer(
      new TileLayer({
        source: new OSM(),
      })
    );
*/

    const countrySource = new VectorSource({
      url: '/assets/geomap/world-administrative-boundaries.geojson',
      format: new GeoJSON(),
    });

    countrySource.on('featuresloadend', (event: BaseEvent) => {
      this.watchCenter.add(countrySource, {
        name: 'Countries',
        url: '/assets/geomap/world-administrative-boundaries.geojson',
      });

      countrySource.getFeatures().forEach((feature) => {
        this.watchCenter.add(
          feature,
          {
            name: feature.getProperties().name,
            selected: true,
          },
          {
            container: countrySource,
          }
        );
      });
    });

    const countryLayer = new VectorLayer({
      source: countrySource,
      style: OpenlayerComponent.styleFunction,
    });

    map.addLayer(countryLayer);

    const cableSource = new VectorSource({
      url: '/assets/geomap/cable-geo.json',
      format: new GeoJSON(),
    });

    const cableLayer = new VectorLayer({
      source: cableSource,
      style: OpenlayerComponent.styleFunction,
    });

    cableSource.on('featuresloadend', (event: BaseEvent) => {
      this.watchCenter.add(cableSource, {
        name: 'Submarine Cables',
        url: '/assets/geomap/cable-geo.json',
      });

      cableSource.getFeatures().forEach((feature: Feature<Geometry>) => {
        this.watchCenter.add(
          feature,
          {
            name: feature.getProperties().name,
            selected: true,
          },
          {
            container: cableSource,
          }
        );
      });
    });

    map.addLayer(cableLayer);

    const editLayer = new EditLayer(map);

    editLayer.addInteractions('LineString');

    const tooltip = document.getElementById('tooltip');
    const overlay = new Overlay({
      element: tooltip,
      offset: [10, 0],
      positioning: 'bottom-left',
    });
    map.addOverlay(overlay);

    let selected: Feature = null;
    map.on('pointermove', (e) => {
      if (e.dragging) {
        return;
      }
      if (selected !== null) {
        selected.setStyle(selected.getProperties().baseStyle);
        selected = null;
      }

      const feature = map.forEachFeatureAtPixel(e.pixel, (f) => f);

      tooltip.style.display = feature ? '' : 'none';
      if (feature) {
        selected = feature as Feature;
        selected.setStyle(selected.getProperties().selectStyle);
        overlay.setPosition(e.coordinate);
        tooltip.innerHTML = feature.get('name');
      }
    });

    /*
    document.getElementById('zoom-out').onclick = function () {
      const view = map.getView();
      const zoom = view.getZoom();
      view.setZoom(zoom - 1);
    };

    document.getElementById('zoom-in').onclick = function () {
      const view = map.getView();
      const zoom = view.getZoom();
      view.setZoom(zoom + 1);
    };
  */
  }
}
