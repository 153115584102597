/* eslint-disable @typescript-eslint/member-ordering */
import {
  EntitiesWatchCenter,
  EntityURNStore,
} from 'src/app/watch-center/entities-watch-center';
import {
  IdbMapConfiguration,
  IdbMapEntity,
  IdbMapLayer,
  IdbMapSource,
  IdbMapStyle,
  IdbMapScopeProperties,
  MapIndexDBWrap,
} from './indexDBWrap';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { MapExt } from './mapExt';
import { ADataStoreSubscriber, DataStore } from 'src/app/data-store/data-store';

export class ConfigurationStore {
  conf: IdbMapConfiguration;
  sources: IdbMapSource[] = [];
  layers: IdbMapLayer[] = [];
  styles: IdbMapStyle[] = [];
}

export class MapUtilStore {
  static tryParseJSONString(str: string): object {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  }

  static requestUrl(
    url: string | URL,
    type: '' | 'arraybuffer' | 'blob' | 'document' | 'json' | 'text'
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open('GET', url, true);
      xhr.responseType = type;
      /*    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const config = MapUtilStore.tryParseJSONString(xhr.response);
          } else {
          }
        }
      };
  */
      xhr.onload = () => {
        resolve(xhr.response);
      };

      xhr.onerror = () => {
        reject();
      };

      xhr.ontimeout = () => {
        reject();
      };

      xhr.send(null);
    });
  }

  static async loadFromJSON(
    container: any,
    watchCenter: EntitiesWatchCenter,
    data: any
  ): Promise<{ map: MapExt; conf: ConfigurationStore }> {
    let map: MapExt;
    let confStore: ConfigurationStore = new ConfigurationStore();
    if (data instanceof Blob) {
      data = await (data as Blob).text();
    }
    if (typeof data === typeof '') {
      let o = this.tryParseJSONString(data);
      if (!o) {
        o = await this.requestUrl(data, 'json');
      }
      if (o) {
        confStore = { ...confStore, ...o };
        if (confStore.conf) {
          map = new MapExt(
            confStore,
            {
              container,
              style: confStore.conf.style,
              center: [
                confStore.conf.position.lon,
                confStore.conf.position.lat,
              ],
              zoom: confStore.conf.position.zoom,
            },
            watchCenter,
            () => {
              window.dispatchEvent(new Event('resize'));
            }
          );
        }
      }
    }
    return { map, conf: confStore };
  }

  static async loadFromIndexDB(
    container: any,
    watchCenter: EntitiesWatchCenter,
    confId?: string
  ): Promise<{ map: MapExt; conf: ConfigurationStore }> {
    let map: MapExt;
    let confStore: ConfigurationStore;

    const mapdb = new MapIndexDBWrap();
    await mapdb
      .getConfigurations()
      .then((confs) => {
        let conf: IdbMapConfiguration;
        if (confs.length > 0) {
          confs.forEach((iconf) => {
            if (!conf) {
              conf = iconf;
            } else if (conf.updatedOn.getTime() < iconf.updatedOn.getTime()) {
              conf = iconf;
            }
          });
          confStore = new ConfigurationStore();
          confStore.conf = conf;
        }
      })
      .then(async () => {
        if (confStore) {
          await Promise.all([
            mapdb.getSources({ sources: confStore.conf.sources }),
            mapdb.getLayers({ layers: confStore.conf.layers }),
          ]).then(([sources, layers]) => {
            sources.forEach((source) => {
              confStore.sources.push(source);
              //            map.addSourceExt(source.id, source.specification);
            });
            layers.forEach((layer) => {
              confStore.layers.push(layer);
              //            map.addLayerExt(layer.specification, layer.beforeId);
            });
            map = new MapExt(
              confStore,
              {
                container,
                style: confStore.conf.style, // '/assets/maplibre/maplibre-style.json', // `https://demotiles.maplibre.org/style.json`
                center: [
                  confStore.conf.position.lon,
                  confStore.conf.position.lat,
                ],
                zoom: confStore.conf.position.zoom,
              },
              watchCenter,
              () => {
                window.dispatchEvent(new Event('resize'));
                if (confStore) {
                  MapUtilStore.getScopeProperties(confStore).then(
                    (scopePropertiesArray) => {
                      scopePropertiesArray?.forEach(() => {});
                    }
                  );
                }
              }
            );
          });
        }
      });
    return { map, conf: confStore };
  }

  static async saveToJSON(conf: ConfigurationStore): Promise<Blob> {
    return new Blob([JSON.stringify(conf)], {
      type: 'application/json',
    });
  }

  private static putInArray(f: any[], o: any, c: (s: any) => boolean) {
    const index = f.findIndex(c);
    if (index !== -1) {
      f[index] = o;
    } else {
      f.push(o);
    }
  }
  /*
   * stores a configuration in an IndexDB
   * @param options , if provided store only the new sources, layers and styles
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  static async saveToIndexDB(
    conf: ConfigurationStore,
    options?: {
      newSources?: IdbMapSource[];
      newLayers?: IdbMapLayer[];
      newStyles?: IdbMapStyle[];
    }
  ) {
    const mapdb = new MapIndexDBWrap();

    await mapdb.transaction(
      'rw',
      mapdb.mapConfigurations,
      mapdb.mapSources,
      mapdb.mapLayers,
      mapdb.mapStyles,
      () => {
        if (!options) {
          conf.sources.forEach((source) => mapdb.putSource(source));
          conf.layers.forEach((layer) => mapdb.putLayer(layer));
          conf.styles.forEach((style) => mapdb.putStyle(style));
        } else {
          options?.newSources?.forEach((source) => {
            mapdb.putSource(source);
            MapUtilStore.putInArray(
              conf.sources,
              source,
              (s) => s.id === source.id
            );
            MapUtilStore.putInArray(
              conf.conf.sources,
              source.id,
              (s) => s === source.id
            );
          });
          options?.newLayers?.forEach((layer) => {
            mapdb.putLayer(layer);
            MapUtilStore.putInArray(
              conf.layers,
              layer,
              (s) => s.id === layer.id
            );
            MapUtilStore.putInArray(
              conf.conf.layers,
              layer.id,
              (s) => s === layer.id
            );
          });
          options?.newStyles?.forEach((style) => {
            mapdb.putStyle(style);
            MapUtilStore.putInArray(
              conf.styles,
              style,
              (s) => s.id === style.id
            );
          });
        }
        mapdb.putConfiguration(conf.conf);
      }
    );
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  static async saveEntitiesProperties(
    conf: ConfigurationStore,
    watchCenter: EntitiesWatchCenter
  ) {
    const mapdb = new MapIndexDBWrap();
    const res = await mapdb.transaction('rw', mapdb.mapEntities, () => {
      watchCenter.traverseEntitiesURNStore((estore: EntityURNStore) => {
        const idbEntity: IdbMapEntity = {
          id: estore.urn,
          entity: estore,
        };
        mapdb.putEntity(idbEntity); // for debug breakpoint
      });
    });
    const i = 0;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  static async saveEntityProperties(estore: EntityURNStore) {
    const mapdb = new MapIndexDBWrap();
    const idbEntity: IdbMapEntity = {
      id: estore.urn,
      entity: estore,
    };
    const res = await mapdb.transaction('rw', mapdb.mapEntities, () => {
      mapdb.putEntity(idbEntity); // crlf for debug breakpoint
    });
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  static async loadEntitiesProperties(
    conf: ConfigurationStore,
    watchCenter: EntitiesWatchCenter,
    sourceId?: string
  ) {
    const mapdb = new MapIndexDBWrap();
    const res = await mapdb.transaction('r', mapdb.mapEntities, () =>
      mapdb.getEntities({ where: { startWith: sourceId } })
    );
    return res;
  }

  static scopeProperties(conf: ConfigurationStore): DataStore {
    return new (class extends DataStore {
      constructor() {
        super();
        new (class extends ADataStoreSubscriber {
          add(entity: any, properties?: { [key: string]: any }) {
            this.update(entity, properties);
          }
          remove(entity: any) {
            // TODO
          }
          update(entity: any, properties?: { [key: string]: any }) {
            MapUtilStore.saveScopeProperties(
              conf,
              properties.view,
              properties.level,
              properties.active,
              properties.value
            );
          }
        })(this);
      }
      getAll(): Promise<any[]> {
        return MapUtilStore.getScopeProperties(conf);
      }
    })();
  }

  static async getScopeProperties(
    conf: ConfigurationStore
  ): Promise<IdbMapScopeProperties[]> {
    const table = 'mapScopeProperties';
    const mapdb = new MapIndexDBWrap();
    const res = await mapdb
      .transaction('r', [table], () =>
        mapdb.get(table, { where: { startWith: conf.conf.id } })
      )
      .catch((error) => undefined);
    return res as IdbMapScopeProperties[];
  }

  static async saveScopeProperties(
    conf: ConfigurationStore,
    view: string,
    level: number,
    active: boolean,
    value: string
  ) {
    const table = 'mapScopeProperties';
    const mapdb = new MapIndexDBWrap();
    const res = await mapdb.transaction('rw', [table], () => {
      const idbScopeProperties: IdbMapScopeProperties = {
        id: conf.conf.id + (view? ('/view'+ view):'') + '/level' + level,
        view,
        level,
        active,
        value,
      };
      mapdb[table].put(idbScopeProperties); // for debug breakpoint
    });
  }

  async testCapacitorFS() {
    const directoryHandle = await navigator.storage.getDirectory();
    const stat1 = Filesystem.stat({
      path: 'secrets/text.txt',
      directory: Directory.Documents,
    }).then(
      (ok) => {
        const toto1 = ok;
        Filesystem.readFile({
          path: 'secrets/text.txt',
          directory: Directory.Documents,
          encoding: Encoding.UTF8,
        });
      },
      (ko) => {
        const toto1 = ko;
        Filesystem.writeFile({
          path: 'secrets/text.txt',
          directory: Directory.Documents,
          encoding: Encoding.UTF8,
          data: 'coucou',
          recursive: true,
        }).then(
          (ok) => {
            Filesystem.stat({
              path: 'secrets/text.txt',
              directory: Directory.Documents,
            }).then(
              (ok3) => {
                const toto3 = ok3;
              },
              (ko3) => {
                const toto3 = ko3;
              }
            );
          },
          (ko2) => {
            const toto2 = ko2;
          }
        );
      }
    );
  }
}
