import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaplibreComponent } from './maplibre/maplibre.component';
import { LayersUiComponent } from './layers-ui/layers-ui.component';
import {PickListModule} from 'primeng/picklist';


@NgModule({
  declarations: [MaplibreComponent,LayersUiComponent],
  imports: [
    CommonModule,
    PickListModule
  ],
  exports: [
    MaplibreComponent,
    LayersUiComponent
  ]
})
export class MaplibreModule { }
