/* eslint-disable jsdoc/no-types */
export const DEFAULT_RADIUS = 6371e3;

/**
 * @param {GeoJSON.Position} position1
 * @param {GeoJSON.Position} position2
 * @returns {boolean}
 */
export const equals = (position1, position2): boolean => {
  if (Math.abs(position1[0] - position2[0]) > Number.EPSILON) {
    return false;
  }
  if (Math.abs(position1[1] - position2[1]) > Number.EPSILON) {
    return false;
  }

  return true;
};

/**
 * @param {number} value
 * @returns {number}
 */
export const toRadians = (value) => (value / 180) * Math.PI;

/**
 * @param {number} value
 * @returns {number}
 */
export const toDegrees = (value) => (value / Math.PI) * 180;
