/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectorRef,
  AfterViewChecked,
} from '@angular/core';
import { EntitiesWatchCenter } from '../watch-center/entities-watch-center';
import { JSEeval } from '../jse-eval/JSE-eval';
import { ObjectEditorContext } from '../object-editor/st-editor-int';

export interface IStpData {
  id: number;
  value?: { [key: string]: any };
  scheme?: { [key: string]: any };
  active?: boolean;
  filter?: string;
}

export interface IClipboardData {
  id: number;
  data?: string;
}

export interface IStateData {
  parseError: string;
  evalWarnings: Map<string, number>;
  matches: number;
  uptodate: boolean;
  active: boolean;
}

@Component({
  selector: 'app-st-properties',
  templateUrl: './st-properties.component.html',
  styleUrls: ['./st-properties.component.scss'],
})
export class StPropertiesComponent implements OnInit, AfterViewChecked {
  private _stalled = true;
  @Input() watchCenter: EntitiesWatchCenter;
  @Input() properties: { [key: string]: ObjectEditorContext };
  @Input() id: number;
  @Input() set stalled(s: boolean) {
    this._stalled = s;
    this.stUpdate(this._active);
  };
  @Input() set stpData(d) {
    if (d && d.eventSource !== 'ui') {
      if (this.load(d.value)) {
        this.stUpdate(this._active);
        this.save(false);
      }
    }
  }
  @Input() clipboard: string;
  @Output() event = new EventEmitter<
    (IStpData | IStateData | IClipboardData) & {
      eventType: string;
      eventSource: string;
    }
  >();
  private _active = false;
  private _parseError: string;
  private _evalWarnings: Map<string, number> = new Map();
  stateData: IStateData = {
    parseError: '',
    evalWarnings: new Map<string, number>(),
    matches: 0,
    uptodate: true,
    active: false,
  };
  private _nstateData: IStateData;
  get matches(): number {
    return this.watchCenter.stSize(this.id);
  }
  get evalWarningKeys(): string[] {
    return [...this.stateData.evalWarnings.keys()];
  }
  getEvalWarning(w: string) {
    return this.stateData.evalWarnings.get(w);
  }
  uptodate = true;
  value: { [key: string]: any } = { tags: {}, style: {} };
  scheme: { [key: string]: any } = { tags: {}, style: {} };
  private _filter = '';
  private _savedCurrentStp;
  private _emptyStp;
  objeditorctxs: { [key: string]: ObjectEditorContext & { property: string } } =
    {};
  dispatching: boolean;
  constructor(private cdRef: ChangeDetectorRef) {
    this.save(false);
    this._emptyStp = this._savedCurrentStp.slice();
  }
  get filter(): string {
    // eslint-disable-next-line no-underscore-dangle
    return this._filter;
  }
  set filter(v: string) {
    // eslint-disable-next-line no-underscore-dangle
    this._filter = v;
    this.uptodate = false;
    this.fireStateUpdate();
  }

  set active(state: boolean) {
    this.stUpdate(state);
  }

  get active(): boolean {
    return this._active;
  }

  copy() {
    if(!this._stalled) {
    this.event.emit({
      id: this.id,
      data: JSON.stringify({
        value: this.value,
        scheme: this.scheme,
        filter: this._filter,
        active: this.active,
      }),
      eventType: 'copy',
      eventSource: 'ui',
    });
  }
  }

  paste() {
    if (this.clipboard && !this._stalled) {
      this.load(this.clipboard);
    }
  }

  canPaste(): boolean {
    return this.clipboard != null && !this._stalled;
  }

  shiftUp() {
    if(!this._stalled) {
    this.event.emit({
      id: this.id,
      eventType: 'shift-up',
      eventSource: 'ui',
    });
  }
  }

  shiftDown() {
    if(!this._stalled) {
    this.event.emit({
      id: this.id,
      eventType: 'shift-down',
      eventSource: 'ui',
    });
  }
  }

  getContext(p: string) {
    return this.objeditorctxs[p];
  }

  getProperties() {
    return Object.getOwnPropertyNames(this.properties);
  }

  updatecallback(context, p: string) {
    this.uptodate = false;
    this.fireStateUpdate();
  }

  timeoutHandle;
  timeoutCounter = 0; // for debug
  fireStateUpdate() {
    window.clearTimeout(this.timeoutHandle);
    this.timeoutHandle = window.setTimeout(() => {
      this.timeoutCounter++;
      if (!this.dispatching && !this._stalled) {
        this._nstateData = {
          parseError: this._parseError,
          evalWarnings: new Map(this._evalWarnings),
          matches: this.matches,
          uptodate: this.uptodate,
          active: this.active,
        };
        this.event.emit({
          ...this._nstateData,
          id: this.id,
          eventType: 'stateData',
          eventSource: 'ui',
        });
      }
    }, 500);
  }

  fireUIDataUpdate() {
    this.event.emit({
      eventType: 'stpData',
      eventSource: 'ui',
      id: this.id,
      value: this.value,
      scheme: this.scheme,
      filter: this._filter,
      active: this.active,
    });
  }

  save(fire: boolean): string {

    this._savedCurrentStp = JSON.stringify({
      value: this.value,
      scheme: this.scheme,
      filter: this._filter,
      active: this.active,
    });

    if(fire) {
      this.fireUIDataUpdate();
    }

    return this._savedCurrentStp;
  }

  load(json: string): boolean {
    let res;
    try {
      res = JSON.parse(json ?? this._emptyStp);
      this.value = res.value;
      this.scheme = res.scheme;
      this._filter = res.filter;
      this._active = res.active;
      this.uptodate = false;
      // eslint-disable-next-line guard-for-in
      for (const p in this.properties) {
        this.objeditorctxs[p] = {
          ...this.objeditorctxs[p],
          value: this.value[p],
          scheme: this.scheme[p],
        };
        this.objeditorctxs[p].update(this.objeditorctxs[p]);
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  cancel() {
    if (this.load(this._savedCurrentStp)) {
      this.uptodate = true;
      //      this.fireUIDataUpdate();
      // this.fireStateupdate();
    }
  }

  stUpdate(state: boolean) {
    this._active = state;
    if(this._stalled) {
      return;
    }
    if (state) {
      const jseEval = new JSEeval(this.filter);
      this._evalWarnings = new Map();

      this._parseError = jseEval.compileError;

      if (this._parseError) {
        return;
      }

      this.dispatching = true;

      this.watchCenter.stUpdate(
        this.id,
        null,
        (properties) => {
          const res = jseEval.evaluate(properties);
          if (jseEval.evalError) {
            const ewarn = this._evalWarnings.get(jseEval.evalError) ?? 0;
            this._evalWarnings.set(jseEval.evalError, ewarn + 1);
          }
          if (!this.dispatching) {
            this.fireStateUpdate();
          }
          return res;
        },
        this.value
      );
      // sort stProperties map with keys in descending order
      // when merging the map objects, the later will overwrite the earlier
      this._evalWarnings = new Map(
        [...this._evalWarnings.entries()].sort((e1, e2) => e2[1] - e1[1])
      );
      this.uptodate = true;
      this.dispatching = false;
      //this.save();
    } else {
      this.watchCenter.stUpdate(
        this.id,
        null,
        (properties) => false,
        this.value
      );
    }
    this.fireStateUpdate();
  }

  apply(option?: { ui: boolean }) {
    const i = 0;
    this.stUpdate(true);
    this.save(true);
  }

  ngAfterViewChecked() {
    if (this._nstateData) {
      this.stateData = this._nstateData;
      this._nstateData = undefined;
      this.cdRef.detectChanges();
    }
  }

  ngOnInit() {
    // eslint-disable-next-line guard-for-in
    for (const p in this.properties) {
      this.objeditorctxs[p] = {
        property: p,
        value: this.value[p],
        scheme: this.scheme[p],
        list: this.properties[p].list,
        restricted: this.properties[p].restricted,
        callback: this.updatecallback.bind(this),
        update: () => {},
      };
    }
    this.fireStateUpdate();
  }
}
