/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { EntitiesWatcher } from 'src/app/watch-center/entities-watcher';

import { MapExt } from './mapExt';
import { ConfigurationStore, MapUtilStore } from './maputil-store';
import { FileSystemFileHandle } from 'file-system-access';
import { Observable, Observer, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Dms } from './geodesy/dms';
import { mockConfig as mockConfiguration } from './mockConfig';
import hat from 'hat';
import { EntitiesWatchCenter } from 'src/app/watch-center/entities-watch-center';
import { IMenuItem } from 'src/app/watched-entities-tree/IMenuItem';
import { DataStore } from 'src/app/data-store/data-store';

class MapInfoObserver implements Observer<any> {
  id;
  constructor(private container: ElementRef<HTMLElement>) {
    this.id = 'list' + hat();
    const ul = document.createElement('ul');
    ul.setAttribute('id', this.id);
    container.nativeElement.appendChild(ul);
    ul.hidden = true;
  }
  next(value: { sourceId: string; eventId: string; event: any }) {
    if (!value) {
      return;
    }
    const itemId = this.id + '-' + value.sourceId + '-' + value.eventId;
    if (value.event) {
      const ul = document.getElementById(this.id);
      let li = document.getElementById(itemId);
      if (!li) {
        li = document.createElement('li');
        li.setAttribute('id', itemId);
        ul.appendChild(li);
        li.appendChild(document.createTextNode(''));
      }
      let result;
      switch (value.eventId) {
        case 'latlon':
          result =
            'mouse lat: ' +
            new Dms(value.event.lat, 'lat').toString(2) +
            '<br>  lon: ' +
            new Dms(value.event.lon, 'lon').toString(2);
          break;
        case 'selectVertex':
          result =
            'vertex lat: ' +
            new Dms(value.event.lat, 'lat').toString(2) +
            '<br>  lon: ' +
            new Dms(value.event.lon, 'lon').toString(2);
          break;
      }
      if (result) {
        li.firstChild.nodeValue = result;
        ul.hidden = false;
      }
    } else {
      const ul = document.getElementById(this.id);
      const li = document.getElementById(itemId);
      ul.removeChild(li);
      if (ul.children.length === 0) {
        ul.hidden = true;
      }
    }
  }
  error: (err: any) => void;
  complete: () => void;
}

@Component({
  selector: 'app-maplibre',
  templateUrl: './maplibre.component.html',
  styleUrls: ['./maplibre.component.scss'],
})
export class MaplibreComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  @ViewChild('mapinfo')
  private mapInfoContainer!: ElementRef<HTMLElement>;

  map: MapExt | undefined;

  watchCenter: EntitiesWatchCenter;

  _stpData: DataStore;

  set stpData(d: DataStore) {
    this._stpData = d;
    this.stpDataEvent.emit(d);
  }

  get stpData(): DataStore {
    return this._stpData;
  }

  mapInfoHidden = false;

  private _drawControl: string;
  private _configControl: object;
  private conf: ConfigurationStore;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input('draw')
  get drawControl(): string {
    return this._drawControl;
  }
  set drawControl(id: string) {
    this._drawControl = id;
    if (id?.length > 0 && id !== 'save' && id !== 'cancel') {
      this.map.enableDrawControl(id);
    } else {
      this.map?.disableDrawControl(id);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private eventsSubscription: Subscription;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() events: Observable<void>;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Output()
  config = {
    openFileOptions: {
      types: [
        {
          description: 'JSON Text Files',
          accept: {
            'application/json': ['.json'],
          },
        },
      ],
      multiple: false,
      excludeAcceptAllOption: true,
    },
    saveFileOptions: {
      suggestedName: 'netmap-config.json',
      types: [
        {
          description: 'JSON Text Files',
          accept: { 'application/json': ['.json'] },
        },
      ],
      excludeAcceptAllOption: false, // default
    },
  };

  @Output()
  stpDataEvent = new EventEmitter<DataStore>();

  public getEntityContextMenu(data: any): IMenuItem[] {
    return [
      {
        label: 'Center To',
        icon: 'fa fa-magnifying-glass',
        command: (e) => {
          if (data) {
            this.watchCenter.command(data, {
              zoomTo: data,
            });
          }
        },
      },
      /*      {
        label: 'File',
        items: [
          {
            label: 'New',
            icon: 'pi pi-fw pi-plus',
            items: [{ label: 'Project' }, { label: 'Other' }],
          },
          { label: 'Open' },
          { label: 'Quit' },
        ],
      },
      {
        label: 'Edit',
        icon: 'pi pi-fw pi-pencil',
        items: [
          { label: 'Delete', icon: 'pi pi-fw pi-trash' },
          { label: 'Refresh', icon: 'pi pi-fw pi-refresh' },
        ],
      },
*/
    ];
  }

  pickOpen(a: FileSystemFileHandle) {
    a.getFile().then((f) => {
      MapUtilStore.loadFromJSON(
        this.mapContainer.nativeElement,
        this.watchCenter,
        f
      ).then((r1) => {
        this.map = r1.map;
        this.conf = r1.conf;
        MapUtilStore.saveToIndexDB(r1.conf).then(() => {
          this.reload();
        });
      });
    });
    //    new Blob([JSON.stringify(this.conf)], { type: 'application/json' }),
  }

  pickSave(fh: FileSystemFileHandle) {
    fh.createWritable().then(async (writer) => {
      (await MapUtilStore.saveToJSON(this.conf)).stream().pipeTo(writer);
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    //    private entitiesWatchService: EntitiesWatchService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    //this.pickOpen = this.pickOpen.bind(this);
    //this.pickSave = this.pickSave.bind(this);
    //this.mockConfig = this.mockConfig.bind(this);

    //this.watchCenter = entitiesWatchService;
    this.watchCenter = new EntitiesWatchCenter(
      'name',
      ['name', 'selected', 'style', 'tags'],
      MapUtilStore.saveEntityProperties
    );
  }

  ngOnInit() {
    new (class extends EntitiesWatcher {
      add(
        i: any,
        properties: { [key: string]: any },
        position: { [key: string]: any }
      ) {}

      remove(i: any) {}

      removeAll(i: any[]) {}

      update(i: any, properties: { [key: string]: any }) {}

      stUpdate(i: any, propertyNames: [key: string]) {}

      move(i: any) {}

      command(o: object) {}

      broadcast(i: any) {}
    })(this.watchCenter);

    this.eventsSubscription = this.events.subscribe((evt: any) => {
      //const data = evt.detail;
      switch (evt.type) {
        case 'save-config':
          this.pickSave(evt.detail);
          break;
        case 'load-config':
          this.pickOpen(evt.detail[0]);
          break;
        case 'set-coordinates':
          this.map.setCoordinates(evt.detail.latitude, evt.detail.longitude);
          break;
        case 'save-entities':
          this.saveURNStore();
          break;
      }
    });
    window.addEventListener('unload', (ev: Event) => {
      this.ngOnDestroy();
    });
  }

  ngAfterViewInit(): void {
    this.initConfig(true);
  }

  initConfig(initial: boolean) {
    const debugSpec = true;
    if (debugSpec && initial) {
      this.mockConfig().then(() => {
        this.initConfig(false);
      });
    } else {
      MapUtilStore.loadFromIndexDB(
        this.mapContainer.nativeElement,
        this.watchCenter
      ).then((r1) => {
        this.map = r1.map;
        this.conf = r1.conf;

        if (!r1.conf && initial) {
          this.mockConfig().then(() => {
            this.initConfig(false);
          });
        } else {
          this.map.addUIObserver(new MapInfoObserver(this.mapInfoContainer));
          new EventEmitter<any>();
        }

        this.stpData = MapUtilStore.scopeProperties(this.conf);
      });
    }
  }

  async mockConfig() {
    this.conf = await mockConfiguration();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  async saveURNStore() {
    await MapUtilStore.saveEntitiesProperties(this.conf, this.watchCenter);
    const i = 0;
  }

  async destroy() {
    this.map?.remove();
    this.eventsSubscription.unsubscribe();
    await this.saveURNStore();
  }

  reload() {
    this.router.navigate([this.router.url]);
  }
}
