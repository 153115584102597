
<p-pickList [source]="sourceItems" [target]="targetItems" sourceHeader="Layers" targetHeader="Selected" [dragdrop]="true" [responsive]="true" [sourceStyle]="{ height: '30rem' }"
    [targetStyle]="{ display: 'none', height: '30rem' }" filterBy="id" sourceFilterPlaceholder="Search by id" targetFilterPlaceholder="Search by id" breakpoint="1400px">
    <ng-template let-item pTemplate="item">
        <div class="flex flex-wrap p-2 align-items-center gap-3">
            <div class="flex-1 flex flex-column gap-2">
                <span class="font-bold">{{ item.id }}</span>
                <div class="flex align-products-center gap-2">
                    <i class="pi pi-tag text-sm"></i>
                    <span>{{ item.type }}</span>
                </div>
            </div>
<!--            <span class="font-bold text-900">{{ '$' + product.price }}</span> -->
        </div>
    </ng-template>
</p-pickList>
