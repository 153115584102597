<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <a href="/map_page">MAP PAGE</a>

  <ion-button (click)="takePicture()" expand="block">
    <ion-icon name="camera" slot="start"></ion-icon>
    Capture image
  </ion-button>

  <ion-img *ngIf="myImage" [src]="myImage"></ion-img>

  <ion-button (click)="getCurrentPosition()" expand="block">
    <ion-icon name="locate" slot="start"></ion-icon>
    Get position
  </ion-button>

  <!-- present the geolocation information -->
  <ion-card *ngIf="position">
    <ion-card-content>
      <ion-item>
        <ion-icon name="location" slot="start"></ion-icon>
        Lat: {{ position.coords.latitude }}
      </ion-item>
      <ion-item>
        <ion-icon name="location" slot="start"></ion-icon>
        Lng: {{ position.coords.longitude }}
      </ion-item>

      <ion-button (click)="share()" expand="block" color="secondary">
        <ion-icon name="share" slot="start"></ion-icon>
        Share!
      </ion-button>
    </ion-card-content>
  </ion-card>
</ion-content>
