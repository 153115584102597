/* eslint-disable @typescript-eslint/member-ordering */
import { Observer } from 'rxjs';
import { EntitiesWatchCenter } from './entities-watch-center';

export abstract class EntitiesWatcher {
  abstract add(i: any,properties: {[key: string]: any},position: {container?: any; before?: any; [key: string]: any});
  abstract remove(i: any,options?: {[key: string]: any});
  abstract removeAll(i: any[]);
  abstract update(i: any,properties: {[key: string]: any});
  abstract stUpdate(i: any, stPropertyNames?: [key: string]);
  abstract move(entity: any, position: {container?: any; before?: any; [key: string]: any});
  abstract command(i: any,o:  {[key: string]: any});
  abstract broadcast(i: any,o:  {[key: string]: any});

  constructor(watchCenter: EntitiesWatchCenter) {
    watchCenter.addObserver(this.entitiesObserver);
  }

  private entitiesObserver: Observer<any> = {
    next: (x) => {
      if (x?.add) {
        this.add(x?.add.entity,x?.add.properties,x?.add.position);
      }
      if (x?.remove) {
        this.remove(x?.remove.entity);
      }
      if (x?.removeAll) {
        this.removeAll(x?.removeAll.entities);
      }
      if (x?.stUpdate) {
        this.stUpdate(x?.stUpdate.entity, x?.stUpdate.propertyNames);
      }
      if (x?.update) {
        this.update(x?.update.entity, x?.update.properties);
      }
      if (x?.move) {
        this.move(x?.move.entity, x?.move.position);
      }
      if (x?.command) {
        this.command(x?.command.entity, x?.command.properties);
      }
      if (x?.broadcast) {
        this.broadcast(x?.broadcast.entity, x?.broadcast.properties);
      }
    },
    error: (err) => {},
    complete: () => {},
  };
}
