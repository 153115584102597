/* eslint-disable no-underscore-dangle */
import { GeodesicGeoJson } from '../geodesy/create-geodesic-geojson';
import { extDisplay } from './extDisplay';
import { IMapDraw, IMapDrawEnv } from './IMapDraw';

export class ExtDrawPoint extends IMapDraw {
  constructor(env: IMapDrawEnv,chain?: any) {
    super(env,chain);
  }

  toDisplayFeatures = function(state, geojson, display) {
    extDisplay(this,state, geojson, display);
  };
}
