import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenlayerComponent } from './openlayer/openlayer.component';

@NgModule({
  declarations: [OpenlayerComponent],
  imports: [
    CommonModule
  ],
  exports: [OpenlayerComponent]
})
export class OpenlayerModule { }
