import * as BABYLON from '@babylonjs/core';
import { PickingInfo } from '@babylonjs/core';
import * as BABYLONGUI from '@babylonjs/gui';
import { BabyContext } from './babycontext';
import { BabyGeoScene } from './babygeoscene';
import { BabyUI } from './babyUI';
import { WebMercator } from './webmercator';

const nameDir = {
  camera1: 'camera1',
  light1: 'light1',
  light2: 'light2',
  geoSphere: 'sphere1',
};

export class Babylines {
  scene: BABYLON.Scene;
  context: BabyContext;

  private nameTextBlock;

  constructor(context: BabyContext) {
    this.scene = context.scene;
    this.context = context;
  }

  static arcCurve(
    start: { lat: number; lon: number },
    end: { lat: number; lon: number },
    radius: number,
    it: number
  ): BABYLON.Curve3 {
    const firstc = { lat: start.lat, lon: start.lon };
    const lastc = { lat: end.lat, lon: end.lon };
    const midc = {
      lat: (lastc.lat + firstc.lat) / 2,
      lon: (lastc.lon + firstc.lon) / 2,
    };
    const first = WebMercator.getLonLatXYZ({
      lon: firstc.lon,
      lat: firstc.lat,
      r: radius,
    });
    const mid = WebMercator.getLonLatXYZ({
      lon: midc.lon,
      lat: midc.lat,
      r: radius,
    });
    const last = WebMercator.getLonLatXYZ({
      lon: lastc.lon,
      lat: lastc.lat,
      r: radius,
    });
    const firstv = new BABYLON.Vector3(first.x, first.y, first.z);
    const midv = new BABYLON.Vector3(mid.x, mid.y, mid.z);
    const lastv = new BABYLON.Vector3(last.x, last.y, last.z);
    return BABYLON.Curve3.ArcThru3Points(firstv, midv, lastv, it, false, false);
  }

  setScene(): void {
    // create spherical path test
    const sphereLine = BABYLON.MeshBuilder.CreateLines(
      'linetest',
      {
        points: Babylines.arcCurve(
          {
            lat: ((48 + 52 / 60) / 180) * Math.PI,
            lon: ((190 + 2 + 19 / 60) / 180) * Math.PI,
          },
          {
            lat: (-25 / 180) * Math.PI,
            lon: (190 + 75 / 180) * Math.PI
          },
          this.context.dimension,
          100
        ).getPoints(),
        updatable: false,
      },
      this.scene
    );
    sphereLine.color = new BABYLON.Color3(1, 0, 0);
    sphereLine.enablePointerMoveEvents = true;
/*    sphereLine.edgesColor = new BABYLON.Color4(0, 1, 0, 1);
    sphereLine.edgesWidth = 200;
    sphereLine.enableEdgesRendering();
*/
    //sphereLine.thinInstanceEnablePicking = true;
    //sphereLine.useVertexColor = true;
    //sphereLine.enableDistantPicking = true;
//    sphereLine.showBoundingBox = true;
//    sphereLine.showSubMeshesBoundingBox = true;
//    sphereLine.overlayColor = BABYLON.Color3.Blue();
//    sphereLine.renderOverlay = true;
  }
}
