import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BabymapComponent } from '../babymap/babymap/babymap.component';
//import { CesiumComponent } from '../cesium/cesium.component';
import { LeafletmapComponent } from '../leafletmap/leafletmap/leafletmap.component';
import { OpenlayerModule } from '../openlayer/openlayer.module';
import { OpenlayerComponent } from '../openlayer/openlayer/openlayer.component';
import { WorldwindComponent } from '../worldwind/worldwind.component';
import { MapPageComponent } from './map-page.component';

const routes: Routes = [
  { path: '', redirectTo: '/map_page', pathMatch: 'full' },
  { path: 'map_page', component: MapPageComponent },
  { path: 'map_page/babymap', component: BabymapComponent },
  { path: 'map_page/leaflet', component: LeafletmapComponent },
//  { path: 'map_page/cesium', component: CesiumComponent },
  { path: 'map_page/worldwind', component: WorldwindComponent },
  { path: 'map_page/openlayer', component: OpenlayerComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes),OpenlayerModule],
  exports: [RouterModule],
})
export class MapPageRoutingModule {}
