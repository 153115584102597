import proj4 from 'proj4';
import { ArgumentError } from './ArgumentError';
import { GeoJSONConstants } from './GeoJSONConstants';
import { GeoJSONCRS } from './GeoJSONCRS';
import { GeoJSONFeature } from './GeoJSONFeature';
import { GeoJSONFeatureCollection } from './GeoJSONFeatureCollection';
import { GeoJSONGeometry } from './GeoJSONGeometry';
import { GeoJSONGeometryCollection } from './GeoJSONGeometryCollection';
import { GeoJSONGeometryLineString } from './GeoJSONGeometryLineString';
import { GeoJSONGeometryMultiLineString } from './GeoJSONGeometryMultiLineString';
import { GeoJSONGeometryMultiPoint } from './GeoJSONGeometryMultiPoint';
import { GeoJSONGeometryMultiPolygon } from './GeoJSONGeometryMultiPolygon';
import { GeoJSONGeometryPoint } from './GeoJSONGeometryPoint';
import { GeoJSONGeometryPolygon } from './GeoJSONGeometryPolygon';
import { Logger } from './Logger';
import { Placemark } from './Placemark';
import { Position } from './Position';
import { Location } from './Location';
import { RenderableLayer } from './RenderableLayer';
import { WorldWind } from './WorldWind';
import { SurfacePolyline } from './SurfacePolyline';
import { SurfacePolygon } from './SurfacePolygon';
import { GeoJSONProperties } from './GeoJSONProperties';
import { GeoJSONWrap } from './GeoJSONWrap';

export class GeoJSONParser {
  private geoGSONObjectWrap: any;
  private dataSource: string | object;
  private parserCompletionCallback: () => void;
  private objectCallback: (layer: any, wrap: GeoJSONWrap) => any;
  private layer: any;
  private crs: any;
  private geoJSONObject: any;

  constructor(dataSource: string | object, clientCRS: string) {
    if (!dataSource) {
      throw new ArgumentError(
        Logger.logMessage(
          Logger.LEVEL_SEVERE,
          'GeoJSON',
          'constructor',
          'missingDataSource'
        )
      );
    }

    // Documented in defineProperties below.
    this.dataSource = dataSource;

    // Documented in defineProperties below.
    this.crs = null;
  }

  load(
    layer?: any,
    parserCompletionCallback?: () => void,
    objectCallback?: (layer: any, wrap: GeoJSONWrap) => any
  ) {
    if (parserCompletionCallback) {
      this.parserCompletionCallback = parserCompletionCallback;
    }

    if (objectCallback) {
      this.objectCallback = objectCallback;
    }

    this.layer = layer;

    if (typeof this.dataSource === typeof '') {
      const obj = GeoJSONParser.tryParseJSONString(this.dataSource as string);
      if (obj !== null) {
        this.handle(obj);
      } else {
        this.requestUrl(this.dataSource);
      }
    } else if (this.dataSource instanceof Object) {
      this.handle(this.dataSource);
    } else {
      Logger.logMessage(
        Logger.LEVEL_SEVERE,
        'GeoJSON',
        'load',
        'Unsupported data source type: ' + typeof this.dataSource
      );
    }
  }

  // Get GeoJSON string using XMLHttpRequest. Internal use only.
  requestUrl(url) {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', url, true);
    xhr.responseType = 'text';
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          this.handle(GeoJSONParser.tryParseJSONString(xhr.response));
        } else {
          Logger.log(
            Logger.LEVEL_WARNING,
            'GeoJSON retrieval failed (' + xhr.statusText + '): ' + url
          );
        }
      }
    };

    xhr.onerror = () => {
      Logger.log(Logger.LEVEL_WARNING, 'GeoJSON retrieval failed: ' + url);
    };

    xhr.ontimeout = () => {
      Logger.log(Logger.LEVEL_WARNING, 'GeoJSON retrieval timed out: ' + url);
    };

    xhr.send(null);
  }

  // Handles the object created from the GeoJSON data source. Internal use only.
  handle(obj) {
    if (!obj) {
      Logger.logMessage(
        Logger.LEVEL_SEVERE,
        'GeoJSON',
        'handle',
        'Invalid GeoJSON object'
      );
    }

    this.geoJSONObject = obj;

    this.geoGSONObjectWrap = GeoJSONWrap.getObject(
      null,
      null,
      obj,
      (wrap: GeoJSONWrap) => {
        // do some CRS conversion stuff
        if (wrap.crs && this.crs) {
          //TODO change to client CRS
        }
        return this.objectCallback(this.layer, wrap);
      }
    );

    if (
      !!this.parserCompletionCallback &&
      typeof this.parserCompletionCallback === 'function'
    ) {
      this.parserCompletionCallback();
    }
  }

  /**
   * Reprojects GeoJSON geometry coordinates if required using proj4js.
   *
   * @param latitude The latitude coordinate of the geometry.
   * @param longitude The longitude coordinate of the geometry.
   * @param crsObject The GeoJSON CRS object.
   * @returns An array containing reprojected coordinates.
   * @throws {ArgumentError} If the specified latitude is null or undefined.
   * @throws {ArgumentError} If the specified longitude is null or undefined.
   * @throws {ArgumentError} If the specified crsObject is null or undefined.
   */
  getReprojectedIfRequired(
    latitude: number,
    longitude: number,
    crsObject: GeoJSONCRS
  ): number[] {
    if (!latitude && latitude !== 0.0) {
      throw new ArgumentError(
        Logger.logMessage(
          Logger.LEVEL_SEVERE,
          'GeoJSON',
          'getReprojectedIfRequired',
          'missingLatitude'
        )
      );
    }

    if (!longitude && longitude !== 0.0) {
      throw new ArgumentError(
        Logger.logMessage(
          Logger.LEVEL_SEVERE,
          'GeoJSON',
          'getReprojectedIfRequired',
          'missingLongitude'
        )
      );
    }

    if (!crsObject || crsObject.isDefault()) {
      return [longitude, latitude];
    } else {
      return proj4(crsObject.projectionString, GeoJSONConstants.EPSG4326_CRS, [
        longitude,
        latitude,
      ]);
    }
  }

  /**
   * Tries to parse a JSON string into a JavaScript object.
   *
   * @param str the string to try to parse.
   * @returns the object if the string is valid JSON; otherwise null.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  static tryParseJSONString(str: string): object {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  }
  setProj4jsAliases() {
    proj4.defs('urn:ogc:def:crs:EPSG::4326', proj4.defs('EPSG:4326'));

    proj4.defs('urn:ogc:def:crs:OGC:1.3:CRS84', proj4.defs('EPSG:4326'));

    proj4.defs('urn:ogc:def:crs:EPSG::3857', proj4.defs('EPSG:3857'));

  }
}
