/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import {
  GeoJSONSource,
  LayerSpecification,
  Map as MapLibre,
  MapOptions,
  NavigationControl,
  Popup,
  SourceSpecification,
  LngLatBounds,
  FeatureIdentifier,
} from 'maplibre-gl';

import { EntitiesWatchCenter } from 'src/app/watch-center/entities-watch-center';
import { GeoJSONParser } from '../../geojson/GeoJSONParser';
import { GeoJSONWrap } from 'src/app/geojson/GeoJSONWrap';
import { LayerGroup } from './layergroup';
import { MapUtilDraw } from './maputil-draw';
import { MapUtilIds } from './maputil-ids';
import { EntitiesWatcher } from 'src/app/watch-center/entities-watcher';
import { ConfigurationStore, MapUtilStore } from './maputil-store';
import { GeometryGhost } from './geodesy/geometryGhost';
import { geoBounds } from 'd3-geo';
import { Dms } from './geodesy/dms';
import { BehaviorSubject, Observer, Subscription } from 'rxjs';
import { LayersControl } from './layersControl';

class LatLonPopup {
  lat;
  lon;
  popup: Popup;
  timeoutHandle;
  constructor(private map) {}
  set = (e) => {
    this.map.uiEventWatch.uiEvent('MapExt', 'latlon', {
      lat: e.lngLat.lat,
      lon: e.lngLat.lng,
    });
    window.clearTimeout(this.timeoutHandle);
    this.timeoutHandle = window.setTimeout(() => {
      this.unset();
    }, 5000);
  };
  unset = () => {
    this.map.uiEventWatch.uiEvent('MapExt', 'latlon');
  };
  set1 = (e) => {
    this.lat = e.lngLat.lat;
    this.lon = e.lngLat.lng;
    if (!this.popup) {
      this.popup = new Popup();
      this.popup.addTo(this.map);
    }
    this.popup
      .setLngLat(e.lngLat)
      .setHTML(
        'lat: ' +
          new Dms(this.lat, 'lat').toString(2) +
          '<br>  lon: ' +
          new Dms(this.lon, 'lon').toString(2)
      );
    window.clearTimeout(this.timeoutHandle);
    this.timeoutHandle = window.setTimeout(() => {
      this.popup.remove();
      this.popup = undefined;
    }, 5000);
  };
}

class UIEventWatch extends BehaviorSubject<any> {
  addUIObserver(observer: Observer<any>): Subscription {
    return this.subscribe(observer);
  }

  uiEvent(sourceId: string, eventId: string, event?: any) {
    this.next({ sourceId, eventId, event });
  }
}

export class MapExt extends MapLibre {
  layersControl: LayersControl = new LayersControl(this, 'name');

  private sources: Map<string, SourceSpecification> = new Map<
    string,
    SourceSpecification
  >();

  private sourcesToRefresh: Map<string, any> = new Map<string, any>();

  private hoverPopup: Map<
    string,
    {
      featureId;
      sourceId;
      popup: Popup;
    }
  > = new Map();

  private uiEventWatch: UIEventWatch = new UIEventWatch(null);

  private latLonPopup: LatLonPopup = new LatLonPopup(this);

  private featureWrapMap: Map<any, GeoJSONWrap> = new Map<any, GeoJSONWrap>();
  private addAfterLoad: Array<object> = new Array<object>();
  private onloadEvent = false;
  private styleloadEvent = false;

  // flag map indicating the feature state changes shall be deferred until app event occurs
  private featureStateDeffer: Map<string, boolean> = new Map<string, boolean>();

  private draw: MapUtilDraw;
  watchCenter: EntitiesWatchCenter;

  constructor(
    private confStore: ConfigurationStore,
    options: MapOptions,
    watchCenter: EntitiesWatchCenter,
    private callOnready: () => void
  ) {
    super(options);
    this.watchCenter = watchCenter;
    this.initialize();
  }

  addUIObserver(observer: Observer<any>) {
    this.uiEventWatch.addUIObserver(observer);
  }

  public getConfigStore(): ConfigurationStore {
    return this.confStore;
  }

  enableDrawControl(type: string) {
    this.draw?.enableDrawControl(type);
  }

  disableDrawControl(mode: string) {
    this.draw?.disableDrawControl(mode);
  }

  setCoordinates(latitude: any, longitude: any) {
    this.draw?.setCoordinates(latitude, longitude);
  }

  setSourceData(drawRhumbSourceId: string, features: any) {
    throw new Error('Method not implemented.');
  }

  addSourceExt(id: string, source: SourceSpecification): this {
    if (this.onloadEvent) {
      if (
        source.type === 'geojson' &&
        (typeof source.data === 'string' || typeof source.data === 'object')
      ) {
        if (!source.promoteId) {
          source.promoteId = 'id';
        }
        const data = source.data;
        source.data = {
          // remove data temporarily from the spec as it will be wrapped for runtime
          type: 'FeatureCollection',
          features: [],
        };
        this.sources.set(id, source);
        this.removeSourceExt(id);
        super.addSource(id, source);
        this.layersControl.setGroup(id, new LayerGroup(this, id));
        this.watchCenter.add(id, { name: id, selected: true, source });
        this.setGeoJSONWrapSource(id, data);
        MapUtilStore.loadEntitiesProperties(
          this.confStore,
          this.watchCenter,
          id
        ).then((storedEntities) => {
          storedEntities.forEach((storedEntity) => {
            this.watchCenter.setEntityURNStore(storedEntity.entity);
            /*            const watchedEntity = this.watchCenter.getEntityByURN(
              storedEntity.id
            );
            if (watchedEntity) {
              this.watchCenter.update(
                watchedEntity.entity,
                storedEntity.entity.properties,
                true // transient as alreaady stored
              );
              if (storedEntity.entity.position) {
                const container = this.watchCenter.getEntityByURN(
                  storedEntity.entity.position.container
                );
                const before = this.watchCenter.getEntityByURN(
                  storedEntity.entity.position.before
                );
                this.watchCenter.move(watchedEntity.entity, {
                  container,
                  before,
                });
              }
            } */
          });
          this.watchCenter.traverseTree(id, (cdata, pdata) => {
            if (cdata !== id) {
              const lppselected =
                (this.watchCenter.getProperties(pdata, ['pselected']).pselected ??
                  true) &&
                (this.watchCenter.getProperties(pdata, ['selected']).selected ??
                  true);
              this.watchCenter.update(cdata, { pselected: lppselected ?? true });
            }
            return true;
          });
          const i = 0;
        });
        source.data = data; // set back the spec data after setting source runtime
        this.fire('feature.state', { id });
      } else {
        this.sources.set(id, source);
        super.addSource(id, source);
        this.watchCenter.add(id, { name: id, selected: true, source });
      }
    } else {
      this.addAfterLoad.push({ source: { id, source } });
    }
    return this;
  }

  private removeWatches(entity: any) {
    this.watchCenter.traverseTree(entity, (lentity, container) => {
      this.watchCenter.remove(lentity);
      return true;
    });
  }

  private removeSourceExt(id: string) {
    if (super.getSource(id)) {
      super.removeSource(id);
      this.removeWatches(id);
    }
  }
  setGeoJSONWrapSource(id: string, data: any) {
    // eslint-disable-next-line no-underscore-dangle
    //const geodesic = data._geodesic ? true : false;

    const pmap: Map<any, any> = new Map<any, any>();

    const entities = this.watchCenter.filterEntities(
      (
        entity: any,
        properties: { [key: string]: any },
        position?: { container?: any; before?: any }
      ) => {
        if (
          entity instanceof GeoJSONWrap &&
          (entity as GeoJSONWrap).getRoot().rootId === id
        ) {
          pmap.set(entity.obj.id, { properties, position });
          return true;
        } else {
          return false;
        }
      }
    );

    entities.forEach((item) => {
      this.watchCenter.remove(item);
      this.featureWrapMap.delete(item.obj);
    });

    const parser = new GeoJSONParser(data);

    const context1 = {};
    let objCount = 0;

    parser.load({
      context: context1,
      parserCompletionCallback: (context: any, wrap: GeoJSONWrap) => {
        /*        if (geodesic) {
          GeodesicGhost.geodesicInterpolate(wrap.obj);
          GeodesicGhost.geodesicGeometryForward(wrap.obj);
        }
*/
        GeometryGhost.geoGhost(wrap.obj);
        GeometryGhost.ghostGeometryForward(wrap.obj);
        (this.getSource(id) as GeoJSONSource).setData(wrap.obj);
        wrap.rootId = id;
        this.watchCenter.execDefered(id);
      },
      objectCallback: (
        context: any,
        wrap: GeoJSONWrap,
        pwrap?: GeoJSONWrap
      ) => {
        if (wrap?.obj?.properties) {
          // count the frequency of property names
          const properties = Object.getOwnPropertyNames(wrap.obj.properties);
          objCount++;
          properties.forEach((property) => {
            if (context.hasOwnProperty(property)) {
              context[property]++;
            } else {
              context[property] = 1;
            }
          });
        }
        /*        if(wrap.obj?.properties?.geodesic) {
          GeometryGhost.geodesicInterpolate(wrap.obj);
          GeometryGhost.geodesicGeometryForward(wrap.obj);
        }
        if(wrap.obj?.properties?.circle) {
        }
*/
        switch (wrap.obj.type) {
          case 'Feature':
          case 'FeatureCollection':
            MapUtilIds.setFeaturesId('oid_', wrap.obj);
            this.featureWrapMap.set(id + '/' + wrap.obj.id, wrap);
            const name = wrap.obj.properties?.name ?? wrap.obj.id;
            const x = pmap.get(wrap.obj.id);
            const properties = {
              ...{
                name,
                id: wrap.obj.id,
                selected: true,
                tags: { _scheme: {} },
                style: { _scheme: {} },
              },
              ...x?.properties,
            };
            this.watchCenter.defer(id).add(wrap, properties, {
              container: pwrap ?? id,
            });
            break;
        }
      },
    });
  }

  featureCenterTo(obj) {
    const bbox2 = geoBounds(obj);
    const bbox4 = LngLatBounds.convert(bbox2);
    if (bbox2[0][0] > 0 && bbox2[1][0] < 0) {
      // eslint-disable-next-line no-underscore-dangle
      bbox4._sw.lng -= 360;
    }
    const bbox3 = this.getBounds();
    const bbox = bbox2;
    this.fitBounds(bbox4, {
      padding: 50,
    });
  }

  featureHover(
    sourceId: string,
    featureId: string | number,
    on: boolean,
    options?: { lngLat; name: string }
  ) {
    // https://maplibre.org/maplibre-gl-js-docs/api/map/#map#setfeaturestate

    const key = sourceId + '/' + featureId;

    if (!on) {
      this.hoverPopup.forEach((item) => {
        item.popup?.remove();
        this.setFeatureState(
          { source: item.sourceId, id: item.featureId },
          { hover: false }
        );
      });
      this.hoverPopup.clear();
    }

    if (on) {
      let popup = this.hoverPopup.get(key)?.popup;

      if (options) {
        if (!popup) {
          popup = new Popup();
        }
        popup.setLngLat(options.lngLat).setHTML(options.name).addTo(this);
      }
      this.hoverPopup.set(key, {
        sourceId,
        featureId,
        popup,
      });
    } else {
      const o = this.hoverPopup.get(key);
      this.hoverPopup.delete(key);
      o?.popup?.remove();
    }
    if (sourceId && featureId) {
      this.setFeatureState({ source: sourceId, id: featureId }, { hover: on });
    }
  }

  featureHighlight(
    sourceId: string,
    featureId: string | number,
    on: boolean,
    options?: { lngLat; name: string }
  ) {
    // https://maplibre.org/maplibre-gl-js-docs/api/map/#map#setfeaturestate

    const key = sourceId + '/' + featureId;

    if (on) {
      let popup;
      if (options) {
        popup = new Popup();
        popup.setLngLat(options.lngLat).setHTML(options.name).addTo(this);
        this.hoverPopup.set(key, {
          sourceId,
          featureId,
          popup,
        });
      }
    } else {
      const o = this.hoverPopup.get(key);
      this.hoverPopup.delete(key);
      o?.popup?.remove();
    }
    if (sourceId && featureId) {
      this.setFeatureState(
        { source: sourceId, id: featureId },
        { highlight: on }
      );
    }
  }

  featureHighlightToggle(feature) {
    const sourceId = feature.source;
    const wrap = this.featureWrapMap.get(sourceId + '/' + feature.id);
    let h = this.watchCenter.getProperties(wrap, ['highlight']).highlight;
    h = !(h ?? false);
    this.watchCenter.update(wrap, { highlight: h });
    this.featureHighlight(sourceId, feature.id, h);
  }

  addLayerExt(
    layer: LayerSpecification & { source?: string | SourceSpecification },
    beforeId?: string
  ): this {
    if (this.onloadEvent) {
      this.layersControl.setLayer(layer.id, layer);

      if (super.getLayer(layer.id)) {
        super.removeLayer(layer.id);
      }
      super.addLayer(layer, beforeId);

      let sourceId;

      if (typeof (layer as any).source === 'string') {
        sourceId = (layer as any).source;
      }

      this.on('mouseenter', layer.id, (e) => {
        // Change the cursor style as a UI indicator.
        this.getCanvas().style.cursor = 'pointer';
        if (e.features && e.features.length > 0) {
          MapUtilIds.setFeaturesId('drawautoid_', e.features[0]);
          const description = e.features[0].properties.description;
          const name =
            e.features[0].properties.name ?? e.features[0].properties.id;

          this.featureHover(sourceId, e.features[0].properties.id, true, {
            lngLat: e.lngLat,
            name,
          });
        }
      });

      this.on('mouseleave', layer.id /* 'lines' */, (e) => {
        this.getCanvas().style.cursor = '';
        this.featureHover(sourceId, undefined, false);
      });

      this.doubleClickZoom.disable();
      this.on('dblclick', layer.id, (e) => {
        if (e.features && e.features.length > 0) {
          this.featureCenterTo(e.features[0]);
          this.featureHighlightToggle(e.features[0]);
        }
      });
    } else {
      this.addAfterLoad.push({ layer: { layer, beforeId } });
    }
    return this;
  }

  getLayers() {
    const layers = this.getStyle().layers;
    return layers;
  }

  private initialize() {
    const lthis = this;

    //this.testCapacitorFS();

    this.watchCenter.removeAll();
    new (class extends EntitiesWatcher {
      add(
        i: any,
        properties: { [key: string]: any },
        position: { [key: string]: any }
      ) {
        if (i instanceof GeoJSONWrap) {
          const rootWrap = (i as GeoJSONWrap).getRoot();
          if (i.obj?.properties?.id) {
            lthis.setFeatureState(
              { source: rootWrap.rootId, id: i.obj.properties.id },
              { style: {} }
            );
          }
        }
        this.update(i, properties, true);
        this.move(i, position);
      }
      remove(i: any[], options: { [key: string]: any }) {}
      removeAll(i: any[]) {}

      update(i: any, properties: { [key: string]: any }, transient?: boolean) {
        if (properties.name && i?.obj?.properties) {
          i.obj.properties.name = properties.name;
        }
      }
      stUpdate(i: any, propertyNames: [key: string]) {
        //const mProperties = lthis.watchCenter.getMergedSTProperties(i,properties);
        const toUpdate: string[] = [
          'pselected',
          'selected',
          'highlight',
          'style',
        ].filter((value) => propertyNames.includes(value));
        const properties = lthis.watchCenter.getMergedSTProperties(i, toUpdate);
        if (i instanceof GeoJSONWrap && i.obj?.properties != null) {
          if (properties.pselected != null || properties.selected != null) {
            // 'feature-state' can't be used to filter features
            // eslint-disable-next-line no-underscore-dangle
            i.obj.properties._hide = !(properties.pselected ?? true) || !(properties.selected ?? true);
            const rootWrap = (i as GeoJSONWrap).getRoot();
            lthis.refreshSources(rootWrap.rootId, rootWrap.obj);
/*            lthis.setFeatureState(
              { source: rootWrap.rootId, id: i.obj.properties.id },
              {
                hide:
                  !(properties.pselected ?? true) ||
                  !(properties.selected ?? true),
              }
            );
            */
          }
          if (properties.highlight != null) {
            const rootWrap = (i as GeoJSONWrap).getRoot();
            lthis.featureHighlight(
              rootWrap.rootId,
              i.obj.properties.id,
              // eslint-disable-next-line no-underscore-dangle
              properties.highlight
            );
          }
          if (properties.style) {
            const rootWrap = (i as GeoJSONWrap).getRoot();
            lthis.setFeatureState(
              { source: rootWrap.rootId, id: i.obj.properties.id },
              { style: properties.style }
            );
          }
        }
      }

      move(
        entity: any,
        position: { [key: string]: any; container?: any; before?: any }
      ) {}
      command(i: any, o: { [key: string]: any }) {
        if (o.zoomTo) {
          //const bbox1 = turfBbox(o.zoomTo.obj);
          const bbox2 = geoBounds(o.zoomTo.obj);
          const bbox4 = LngLatBounds.convert(bbox2);
          if (bbox2[0][0] > 0 && bbox2[1][0] < 0) {
            // eslint-disable-next-line no-underscore-dangle
            bbox4._sw.lng -= 360;
          }
          const bbox3 = lthis.getBounds();
          const bbox = bbox2;
          /*          if (bbox.length === 4) {
            lthis.fitBounds([bbox[0], bbox[1], bbox[2], bbox[3]], {
              padding: 50,
            });
          }
         if (bbox.length === 2) {
            lthis.fitBounds([bbox[0][0]-360, bbox[0][1], bbox[1][0], bbox[1][1]], {
              padding: 50,
            });
          }
        */
          lthis.fitBounds(bbox4, {
            padding: 50,
          });
        }
      }
      broadcast(i: any, o: { [key: string]: any }) {
        if (i instanceof GeoJSONWrap && o.hover !== undefined) {
          const rootWrap = (i as GeoJSONWrap).getRoot();
          const name = i.obj.properties.name ?? i.obj.properties.id;
          lthis.featureHover(
            rootWrap.rootId,
            i.obj.properties.id,
            // eslint-disable-next-line no-underscore-dangle
            o.hover
          );
        }
      }
    })(this.watchCenter);

    this.on('load', (el) => {
      this.onloadEvent = true;

      this.addAfterLoad.forEach((item: any) => {
        if (item.source) {
          this.addSourceExt(item.source.id, item.source.source);
        }
        if (item.layer) {
          this.addLayerExt(item.layer.layer, item.layer.beforeId);
        }
      });
      this.addAfterLoad = new Array<object>();
      this.addControl(new NavigationControl({}), 'top-right');
      this.on('draw.create', this.updateArea);
      this.on('draw.delete', this.updateArea);
      this.on('draw.update', this.updateArea);
      this.callOnready();
    });

    this.on('style.load', (e) => {
      this.styleloadEvent = true;
      this.getStyle().layers.forEach((layer) => {
        this.layersControl.setLayer(layer.id, layer);
      });
    });

    this.on('mousemove', (e) => {
      this.latLonPopup.set(e);
    });

    Promise.all([this.confStore.conf.sources, this.confStore.conf.layers])
      .then(([sourceIds, layerIds]) => {
        sourceIds.findIndex((sourceId) => {
          const source = this.confStore.sources.find(
            (value) => value.id === sourceId
          );
          if (source) {
            this.addSourceExt(source.id, source.specification);
          }
        });
        layerIds.findIndex((layerId) => {
          const layer = this.confStore.layers.find(
            (value) => value.id === layerId
          );
          if (layer) {
            this.addLayerExt(layer.specification, layer.beforeId);
          }
        });
      })
      .then(() => {
        this.draw = new MapUtilDraw(this, this.watchCenter, this.confStore);
      });
  }
  refreshSourcesTimerId: number;
  private refreshSources(sourceId: string, sourceData: any) {
    this.sourcesToRefresh.set(sourceId, sourceData);
    if (this.refreshSourcesTimerId != null) {
      window.clearTimeout(this.refreshSourcesTimerId);
      this.refreshSourcesTimerId = undefined;
    }
    this.refreshSourcesTimerId = window.setTimeout(() => {
      this.refreshSourcesTimerId = undefined;
      const sources: Map<string, any> = this.sourcesToRefresh;
      this.sourcesToRefresh = new Map<string, any>();
      sources.forEach((sourceDataValue, sourceIdKey) => {
        const source = this.getSource(sourceIdKey);
        if (source instanceof GeoJSONSource) {
          (source as GeoJSONSource).setData(sourceDataValue);
        }
      });
    }, 200);
  }
  setSourceVisible(sourceId: string) {
    this.layersControl.setSourceVisible(sourceId);
  }
  setSourceInvisible(sourceId: string) {
    this.layersControl.setSourceInvisible(sourceId);
  }
  private updateArea(e) {
    const toto = 0; // for setting debug breakpoint purposes
  }
}
