import { LayerSpecification, SourceSpecification } from 'maplibre-gl';
import { EntitiesWatchCenter } from 'src/app/watch-center/entities-watch-center';
import { MapExt } from './mapExt';

export class LayerGroup {
  map: MapExt;
  groupId: string;

  layers: Map<string, LayerSpecification> = new Map<
    string,
    LayerSpecification
  >();

  constructor(map: MapExt, groupId: string) {
    this.map = map;
  }

  addLayers(layers: LayerSpecification[], beforeId?: string) {
    layers.forEach((value: LayerSpecification) => {
      this.layers.set(value.id,value);
    });
  }
  removeLayers(layers: string[]) {
    layers.forEach((key) => {
      this.layers.delete(key);
    });
  }
  setVisible(layers?: string[]) {
    if (!layers) {
      layers = [...this.layers.keys()];
    }
    layers.forEach((key) => {
      this.map.setLayoutProperty(key, 'visibility', 'visible');
    });
  }
  setInvisible(layers?: string[]) {
    if (!layers) {
      layers = [...this.layers.keys()];
    }
    layers.forEach((key) => {
      this.map.setLayoutProperty(key, 'visibility', 'none');
    });
  }
}
