/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { ExtDrawLineString } from './extDrawLineString';
import { ExtDrawPolygon } from './extDrawPolygon';
import { ExtDrawCircle } from './extDrawCircle';
import { ExtDrawPoint } from './extDrawPoint';
import { ExtSimpleSelect } from './extSimpleSelect';
import { ExtDirectSelect } from './extDirectSelect';
import { ExtStatic } from './extStatic';
import { ExtDrawRadius } from './extDrawRadius';
import { parseDms } from '../geodesy/dms';
import { IMapDrawEnv } from './IMapDraw';

//https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/MODES.md#available-custom-modes

export class MapDrawExt extends MapboxDraw implements Record<string, any> {
  [x: string]: any;
  private static enable(env: IMapDrawEnv, modes) {
    return {
      ...modes,
      draw_line_string: new ExtDrawLineString(env, modes.draw_line_string),
      draw_polygon: new ExtDrawPolygon(env, modes.draw_polygon),
      draw_circle: new ExtDrawCircle(env, modes.draw_circle),
      draw_radius: new ExtDrawRadius(env, MapboxDraw.modes.draw_line_string),
      draw_point: new ExtDrawPoint(env, modes.draw_point),
      simple_select: new ExtSimpleSelect(env, modes.simple_select),
      direct_select: new ExtDirectSelect(env, modes.direct_select),
      static: new ExtStatic(env, modes.static_select),
    };
  }

  env: IMapDrawEnv;

  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  eventHandler(event: string, t, e, mode) {
    //MapDrawExt.elevatedMap.set(this.id,this);
    this.mode = mode;
    this.state = t;
    switch (event) {
      case 'clickOnVertex':
      case 'dragVertex':
        const selectedPoints = mode._ctx.api.getSelectedPoints.call(mode);
        if (selectedPoints.features.length === 1) {
          mode.map.uiEventWatch.uiEvent('MapDrawExt', 'selectVertex', {
            lat: selectedPoints.features[0].geometry.coordinates[1],
            lon: selectedPoints.features[0].geometry.coordinates[0],
          });
        } else {
          mode.map.uiEventWatch.uiEvent('MapDrawExt', 'selectVertex', null);
        }
        break;
      case 'clickNoTarget':
        mode.map.uiEventWatch.uiEvent('MapDrawExt', 'selectVertex', null);
        break;
      case 'onVertex':
        {
          const cp1 = e.featureTarget.properties.coord_path;
          const cp2 = t.selectedCoordPaths;
          const a = cp1.split('.');
          let r = t.feature.coordinates;
          let r0;
          let i1;
          a.forEach((i) => {
            r0 = r;
            i1 = i;
            r = r[i];
          });
          if (r0) {
            this.selectedVertexPoint = r0[i1];
            //this.setCoordinates(0,0);
            mode.map.uiEventWatch.uiEvent('MapDrawExt', 'selectVertex', {
              lat: r0[i1][1],
              lon: r0[i1][0],
            });
          }
        }
        break;
    }
  }

  setCoordinates(latitude, longitude) {
    // https://github.com/mapbox/mapbox-gl-draw/blob/d08dd01ab5962093c6d59a3768c22871b9945e95/src/modes/direct_select.js#L79
    if (this?.state?.selectedCoordPaths) {
      this?.state?.selectedCoordPaths?.forEach((scp) =>
        this?.state?.feature?.updateCoordinate(
          scp,
          typeof longitude === typeof '' ? parseDms(longitude) : longitude,
          typeof latitude === typeof '' ? parseDms(latitude) : latitude
        )
      );
    } else {
      this.setCoordinates1(latitude, longitude);
    }
    this.mode?._ctx?.store?.render();
  }

  private setCoordinates1(latitude, longitude) {
    const selectedFeatures = this.getSelected();
    const selectedFeaturesIds = this.getSelectedIds();
    const selectedPoints = this.getSelectedPoints();
    const subSetCoordinates = (coords, c, lat, lon) => {
      coords.forEach((pc) => {
        if (pc[0] === c[0] && pc[1] === c[1]) {
          pc[0] = lon;
          pc[1] = lat;
        }
      });
    };
    if (
      selectedPoints?.features?.length > 0 &&
      selectedFeatures?.features?.length > 0
    ) {
      latitude = typeof latitude === typeof '' ? parseDms(latitude) : latitude;
      longitude =
        typeof longitude === typeof '' ? parseDms(longitude) : longitude;
      selectedPoints.features.forEach((point) => {
        if (point.geometry.type === 'Point') {
          const pc = point.geometry.coordinates;
          selectedFeaturesIds.forEach((featureId) => {
            const feature = {
              geometry: this.mode?._ctx?.store?.get(featureId),
            };
            switch (feature.geometry.type) {
              case 'Polygon':
                {
                  // TODO Circle, adjust center to lat lon
                  feature.geometry.coordinates.forEach((c) =>
                    subSetCoordinates(c, pc, latitude, longitude)
                  );
                }
                break;
              case 'LineString':
                {
                  subSetCoordinates(
                    feature.geometry.coordinates,
                    pc,
                    latitude,
                    longitude
                  );
                }
                break;
            }
            this.mode?._ctx?.store?.featureChanged(
              featureId
            );
          });
        }
      });
    }
  }

  getCoordPath(coordinates: [[]], coord: []): string {
    //TODO implement function to return a coord path from coordinates and a matching coord
    return '';
  }

  constructor(
    id: string,
    drawcontrols: { geodesic: boolean },
    options?: object
  ) {
    const env = {
      option: drawcontrols,
      event: undefined,
    };

    const modes = MapDrawExt.enable(env, MapboxDraw.modes);
    super({ ...options, modes, userProperties: true });
    const pOnAdd = this.onAdd;
    const pOnRemove = this.onRemove;
    this.env = env;
    this.env.event = this.eventHandler.bind(this);
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    this.onAdd = function(map) {
      this.map = map;

      const container = pOnAdd.call(this, map);

      const button = MapDrawExt.createControlButton('circle', {
        container,
        className: 'mapbox-gl-draw_circle',
        title: `Circle tool`,
        onActivate: () =>
          this.changeMode.call(this, 'draw_circle', {
            initialRadiusInKm: 1 / Math.pow(2, map.getZoom() - 11),
          }),
        onDeactivate: () => this.trash(),
      });

      const button2 = MapDrawExt.createControlButton('radius', {
        container,
        className: 'mapbox-gl-draw_circle',
        title: `Radius tool`,
        onActivate: () => this.changeMode.call(this, 'draw_radius'),
        onDeactivate: () => this.trash(),
      });

      return container;
    };
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    this.onRemove = function(map) {
      pOnRemove.call(this, map);
      this.env.event('clickNoTarget');
    };
  }

  static createControlButton(
    id: string,
    options: Record<string, any>
  ): HTMLButtonElement {
    const button = document.createElement('button');
    button.className = `mapbox-gl-draw_ctrl-draw-btn ${options.className}`;
    button.setAttribute('title', options.title);
    options.container.appendChild(button);

    button.addEventListener(
      'click',
      (e) => {
        e.preventDefault();
        e.stopPropagation();

        const clickedButton = e.target;
        /*      if (clickedButton === activeButton) {
        deactivateButtons();
        options.onDeactivate();
        return;
      }

      setActiveButton(id);
*/ options.onActivate();
      },
      true
    );

    return button;
  }
}
