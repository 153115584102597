import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as CircleConstants from '../geodesy/constants';
import { CircleGeoJson } from '../geodesy/circle-geojson';
import { Geodesy } from '../geodesy/geodesy';
import { DragPan } from './drag-pan';
import { IMapDraw, IMapDrawEnv } from './IMapDraw';
import { initialBearing } from '../geodesy/spherical';
import { GeometryGhost } from '../geodesy/geometryGhost';
import { extDisplay } from './extDisplay';

export class ExtDrawCircle extends IMapDraw {
  constructor(env: IMapDrawEnv,chain?: any) {
    super(env, chain);
  }
  onSetup = function(opts) {
    this.clearSelectedFeatures();
    const lib = MapboxDraw.lib;
    const constants = MapboxDraw.constants;
    const modes = MapboxDraw.modes;
    MapboxDraw.lib.doubleClickZoom.disable(this);
    DragPan.disable(this);
    this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.ADD });
    this.setActionableState(); // default actionable state is false for all actions
    return {};
  };

  onTouchStart = function(state, e) {
    const center = [e.lngLat.lng, e.lngLat.lat];
    const circle = this.newFeature(
      CircleGeoJson.createCircle(center, Number.EPSILON)
    );
    this.addFeature(circle);
    state.circle = circle;
    GeometryGhost.setCircle(state.circle,true);
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  onMouseDown = this.onTouchStart;

  onTouchMove = (state, e) => {
    if (state.circle) {
      const geojson = state.circle.toGeoJSON();
      const center = CircleGeoJson.getCircleCenter(geojson);
      const handle = [e.lngLat.lng, e.lngLat.lat];
      const radius = Geodesy.distance(center, handle);
      const handleBearing = initialBearing(center, handle);
      state.circle.properties[CircleConstants.properties.CIRCLE_RADIUS] = radius;
      state.circle[CircleConstants.properties.CIRCLE_HANDLE_BEARING] = handleBearing;
      state.circle.changed();
    }
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  onDrag = this.onTouchMove;

  onTouchEnd = function(state, e) {
    this.map.fire(MapboxDraw.constants.events.CREATE, {
      features: [state.circle.toGeoJSON()],
    });
    return this.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, {
      featureIds: [state.circle.id],
    });
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  onMouseUp = this.onTouchEnd;

  onMouseMove = function(state, e) {
    this.chain?.onMouseMove.call(this, state, e);
  };

  onKeyUp = function(state, e) {
    if (MapboxDraw.lib.CommonSelectors.isEscapeKey(e)) {
      if (state.circle) {
        this.deleteFeature([state.circle.id], { silent: true });
      }
      this.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT);
    } else if (MapboxDraw.lib.CommonSelectors.isEnterKey(e)) {
      this.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, {
        featureIds: [state.circle.id],
      });
    }
  };

  onStop = function() {
    this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.NONE });
    MapboxDraw.lib.doubleClickZoom.enable(this);
    DragPan.enable(this);
    this.activateUIButton();
  };

  toDisplayFeatures = function(state, geojson, display) {
    extDisplay(this,state, geojson, display);
  };
}
