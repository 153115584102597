/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { BehaviorSubject, Observer, Subscription, defer } from 'rxjs';
import merge from 'lodash.merge';
import clonedeep from 'lodash.clonedeep';

interface IWatchedEntityCenter {
  add(
    entity: any,
    properties: { [key: string]: any },
    position?: { container?: any; before?: any }
  ): void;
  remove(entity: any): void;
  removeAll(): void;
  update(entity: any, properties: { [key: string]: any }): void;
  stUpdate(
    rootentity: any,
    id: number,
    filter: (properties: { [key: string]: any }) => boolean,
    properties: { [key: string]: any }
  ): void;
  move(
    entity: any,
    position: { [key: string]: any; container?: any; before?: any }
  ): void;
  command(entity: any, properties: { [key: string]: any }): void;
  broadcast(entity: any, properties: { [key: string]: any }): void;
}

class WatchedEntity {
  entity: any;
  initialURN: string;
  properties: { [key: string]: any } = {};
  position: { container?: any; before?: any } = {};
  contained: WatchedEntity[];
  container: WatchedEntity;

  // Stacked Transient Properties is a stack of transient properties which aim at overriding/ extending
  // transitorily the base properties without need to directly alter them
  sctPropertiesMap: Map<number, SCTPropertiesOperator> = new Map<
    number,
    SCTPropertiesOperator
  >();
  // properties which are stored separatly from the watched entity.
  sctPropertiesMerged: { [key: string]: any } = {};

  setProperties(p: object) {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax
    if (p) {
      this.properties = {
        ...this.properties,
        ...p,
      };
    }
  }

  setSTProperties(id: number, sctOperator: SCTPropertiesOperator): string[] {
    const current = this.sctPropertiesMap.get(id);

    const result: Set<string> = new Set<string>();

    if (current !== sctOperator) {
      if (current) {
        Object.getOwnPropertyNames(current.properties).forEach((pr) => {
          delete this.sctPropertiesMerged[pr];
          result.add(pr);
        });
      }
      if (sctOperator) {
        Object.getOwnPropertyNames(sctOperator.properties).forEach((pr) => {
          delete this.sctPropertiesMerged[pr];
          result.add(pr);
        });
      }
      if (
        sctOperator?.properties &&
        Object.getOwnPropertyNames(sctOperator.properties).length !== 0 &&
        sctOperator?.filter
      ) {
        this.sctPropertiesMap.set(id, sctOperator);
      } else {
        this.sctPropertiesMap.delete(id);
      }
      // sort stProperties map with keys in ascending order
      // when merging the map objects, the later will overwrite the earlier
      this.sctPropertiesMap = new Map(
        [...this.sctPropertiesMap.entries()].sort((e1, e2) => e1[0] - e2[0])
      );
    }
    return Array.from(result.keys());
  }

  setPosition(p: { container?: any; before?: any }) {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax
    if (p) {
      this.position = {
        ...this.position,
        ...p,
      };
    }
  }
}

// Stacked Cascaded Transient Properties Operator
class SCTPropertiesOperator {
  watchedEntities: Map<WatchedEntity, any> = new Map<WatchedEntity, any>(); // keep entities which passed the filter and the result
  removedProperties: string[] = []; // properties removed from previous call not included in this call

  constructor(
    private watchcenter: EntitiesWatchCenter,
    private _id: number,
    private _rootentity: any,
    private _filter: (properties: { [key: string]: any }) => any,
    private _properties?: { [key: string]: any }
  ) {}
  get properties(): { [key: string]: any } {
    return this._properties;
  }
  get filter(): (properties: { [key: string]: any }) => any {
    return this._filter;
  }
  get id(): number {
    return this._id;
  }
  get rootEntity() {
    return this._rootentity;
  }
}

export class EntityURNStore {
  urn: string;
  properties: { [key: string]: any } = {};
  position: { container?: string; before?: string } = {};
}

class DeferCenter implements IWatchedEntityCenter {
  defered = [];

  constructor(private watchCenter: EntitiesWatchCenter) {}

  add(
    entity: any,
    properties: { [key: string]: any },
    position?: { container?: any; before?: any }
  ): void {
    this.defered.push({
      call: this.watchCenter.add.bind(this.watchCenter),
      p: [entity, properties, position],
    });
  }
  remove(entity: any): void {
    this.defered.push({
      call: this.watchCenter.remove,
      p: [entity],
    });
  }
  removeAll(): void {
    this.defered.push({
      call: this.watchCenter.removeAll,
      p: [],
    });
  }
  update(entity: any, properties: { [key: string]: any }): void {
    this.defered.push({
      call: this.watchCenter.update,
      p: [entity, properties],
    });
  }
  stUpdate(
    rootentity: any,
    id: number,
    filter: (properties: { [key: string]: any }) => boolean,
    properties: { [key: string]: any }
  ): void {
    this.defered.push({
      call: this.watchCenter.stUpdate,
      p: [rootentity, id, filter, properties],
    });
  }
  move(
    entity: any,
    position: { [key: string]: any; container?: any; before?: any }
  ): void {
    this.defered.push({
      call: this.watchCenter.move,
      p: [entity, position],
    });
  }
  command(entity: any, properties: { [key: string]: any }): void {
    this.defered.push({
      call: this.watchCenter.command,
      p: [entity, properties],
    });
  }
  broadcast(entity: any, properties: { [key: string]: any }): void {
    this.defered.push({
      call: this.watchCenter.broadcast,
      p: {
        entity,
        properties,
      },
    });
  }
  execute() {
    this.defered.forEach((d) => {
      try {
        d.call(...d.p);
      } catch (e) {
        const i = e;
      }
    });
    this.defered = [];
  }
}
export class EntitiesWatchCenter implements IWatchedEntityCenter {
  map: Map<any, WatchedEntity> = new Map<any, WatchedEntity>();

  urnMap: Map<string, WatchedEntity> = new Map<string, WatchedEntity>();

  // observer for changes
  change: BehaviorSubject<any> = new BehaviorSubject(null);

  deferMap: Map<string, DeferCenter> = new Map();

  stPropertiesFiltersMap: Map<number, SCTPropertiesOperator> = new Map();

  constructor(
    private propertyName: string,
    private persistedProperties?: string[],
    private persistCallBack?: (estore: EntityURNStore) => void
  ) {}

  observersCount(): number {
    return this.change.observers.length;
  }

  addObserver(observer: Observer<any>): Subscription {
    return this.change.subscribe(observer);
  }

  add(
    entity: any,
    properties: { [key: string]: any },
    position?: { container?: any; before?: any }
  ) {
    if (entity != null) {
      const watchedEntity = new WatchedEntity();
      watchedEntity.entity = entity;
      watchedEntity.setProperties(properties);
      watchedEntity.setPosition(position);
      this.map.set(entity, watchedEntity);
      this.updatePosition(watchedEntity);
      watchedEntity.initialURN = this.getEntityURN(watchedEntity);
      this.urnMap.set(watchedEntity.initialURN, watchedEntity);
    }
    this.change.next({ add: { entity, properties, position } });
    if (entity != null) {
      this.stEntityUpdate(entity, properties);
    }
  }

  remove(entity: any) {
    // remove references in container
    const watchedEntity = this.map.get(entity);
    if (watchedEntity != null) {
      watchedEntity.contained = watchedEntity.contained?.filter(
        (item) => item !== watchedEntity
      );
      this.map.delete(entity);
      this.urnMap.delete(watchedEntity.initialURN);
      this.change.next({ remove: { entity } });
      this.stPropertiesFiltersMap.forEach((sctOp, key) => {
        sctOp.watchedEntities.delete(entity);
      });
    }
  }

  removeAll() {
    const entities = this.map.keys();
    this.map.clear();
    this.urnMap.clear();
    this.change.next({ removeAll: { entities } });
    this.stPropertiesFiltersMap.forEach((sctOp, key) => {
      sctOp.watchedEntities.clear();
    });
  }

  update(entity: any, properties: { [key: string]: any }, transient?: boolean) {
    const watchedEntity = this.map.get(entity);
    watchedEntity?.setProperties(properties);
    if (!transient && this.persistCallBack && this.persistedProperties) {
      if (this.persistedProperties.some((p) => p in properties)) {
        new Promise(() => {
          this.persistCallBack(this.getEntityURNStore(entity));
        });
      }
    }
    this.change.next({ update: { entity, properties } });
    this.stEntityUpdate(entity, properties);
  }

  private stEntityUpdate(entity: any, properties?: { [key: string]: any }) {
    const watchedEntity = this.map.get(entity);
    const fproperties = this.getProperties(entity);
    const updatedProps = new Set<string>(
      Object.getOwnPropertyNames(properties)
    );
    this.stPropertiesFiltersMap.forEach((sctOp, key) => {
      const previousPass = sctOp.watchedEntities.get(watchedEntity);
      const pass = sctOp.filter(fproperties);
      if (pass) {
        sctOp.watchedEntities.set(watchedEntity, pass);
        watchedEntity.setSTProperties(sctOp.id, sctOp);
      } else {
        sctOp.watchedEntities.delete(watchedEntity);
        watchedEntity.setSTProperties(sctOp.id, null);
      }
      if (previousPass !== pass) {
        Object.getOwnPropertyNames(sctOp.properties).forEach((p) => {
          updatedProps.add(p);
        });
      }
    });
    updatedProps.forEach((p) => {
      delete watchedEntity.sctPropertiesMerged[p];
    });
    this.change.next({
      stUpdate: {
        entity: watchedEntity.entity,
        propertyNames: Array.from(updatedProps.keys()),
      },
    });
  }

  stUpdate(
    id: number,
    rootentity: any,
    filter: (properties: { [key: string]: any }) => any,
    properties?: { [key: string]: any }
  ): number {
    const psctOperator = this.stPropertiesFiltersMap.get(id);
    if (psctOperator && rootentity !== psctOperator.rootEntity) {
      this.stUpdate(id, psctOperator.rootEntity, psctOperator.filter); // erase previous
    }
    const sctOperator = new SCTPropertiesOperator(
      this,
      id,
      rootentity,
      filter,
      properties
    );
    this.traverseTree(
      rootentity,
      (entity) => {
        const fproperties = this.getProperties(entity);
        const pass = filter(fproperties);
        if (pass) {
          sctOperator.watchedEntities.set(entity, pass);
          psctOperator?.watchedEntities.delete(entity);
        }
        return true; // continue deep parse
      },
      null,
      true
    );
    this.stPropertiesFiltersMap.set(id, sctOperator);
    psctOperator?.watchedEntities.forEach((pass, lwatchedEntity) => {
      const propertyNames = lwatchedEntity.setSTProperties(
        sctOperator.id,
        null
      );
      this.change.next({
        stUpdate: { entity: lwatchedEntity.entity, propertyNames },
      });
    });
    sctOperator.watchedEntities.forEach((pass, lwatchedEntity) => {
      const propertyNames = lwatchedEntity.setSTProperties(
        sctOperator.id,
        sctOperator
      );
      this.change.next({
        stUpdate: { entity: lwatchedEntity.entity, propertyNames },
      });
    });
    return sctOperator.watchedEntities.size;
  }

  stSize(id: number): number {
    const sctOperator = this.stPropertiesFiltersMap.get(id);
    return sctOperator?.watchedEntities.size;
  }

  move(
    entity: any,
    position: { [key: string]: any; container?: any; before?: any },
    transient?: boolean
  ) {
    if (position?.container) {
      const watchedEntity = this.map.get(entity);
      watchedEntity?.setPosition(position);
      this.updatePosition(watchedEntity);
      if (!transient) {
        new Promise(() => {
          this.persistCallBack(this.getEntityURNStore(entity));
        });
      }
      this.change.next({ move: { entity, position } });
    }
  }

  command(entity: any, properties: { [key: string]: any }) {
    this.change.next({ command: { entity, properties } });
  }

  broadcast(entity: any, properties: { [key: string]: any }) {
    this.change.next({ broadcast: { entity, properties } });
  }

  getProperties(entity: any, p?: string[]): { [key: string]: any } {
    let watch = entity;
    if (!(watch instanceof WatchedEntity)) {
      watch = this.map.get(entity);
    }
    const props = {};
    if ((!p || p.length === 0) && watch?.properties) {
      p = Object.getOwnPropertyNames(watch?.properties);
    }
    p.forEach((i) => {
      props[i] = watch?.properties ? watch.properties[i] : undefined;
    });
    return props;
  }

  getMergedSTProperties(entity: any, p?: string[]): { [key: string]: any } {
    const watch = this.map.get(entity);
    const props = {};
    if (!watch) {
      return undefined;
    }
    if (!p) {
      p = Object.getOwnPropertyNames(watch?.properties);
      watch.sctPropertiesMap.forEach((value, key) => {
        p = [...p, ...Object.getOwnPropertyNames(value)];
      });
    }
    p.forEach((i) => {
      if (!watch?.sctPropertiesMerged[i]) {
        if (watch?.properties[i] !== undefined) {
          props[i] = clonedeep(watch.properties[i]);
        }
        watch.sctPropertiesMap.forEach((value, key) => {
          if (value.properties[i] !== undefined) {
            merge(props[i], value.properties[i]);
          }
        });
        watch.sctPropertiesMerged[i] = props[i];
      } else {
        props[i] = watch.sctPropertiesMerged[i];
      }
    });
    return props;
  }

  filterEntities(
    filter: (
      entity: any,
      properties: { [key: string]: any },
      position: { container?: any; before?: any }
    ) => boolean
  ): any[] {
    const result: any[] = [];
    for (const [k, v] of this.map) {
      if (filter(k, v.properties, v.position)) {
        result.push(k);
      }
    }
    return result;
  }

  traverseTree(
    entity: any,
    callBack: (entity: any, container: any) => boolean,
    container?: WatchedEntity,
    internal?: boolean
  ) {
    if (!entity && !container) {
      this.map.forEach((lentity) => {
        if (!lentity.container) {
          this.traverseTree(
            internal ? lentity : lentity.entity,
            callBack,
            container,
            internal
          );
        }
      });
      return;
    }
    let we: WatchedEntity = entity;
    if (!(we instanceof WatchedEntity)) {
      we = this.map.get(entity);
    }
    entity = we?.entity ?? entity;
    if (
      we &&
      callBack(internal ? we : entity, internal ? container : container?.entity)
    ) {
      we?.contained?.forEach((element) => {
        if (
          callBack(
            internal ? element : element.entity,
            internal ? we : we.entity
          )
        ) {
          this.traverseTree(element, callBack, we, internal);
        }
      });
    }
  }

  defer(deferId: string): DeferCenter {
    let d = this.deferMap.get(deferId);
    if (!d) {
      d = new DeferCenter(this);
      this.deferMap.set(deferId, d);
    }
    return d;
  }

  execDefered(deferId: string) {
    const d = this.deferMap.get(deferId);
    this.deferMap.delete(deferId);
    d.execute();
  }

  getEntityURN(e: WatchedEntity): string {
    if (!e) {
      return e as unknown as string;
    }
    let r = '';
    if (e.container) {
      r += this.getEntityURN(e.container) + ':';
    }
    return r + e.properties[this.propertyName];
  }
  getEntityInitialURN(e: WatchedEntity): string {
    return e?.initialURN;
  }
  getEntityByURN(urn: string, entity?: WatchedEntity) {
    return this.urnMap.get(urn);
    /*    if(!urn) {
      return null;
    }
    const idp = urn.indexOf(':');
    let id: string;
    let idr: string;
    if (idp !== -1) {
      id = urn.slice(0, idp);
      idr = urn.slice(idp);
    } else {
      id = urn;
      idr = null;
    }
    if(id) {

    }
  if (!entity) {
      this.map.forEach((e) => {
        const fe = this.getEntityByURN(urn, e);
        if (fe) {
          return fe;
        }
      });
    } else {
      const idp = urn.indexOf(':');
      let id: string;
      let idr: string;
      if (idp !== -1) {
        id = urn.slice(0, idp);
        idr = urn.slice(idp);
      } else {
        id = urn;
        idr = null;
      }
      if (!id || !entity.properties || entity.properties[this.propertyName] !== id) {
        return null;
      }
      if (idr === null) {
        return entity;
      } else {
        entity.contained?.forEach((e) => {
          const fe = this.getEntityByURN(idr, e);
          if (fe) {
            return fe;
          }
        });
      }
      return null;
    }
  */
  }
  getEntityURNStore(watchedEntity: WatchedEntity | any): EntityURNStore {
    let res: EntityURNStore;
    if (!(watchedEntity instanceof WatchedEntity)) {
      watchedEntity = this.map.get(watchedEntity);
    }
    if (watchedEntity) {
      res = new EntityURNStore();
      res.urn = this.getEntityInitialURN(watchedEntity);
      res.properties = {};
      this.persistedProperties?.forEach((p) => {
        if (watchedEntity.properties[p] !== undefined) {
          res.properties[p] = watchedEntity.properties[p];
        }
      });
      res.position.container = this.getEntityInitialURN(
        this.map.get(watchedEntity.position.container)
      );
      res.position.before = this.getEntityInitialURN(
        this.map.get(watchedEntity.position.before)
      );
    }
    return res;
  }
  setEntityURNStore(urnStore: EntityURNStore) {
    const we = this.getEntityByURN(urnStore.urn);
    if (!we) {
      return null;
    }
    if (urnStore.properties) {
      this.update(we.entity, urnStore.properties, true);
    }
    const container = this.getEntityByURN(urnStore.position.container)?.entity;
    const before = this.getEntityByURN(urnStore.position.before)?.entity;
    if (container) {
      this.move(we.entity, { container, before }, true /* transient */);
    }
  }
  traverseEntitiesURNStore(callBack: (estore: EntityURNStore) => void) {
    this.map.forEach((we: WatchedEntity, key: any) => {
      callBack(this.getEntityURNStore(we));
    });
  }
  private updatePosition(watchedEntity: WatchedEntity) {
    // remove from previous container
    const previousContainer = watchedEntity.container;
    if (previousContainer) {
      previousContainer.contained = previousContainer.contained?.filter(
        (we) => we !== watchedEntity
      );
    }

    // update container
    watchedEntity.container = this.map.get(watchedEntity.position.container);
    if (watchedEntity.container && watchedEntity.position.before) {
      let beforeWatchedEntity = this.map.get(watchedEntity.position.before);
      const newContained = [];
      watchedEntity.container.contained?.forEach((item) => {
        if (beforeWatchedEntity === item) {
          newContained.push(beforeWatchedEntity);
          beforeWatchedEntity = null;
        }
        newContained.push(item);
      });
      watchedEntity.container.contained = newContained;
    } else if (watchedEntity.container) {
      if (!watchedEntity.container.contained) {
        watchedEntity.container.contained = [];
      }
      watchedEntity.container.contained.push(watchedEntity);
    }
  }
}
