import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletmapComponent } from './leafletmap/leafletmap.component';



@NgModule({
  declarations: [LeafletmapComponent],
  imports: [
    CommonModule
  ],
  exports: [LeafletmapComponent]
})
export class LeafletmapModule { }
