/* eslint-disable no-underscore-dangle */
import { GeometryGhost } from '../geodesy/geometryGhost';
import { extDisplay } from './extDisplay';
import { IMapDraw, IMapDrawEnv } from './IMapDraw';

export class ExtDrawPolygon extends IMapDraw {
  constructor(env: IMapDrawEnv, chain?: any) {
    super(env, chain);
  }
  onTouchStart = function(state, e) {
    state.geodesic = this.env.option.geodesic;
    if(this.chain?.onTouchStart) {
      this.chain?.onTouchStart.call(this,state,e);
    }
  };
  onMouseDown = function(state, e) {
    state.geodesic = this.env.option.geodesic;
    if(this.chain?.onMouseDown) {
      this.chain?.onMouseDown.call(this,state,e);
    }
  };
  onClick = function(state, e) {
    state.geodesic = this.env.option.geodesic;
    if(this.chain?.onClick) {
      this.chain?.onClick.call(this,state,e);
    }
  };
  onStop = function(state) {
    GeometryGhost.setGeodesic(state.polygon,state.geodesic);
    if (this.chain?.onStop) {
      this.chain?.onStop.call(this, state);
    }
  };
  toDisplayFeatures = function(state, geojson, display) {
    extDisplay(this,state, geojson, display);
  };
}
