/* eslint-disable @typescript-eslint/member-ordering */
import { BehaviorSubject, Observer, Subscription } from 'rxjs';

export abstract class ADataStoreSubscriber {
  abstract add(entity: any, properties?: { [key: string]: any });
  abstract remove(entity: any);
  abstract update(entity: any, properties?: { [key: string]: any });

  constructor(watchCenter: DataStore) {
    watchCenter.addObserver(this.entitiesObserver);
  }

  private entitiesObserver: Observer<any> = {
    next: (x) => {
      if (x?.add) {
        this.add(x.add.entity);
      }
      if (x?.remove) {
        this.remove(x.remove.entity);
      }
      if (x?.update) {
        this.update(x.update.entity, x.update.properties);
      }
    },
    error: (err) => {},
    complete: () => {},
  };
}

export abstract class DataStore {
  // observer for changes
  change: BehaviorSubject<any> = new BehaviorSubject(null);

  observersCount(): number {
    return this.change.observers.length;
  }

  addObserver(observer: Observer<any>): Subscription {
    return this.change.subscribe(observer);
  }

  add(entity: any) {
    this.change.next({ add: { entity } });
  }

  update(entity: any, properties: { [key: string]: any }) {
    this.change.next({ update: { entity, properties } });
  }

  remove(entity: any): void {
    this.change.next({ remove: { entity } });
  }

  abstract getAll(): Promise<any[]>;

}
