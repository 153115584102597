interface From {
  reference: string;
}

interface IScheme {
  html: string;
  js: string;
}

export class ObjectEditor {
  static readonly schemes = {
    from: {
      type: 'from',
      html: 'text',
      js: 'string',
      reference: '',
    },
    text: {
      type: 'text',
      html: 'text',
      js: 'string',
    },
    color: {
      type: 'color',
      html: 'color',
      js: 'string',
    },
    // eslint-disable-next-line quote-props
    'number': {
      type: 'number',
      html: 'number',
      js: 'number',
    },
    date: {
      type: 'date',
      html: 'date',
      js: 'string',
    },
    time: {
      type: 'time',
      html: 'time',
      js: 'string',
    },
    datetime: {
      type: 'datetime',
      html: 'datetime-local',
      js: 'string',
    },
    file: {
      type: 'file',
      html: 'file',
      js: 'string',
    },
    email: {
      type: 'email',
      html: 'email',
      js: 'string',
    },
    image: {
      type: 'image',
      html: 'image',
      js: 'string',
    },
  };
  public static getSchemes(): string[] {
    return Object.getOwnPropertyNames(ObjectEditor.schemes);
  }
  public static getScheme(typeName: string) {
    return ObjectEditor.schemes[typeName];
  }
  static isScheme(type: string): boolean {
    return ObjectEditor.schemes.hasOwnProperty(type);
  }
  public static convert(value: string, type: IScheme): any {
    if(!value) {
      return value;
    }
    switch (type.js) {
      case 'number':
        return Number(value);
        break;
      default:
        return value;
        break;
    }
  }
}
