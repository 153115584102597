import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StPropertiesComponent } from './st-properties.component';
import { StPropertiesStackComponent } from './st-properties-stack.component';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { ObjectEditorModule } from '../object-editor/object-editor.module';
import { FormsModule } from '@angular/forms';
import { BadgeModule } from 'primeng/badge';

@NgModule({
  declarations: [
    StPropertiesComponent,
    StPropertiesStackComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TabViewModule,
    ButtonModule,
    BadgeModule,
    ObjectEditorModule
  ],
  exports: [
    StPropertiesComponent,
    StPropertiesStackComponent
  ]
})
export class StPropertiesModule { }
