import * as Constants from './constants';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {
  CircleGeoJson
} from './circle-geojson';
import { GeodesicLine } from './create-geodesic-line';
import { GeodesicCircle } from './create-geodesic-circle';
import { midpoint, destinationPoint } from 'geodesy-fn/src/spherical';

const STEPS = 32;
const HANDLE_BEARING = 45;

export class GeodesicGeoJson {
  static isCircleFeature(feature) {
    return CircleGeoJson.isCircleByTypeAndProperties(feature.type, feature.properties);
  }

  // returns path with the last coord id subtracted by 1
  static getMidpointStartCoordPath(path) {
    return path
      .split('.')
      .map((x, i, array) =>
        i === array.length - 1 ? (parseInt(x, 10) - 1).toString() : x
      )
      .join('.');
  }

  // returns path with the last coord id of a polygon overridden to 0
  // see https://github.com/mapbox/mapbox-gl-draw/pull/998
  static getMidpointEndCoordPath(feature, path) {
    if (
      feature.type === 'Polygon' ||
      feature.type === 'MultiPolygon'
    ) {
      try {
        feature.getCoordinate(path);
        return path;
      } catch (e) {
        return path
          .split('.')
          .map((x, i, array) => (i === array.length - 1 ? '0' : x))
          .join('.');
      }
    } else {
      return path;
    }
  }

  static createGeodesicGeojson(geojson, options) {
    options = { steps: STEPS, ...options };

    const properties = geojson.properties;
    const type = geojson.geometry.type;
    const coordinates = geojson.geometry.coordinates;

    const featureId = properties.parent || properties.id;
    const feature = options.ctx.store.get(featureId);

    const isSelectedPath = (path) => {
      if (!options.selectedPaths) {
        return false;
      }
      return options.selectedPaths.indexOf(path) !== -1;
    };

    const processMidpoint = () => {
      const coordPath = properties.coord_path;

      const startCoordPath =
        GeodesicGeoJson.getMidpointStartCoordPath(coordPath);
      const endCoordPath = GeodesicGeoJson.getMidpointEndCoordPath(
        feature,
        coordPath
      );

      const startCoord = feature.getCoordinate(startCoordPath);
      const endCoord = feature.getCoordinate(endCoordPath);
      const midCoord = midpoint(startCoord, endCoord);

      const geodesicGeojson = {
        ...geojson,
        properties: {
          ...properties,
          lng: midCoord[0],
          lat: midCoord[1],
        },
        geometry: {
          ...geojson.geometry,
          coordinates: midCoord,
        },
      };
      return [geodesicGeojson];
    };

    const processLine = () => {
      const geodesicCoordinates = GeodesicLine.createGeodesicLine(
        coordinates,
        options.steps
      );
      const geodesicGeojson = {
        ...geojson,
        geometry: {
          ...geojson.geometry,
          coordinates: geodesicCoordinates,
        },
      };
      return [geodesicGeojson];
    };

    const processPolygon = () => {
      const geodesicCoordinates = coordinates.map((subCoordinates) =>
        GeodesicLine.createGeodesicLine(subCoordinates)
      );
      const geodesicGeojson = {
        ...geojson,
        geometry: {
          ...geojson.geometry,
          coordinates: geodesicCoordinates,
        },
      };
      return [geodesicGeojson];
    };

    const processCircle = () => {
      const featureGeojson = feature.toGeoJSON();
      const center = CircleGeoJson.getCircleCenter(featureGeojson);
      const radius = CircleGeoJson.getCircleRadius(featureGeojson);
      const handleBearing =
        feature[Constants.properties.CIRCLE_HANDLE_BEARING] || HANDLE_BEARING;
      const geodesicCoordinates = GeodesicCircle.createGeodesicCircle(
        center,
        radius,
        handleBearing,
        options.steps * 4
      );
      const geodesicGeojson = {
        ...geojson,
        geometry: {
          ...geojson.geometry,
          coordinates: [geodesicCoordinates],
        },
      };

      // circle handles
      if (properties.active === MapboxDraw.constants.activeStates.ACTIVE) {
        const handle = destinationPoint(center, radius*1000, handleBearing);
        const points = [center, handle];
        const vertices = points.map((point, i) =>
        MapboxDraw.lib.createVertex(properties.id, point, `0.${i}`, isSelectedPath(`0.${i}`))
        );

        return [geodesicGeojson, ...vertices];
      } else {
        return [geodesicGeojson];
      }
    };

    const processMultiGeometry = () => {
      const subType = type.replace('Multi', '');
      const geodesicFeatures = coordinates
        .map((subCoordinates) => {
          const subFeature = {
            type: 'Feature',
            properties,
            geometry: {
              type: subType,
              coordinates: subCoordinates,
            },
          };
          return GeodesicGeoJson.createGeodesicGeojson(subFeature, options);
        })
        .flat();
      const geodesicCoordinates = geodesicFeatures.map(
        (subFeature) => subFeature.geometry.coordinates
      );
      const geodesicGeojson = {
        ...geojson,
        geometry: {
          ...geojson.geometry,
          coordinates: geodesicCoordinates,
        },
      };
      return [geodesicGeojson];
    };

    if (type === 'Point') {
      if (GeodesicGeoJson.isCircleFeature(feature)) {
        return []; // hide circle points, they are displayed in processCircle instead
      } else if (properties.meta === 'midpoint') {
        return processMidpoint(); // calculate geodesic midpoint
      } else {
        return [geojson]; // pass point as is
      }
    } else if (type === 'LineString') {
      return processLine(); // calculate geodesic line
    } else if (type === 'Polygon') {
      if (GeodesicGeoJson.isCircleFeature(feature)) {
        return processCircle(); // calculate geodesic circle
      } else {
        return processPolygon(); // calculate geodesic polygon
      }
    } /* istanbul ignore else */ else if (
      type.indexOf('Multi') === 0
    ) {
      return processMultiGeometry();
    }
  }
}
