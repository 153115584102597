import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// https://www.cloudhadoop.com/angular-reload-component/

const routes: Routes = [
  {
    path: '',
    redirectTo: 'map_page',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'map_page',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'map_page',
    loadChildren: () => import('./map-page/map-page.module').then( m => m.MapPageModule)
  },
  {
    path: 'map_page/:id',
    loadChildren: () => import('./map-page/map-page.module').then( m => m.MapPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
