/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { EntitiesWatchCenter } from '../watch-center/entities-watch-center';
import { DataStore } from '../data-store/data-store';
import { ObjectEditorContext } from '../object-editor/st-editor-int';

interface IView {
  view: string;
  stalled: boolean;
  fsps: number[];
  stpActiveIndex: number;
  fspStateMap: Map<number, any>;
  fspDataMap: Map<number, any>;
}

@Component({
  selector: 'app-st-properties-stack',
  templateUrl: './st-properties-stack.component.html',
  styleUrls: ['./st-properties-stack.component.scss'],
})
export class StPropertiesStackComponent implements OnInit, OnChanges {
  @Input() stpSize = 0;
  @Input() properties: { [key: string]: ObjectEditorContext };
  @Input() watchCenter: EntitiesWatchCenter;
  @Input() datastore: DataStore;

  private _viewActiveIndex = 0;
  set viewActiveIndex(i) {
    if (this.viewActive) {
      this.viewActive.stalled = true;
    }
    this._viewActiveIndex = i;
    this.viewActive = Array.from(this.views.values())[i];
    this.viewActive.stalled = false;
  }
  get viewActiveIndex(): number {
    return this._viewActiveIndex;
  }

  viewActive: IView;

  views = new Map<string, IView>();

  clipboard: string;

  constructor() {
    this.views.set('', {
      view: '',
      stalled: false,
      fsps: [],
      stpActiveIndex: 0,
      fspStateMap: new Map<number, any>(),
      fspDataMap: new Map<number, any>(),
    });
    this.views.set('toto', {
      view: 'toto',
      stalled: true,
      fsps: [],
      stpActiveIndex: 0,
      fspStateMap: new Map<number, any>(),
      fspDataMap: new Map<number, any>(),
    });
  }

  store(view: string, fsp: number, event) {
    if (event.eventType) {
      this.datastore?.update(undefined, {
        view,
        level: fsp,
        active: event.active,
        value: JSON.stringify({
          value: event.value,
          scheme: event.scheme,
          filter: event.filter,
          active: event.active,
        }),
      });
    } else {
      this.datastore?.update(undefined, {
        view,
        level: fsp,
        active: event.active,
        value: event.value,
      });
    }
  }

  stpEvent(viewId: string, fsp: number, event: any) {
    const view = this.views.get(viewId);
    if (event.eventType === 'stpData') {
      view.fspDataMap.set(fsp, event);
      this.store(viewId, fsp, event);
    }
    if (event.eventType === 'stateData') {
      view.fspStateMap.set(fsp, event);
    }
    if (event.eventType === 'copy') {
      this.clipboard = event.data;
    }
    if (event.eventType === 'shift-up') {
      if (event.id + 1 < this.stpSize) {
        const swap1 = view.fspDataMap.get(event.id);
        const swap2 = view.fspDataMap.get(event.id + 1);
        view.fspDataMap.set(event.id, swap2);
        view.fspDataMap.set(event.id + 1, swap1);
        this.store(viewId, event.id, swap2);
        this.store(viewId, event.id + 1, swap1);
        view.stpActiveIndex = event.id + 1;
      }
    }
    if (event.eventType === 'shift-down') {
      if (event.id > 0) {
        const swap1 = view.fspDataMap.get(event.id);
        const swap2 = view.fspDataMap.get(event.id - 1);
        view.fspDataMap.set(event.id, swap2);
        view.fspDataMap.set(event.id - 1, swap1);
        this.store(viewId, event.id, swap2);
        this.store(viewId, event.id - 1, swap1);
        view.stpActiveIndex = event.id - 1;
      }
    }
  }

  ngOnChanges(changes) {
    if (changes.datastore?.currentValue) {
      this.loadFSPs();
    }
  }

  ngOnInit() {
    this.views.forEach((view) => {
      view.fsps = [...Array(Number(this.stpSize)).keys()];
      view.fsps.forEach((fsp) => {
        view.fspStateMap.set(fsp, { level: fsp, active: false });
      });
    });
    this.loadFSPs();
  }

  loadFSPs() {
    this.datastore?.getAll().then((i) => {
      i?.forEach((fsp) => {
        let view = this.views.get(fsp.view ?? '');
        if (!view) {
          this.views.set(
            fsp.view,
            (view = {
              view: fsp.view,
              stalled: (fsp.view as string).length !== 0,
              fsps: [...Array(Number(this.stpSize)).keys()],
              stpActiveIndex: 0,
              fspStateMap: new Map<number, any>(),
              fspDataMap: new Map<number, any>(),
            })
          );
        }
        if (!view.view) {
          view.view = '';
        }
        view.fspDataMap.set(fsp.level, fsp);
      });
    });
  }
}
