/* eslint-disable no-underscore-dangle */
import { GeometryGhost } from '../geodesy/geometryGhost';
import { GeodesicGeoJson } from '../geodesy/create-geodesic-geojson';
import { extDisplay } from './extDisplay';
import { IMapDraw, IMapDrawEnv } from './IMapDraw';

export class ExtStatic extends IMapDraw {
  // copied from https://github.com/mapbox/mapbox-gl-draw-static-mode
  // adapted to typescript

  constructor(env: IMapDrawEnv, chain?: any) {
    super(env, chain);
  }

  onSetup = function() {
    this.setActionableState(); // default actionable state is false for all actions
    return {};
  };

  toDisplayFeatures = function(state, geojson, display) {
    extDisplay(this,state, geojson, display);
  };
}
