<div #objectcontainer>
  <p *ngIf="hasListSel()">
    <select #listsel [(ngModel)]="sel">
      <option>choose a predefined property</option>
      <option *ngFor="let o of getListSel()" value="{{o}}">{{o}}</option>
    </select>
  </p>
  <p style="display: flex; gap: 10px">
    <input type="text" pattern="[a-zA-Z]{1}[a-zA-Z0-9_]*" [(ngModel)]="newobject.property" />
    <select #typesel [(ngModel)]="newobject.type">
      <option>choose an option</option>
      <option *ngFor="let o of typeoptions" value="{{o}}">{{o}}</option>
    </select>
    <i class="gg-add-r gg-icon-wet" (click)="new()"></i>
  </p>
  <div *ngFor="let p of properties">
    <p *ngIf="isediting(p); else noedit" style="display: flex; gap: 10px">
      <i class="gg-pen gg-icon-wet" style="color:green" (click)="edittoggle(p)"></i>
      <i class="gg-trash gg-icon-wet" (click)="delete(p)"></i>
      <label> {{ p }} </label>
      <input type="{{context.scheme[p].html}}" [(ngModel)]="context.value[p]" />
    </p>
    <ng-template #noedit>
      <p style="display: flex; gap: 10px">
        <i class="gg-pen gg-icon-wet" (click)="edittoggle(p)"></i>
        <label> {{ p }} </label>
        <input type="{{context.scheme[p].html}}" [(ngModel)]="context.value[p]" disabled />
      </p>
    </ng-template>
  </div>
</div>
