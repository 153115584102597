import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WatchedEntitiesTreeComponent } from './watched-entities-tree.component';
import { TreeModule } from 'primeng/tree';
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ButtonModule } from 'primeng/button';
import {ScrollTopModule} from 'primeng/scrolltop';
import { Scroll } from '@angular/router';
import {CheckboxModule} from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import { DividerModule } from 'primeng/divider';
import { ObjectEditorModule } from '../object-editor/object-editor.module';
import { StPropertiesModule } from '../st-properties/st-properties.module';

@NgModule({
  declarations: [WatchedEntitiesTreeComponent],
  imports: [
    CommonModule,
    TreeModule,
    AccordionModule,
    TabViewModule,
    ButtonModule,
    ContextMenuModule,
    ScrollTopModule,
    CheckboxModule,
    FormsModule,
    InputTextModule,
    DividerModule,
    ObjectEditorModule,
    StPropertiesModule
  ],
  exports: [
    WatchedEntitiesTreeComponent
  ]
})
export class EntitiesTreeModule { }
