import * as BABYLON from '@babylonjs/core';
import * as BABYLONGUI from '@babylonjs/gui';
import { BabyContext } from './babycontext';
import { BabymapService } from './babymap.service';
import { WebMercator } from './webmercator';

export class BabyUI {
  camera: BABYLON.ArcRotateCamera;
  sphere: BABYLON.AbstractMesh;

  private fullScreenUI: BABYLONGUI.AdvancedDynamicTexture;
  private nameTextBlock;
  private service: BabyContext;

  constructor(context: BabyContext) {
    this.service = context;
  }

  setScene(centerMesh: BABYLON.AbstractMesh): void {
    // GUI
    this.fullScreenUI =
      BABYLONGUI.AdvancedDynamicTexture.CreateFullscreenUI('FS-UI');
    this.fullScreenUI.useInvalidateRectOptimization = false;

    this.nameTextBlock = new BABYLONGUI.TextBlock();
    this.nameTextBlock.text = 'Hello world';
    this.nameTextBlock.color = 'red';
    this.nameTextBlock.fontSize = 24;
    this.fullScreenUI.addControl(this.nameTextBlock);
    this.service.addPointerMoveHandler((evt: BABYLON.IPointerEvent,
      pickInfo?: BABYLON.PickingInfo,
      type?: BABYLON.PointerEventTypes) => {
      if (pickInfo && pickInfo.hit) {
        this.nameTextBlock.text = pickInfo.pickedMesh.name;
        const lonLat = WebMercator.getXYZLonLat({
          x: pickInfo.pickedPoint.x,
          y: pickInfo.pickedPoint.z,
          z: pickInfo.pickedPoint.y,
        });
      } else {
        this.nameTextBlock.text = '';
      }
    }
    );
    this.service.addPointerUpHandler ((
      evt: BABYLON.IPointerEvent,
      pickInfo: BABYLON.PickingInfo,
      type: BABYLON.PointerEventTypes
    ) => {
      if (pickInfo && pickInfo.hit) {
        this.nameTextBlock.text = pickInfo.pickedMesh.name;
        const lonLat = WebMercator.getXYZLonLat({
          x: pickInfo.pickedPoint.x,
          y: pickInfo.pickedPoint.z,
          z: pickInfo.pickedPoint.y,
        });
      } else {
        this.nameTextBlock.text = '';
      }
    });

    window.addEventListener('click', (evt) => {
      const pickResult = this.pickingInfo();
      if (pickResult.hit) {
        const lonLat = WebMercator.getXYZLonLat({
          x: pickResult.pickedPoint.x,
          y: pickResult.pickedPoint.z,
          z: pickResult.pickedPoint.y,
        });
        /*        this.sun.setAbsolutePosition(new BABYLON.Vector3(pickResult.pickedPoint.x,
              pickResult.pickedPoint.y,pickResult.pickedPoint.z));
    */ console.log(
          pickResult.pickedMesh.name,
          ' x =' + pickResult.pickedPoint.x,
          ' y =' + pickResult.pickedPoint.y,
          ' z =' + pickResult.pickedPoint.z,
          ' r = ' +
            Math.sqrt(
              pickResult.pickedPoint.x * pickResult.pickedPoint.x +
                pickResult.pickedPoint.y * pickResult.pickedPoint.y +
                pickResult.pickedPoint.z * pickResult.pickedPoint.z
            ),
          ' lon ' + (lonLat.lon / Math.PI) * 180,
          ' lat ' + (lonLat.lat / Math.PI) * 180
        );
      }
    });
    const scaleXAnimation = new BABYLON.Animation(
      'myAnimation',
      'scaleX',
      30,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT,
      BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
    );
    const scaleYAnimation = new BABYLON.Animation(
      'myAnimation',
      'scaleY',
      30,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT,
      BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const keys = [];

    keys.push({
      frame: 0,
      value: 0,
    });
    keys.push({
      frame: 10,
      value: 1,
    });

    scaleXAnimation.setKeys(keys);
    scaleYAnimation.setKeys(keys);
    this.nameTextBlock.animations = [];
    this.nameTextBlock.animations.push(scaleXAnimation);
    this.nameTextBlock.animations.push(scaleYAnimation);

    const myscaleIn = (control, amt, speed) => {
      const ease = new BABYLON.CubicEase();
      ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
      BABYLON.Animation.CreateAndStartAnimation(
        'at2',
        control,
        'scaleX',
        speed,
        120,
        0,
        amt,
        0,
        ease
      );
      BABYLON.Animation.CreateAndStartAnimation(
        'at3',
        control,
        'scaleY',
        speed,
        120,
        0,
        amt,
        0,
        ease
      );
    };
  }
  pickingInfo(): BABYLON.PickingInfo {
    //https://gamedevacademy.org/grabbing-3d-objects-with-the-mouse-babylonjs-series-part-11/
    //When click event is raised
    // We try to pick an object
    const ray = this.service.scene.createPickingRay(
      this.service.scene.pointerX,
      this.service.scene.pointerY,
      BABYLON.Matrix.Identity(),
      this.camera
    );
    const pickedMeshes = this.service.scene.multiPick(
      this.service.scene.pointerX,
      this.service.scene.pointerY,
      null,
      this.camera
    );
    if (pickedMeshes.length > 1) {
      const toto = 3;
    }
    return this.service.scene.pickWithRay(ray, null, false);
  }

  text1(mesh) {
    const rect1 = new BABYLONGUI.Rectangle();
    this.fullScreenUI.addControl(rect1);
    rect1.width = '150px';
    rect1.height = '30px';
    rect1.thickness = 2;
    rect1.linkOffsetY = '-100px';
    rect1.transformCenterY = 1;
    rect1.background = 'white';
    rect1.alpha = 0.5;
    rect1.scaleX = 0;
    rect1.scaleY = 0;
    rect1.cornerRadius = 5;
    if(mesh) {
      rect1.linkWithMesh(mesh);
    }

    const text1 = new BABYLONGUI.TextBlock();
    text1.text = 'I am a object';
    text1.color = 'red';
    text1.fontSize = 18;
    text1.textWrapping = true;
    text1.textVerticalAlignment = BABYLONGUI.Control.VERTICAL_ALIGNMENT_TOP;
    //text1.background = '#006994';
    rect1.addControl(text1);
    text1.alpha = 1 / text1.parent.alpha;

  }
}
