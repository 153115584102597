// https://en.wikipedia.org/wiki/Web_Mercator_projection
// x = ((lon + PI)/2/PI * 2^zoom * 256) in pixel
// y = (PI - ln[tan(PI/4 + lat/2)] * 256)

import { double } from '@babylonjs/core';

class XY {
  x: number;
  y: number;
}
export class WebMercator {
  static getXY(lon, lat, zoom): { x: number; y: number } {
    const x1 = ((lon + Math.PI) / 2 / Math.PI) * 2 ** zoom * 256;
    const y1 =
      ((Math.PI - Math.log(Math.tan(Math.PI / 4 + lat / 2))) / 2 / Math.PI) *
      2 ** zoom *
      256;
    return { x: x1, y: y1 };
  }

  /*
  * get the covered sphere angle (from the sphere centric perspective) as viewed by a camera at a distance d with semi field of view hfov
  * r: sphere radius
  */
  static getSphereViewAngle(distance: number, hfov: number, r: number) {
    // ax+b+c = 0 line between camera fov and sphere
    const a: double = 1; // normalised
    const b: double = -1 / Math.sin(hfov); // - camera field of view y slope
    const c: double = distance;
    const ab2: double = a * a + b * b;
    const d0: double = Math.abs(c) / Math.sqrt(ab2);
    const x0: double = (-a * c) / ab2;
    const y0: double = (-b * c) / ab2;
    const discr2 = r * r - (c * c) / ab2;
    let theta: double = Math.PI / 2; // field of vue angle on sphere
    if (discr2 >= 0) {
      const m: double = Math.sqrt(discr2 / ab2);
      const ax: double = x0 + b * m;
      const bx: double = x0 - b * m;
      const x: double = -Math.min(ax, bx);
      theta = Math.acos(x/r);
    } else {
      // take the sphare tangent line originating from the camera
      const ftan: double = Math.atan(1 / Math.sqrt((c * c) / a / a));
      theta = Math.PI / 2 - ftan;
    }
    return theta;
  }

  static getLonLat(x, y, zoom): { lon: number; lat: number } {
    const lon1 = (2 * Math.PI * x) / 256 / 2 ** zoom - Math.PI;
    const lat1 =
      2 * Math.atan(Math.exp(Math.PI - (2 * Math.PI * y) / 256 / 2 ** zoom));
    return { lon: lon1, lat: lat1 };
  }

  static getXYTile(lon, lat, zoom): { x: number; y: number } {
    const x1 = Math.floor(((lon + Math.PI) / 2 / Math.PI) * 2 ** zoom);
    const y1 = Math.floor(
      ((Math.PI - Math.log(Math.tan(Math.PI / 4 + lat / 2))) / 2 / Math.PI) *
        2 ** zoom
    );
    return { x: x1, y: y1 };
  }

  static getZoomLevel(lat1, deltalat, tileCount) {
    const nearPole = 0.001; // radians
    let lat2 = 0;
    if (lat1 < 0) {
      lat2 = Math.max(lat1 - Math.abs(deltalat), -Math.PI / 2 + nearPole);
    } else {
      lat2 = Math.min(lat1 + Math.abs(deltalat), Math.PI / 2 - nearPole);
    }
    const xy1 = WebMercator.getXY(0, lat1, 0);
    const xy2 = WebMercator.getXY(0, lat2, 0);
    const zoom = Math.round(
      Math.log2((tileCount / Math.abs(xy2.y - xy1.y)) * 256)
    );
    return zoom;
  }

  static getXYZLonLat({ x: x, y: y, z: z }): { lon: number; lat: number } {
    // https://en.wikipedia.org/wiki/Spherical_coordinate_system
    const r: number = Math.sqrt(x * x + y * y + z * z);
    let lat: number = Math.acos(z / r);
    let lon: number;
    if (x > 0) {
      lon = Math.atan(y / x);
    } else if (x < 0 && y >= 0) {
      lon = Math.atan(y / x) + Math.PI;
    } else if (x < 0 && y < 0) {
      lon = Math.atan(y / x) - Math.PI;
    } else if (x === 0 && y > 0) {
      lon = Math.PI / 2;
    } else if (x === 0 && y < 0) {
      lon = -Math.PI / 2;
    }

    if (lon < Math.PI) {
      lon = lon + 2 * Math.PI;
    }
    if (lon > Math.PI) {
      lon = lon - 2 * Math.PI;
    }
    lat = Math.PI / 2 - lat;

    return { lon, lat };
  }

  static getLonLatXYZ({ lon: lon, lat: lat, r: r }): { x: number; y: number; z: number } {
    // https://en.wikipedia.org/wiki/Spherical_coordinate_system
    let phi = lon + Math.PI;
    if (phi >= 2 * Math.PI) {
      phi = phi - 2 * Math.PI;
    }
    const theta = Math.PI/2 - lat;
    return ({
      x: - r * Math.cos(phi) * Math.sin(theta),
      z: - r * Math.sin(phi) * Math.sin(theta),
      y: r * Math.cos(theta)
    });
  }
  static test() {
    const t1 = WebMercator.getXY(0, 0, 0);
    const t2 = WebMercator.getXY(
      (-175 / 180) * Math.PI,
      (-80 / 180) * Math.PI,
      0
    );
    const t3 = WebMercator.getXY(
      (+175 / 180) * Math.PI,
      (+85 / 180) * Math.PI,
      0
    );
  }
}
