import { distance as geodesyDistance, destinationPoint as geodesyDestinationPoint } from 'geodesy-fn/src/spherical';

export class Geodesy {
// radius used by mapbox-gl, see https://github.com/mapbox/mapbox-gl-js/blob/main/src/geo/lng_lat.js#L11
// eslint-disable-next-line @typescript-eslint/naming-convention
static DEFAULT_RADIUS = 6371.0088;

static distance(start, destination) {
  return geodesyDistance(start, destination, Geodesy.DEFAULT_RADIUS);
}

static destinationPoint(start, distance, bearing) {
  return geodesyDestinationPoint(start, distance, bearing, Geodesy.DEFAULT_RADIUS);
}

}
