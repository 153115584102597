/* eslint-disable @typescript-eslint/naming-convention */
import { FilterSpecification, StyleSpecification } from 'maplibre-gl';
import {
  IdbMapConfiguration,
  IdbMapLayer,
  IdbMapSource,
  RecordState,
} from './indexDBWrap';
import { ConfigurationStore, MapUtilStore } from './maputil-store';
import { mapUtilStyleLineSpec1 } from './maputil-stylespecs';
import merge from 'lodash.merge';

export const mockConfig = async (): Promise<ConfigurationStore> => {
  // https://www.submarinecablemap.com/api/v3/landing-point/landing-point-geo.json
  // https://www.submarinecablemap.com/api/v3/cable/cable-geo.json
  // https://www.submarinecablemap.com/api/v3/cable/all.json
  // https://github.com/delusan/www.submarinecablemap.com
  const submarineCablesSourceId = 'Submarine Cables';
  const test = 'conf0/super test';
  const elevstyle = await MapUtilStore.requestUrl(
    '/assets/maplibre/maplibre-style-elevation.json',
    'json'
  );
  const conf0: IdbMapConfiguration = {
    id: 'conf0',
    state: RecordState.active,
    description: 'builtin configuration',
    style: await MapUtilStore.requestUrl(
      '/assets/maplibre/maplibre-style.json',
      'json'
    ) as StyleSpecification,
    position: { lon: 139.753, lat: 35.6844, zoom: 3 },
    sources: [submarineCablesSourceId],
    layers: [submarineCablesSourceId],
  };
  const source1: IdbMapSource = {
    id: submarineCablesSourceId,
    state: RecordState.active,
    specification: {
      type: 'geojson',
      data: await MapUtilStore.requestUrl(
        '/assets/geomap/cable-geo.json',
        'json'
      ),
      promoteId: 'id',
    },
  };
  const layer1: IdbMapLayer = {
    id: submarineCablesSourceId,
    state: RecordState.active,
    specification: {
      id: submarineCablesSourceId,
      type: 'line',
      source: submarineCablesSourceId,
      // https://maplibre.org/maplibre-gl-js-docs/style-spec/expressions/
      layout: mapUtilStyleLineSpec1.layout as unknown,
      paint: mapUtilStyleLineSpec1.paint as unknown,
      filter: mapUtilStyleLineSpec1.filter as FilterSpecification
//      filter: ['!', ['all', ['has', '_hide'], ['get', '_hide']]],
    },
  };
  const source2: IdbMapSource = {
    id: test,
    state: RecordState.active,
    specification: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: { type: 'Point', coordinates: [102.0, 0.5] },
            properties: { prop0: 'value0' },
          },
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [
                [102.0, 0.0],
                [103.0, 1.0],
                [104.0, 0.0],
                [105.0, 1.0],
              ],
            },
            properties: {
              prop0: 'value0',
              prop1: 0.0,
            },
          },
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [100.0, 0.0],
                  [101.0, 0.0],
                  [101.0, 1.0],
                  [100.0, 1.0],
                  [100.0, 0.0],
                ],
              ],
            },
          },
        ],
        properties: {
          prop0: 'value0',
          prop1: { this: 'that' },
        },
      },
      promoteId: 'id',
    },
  };
  const layer2: IdbMapLayer = {
    id: test + '/layer==',
    state: RecordState.active,
    specification: {
      id: test + '/layer==',
      type: 'line',
      source: test,
      // https://maplibre.org/maplibre-gl-js-docs/style-spec/expressions/
      layout: mapUtilStyleLineSpec1.layout as unknown,
      paint: mapUtilStyleLineSpec1.paint as unknown,
//      filter: ['!', ['all', ['has', '_hide'], ['get', '_hide']]],
      filter: mapUtilStyleLineSpec1.filter as FilterSpecification
    },
  };
  const confStore: ConfigurationStore = Object.assign(
    new ConfigurationStore(),
    {
      conf: conf0,
      sources: [source1],
      layers: [layer1],
    }
  );
  await MapUtilStore.saveToIndexDB(confStore);
  return confStore;
};
