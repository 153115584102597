/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import MapboxDraw from '@mapbox/mapbox-gl-draw';
//import * as MapDrawExt from './map-draw-ext/modes';
import { MapDrawExt } from './map-ext-draw/mapDrawExt';
import {
  FilterSpecification,
  GeoJSONSourceSpecification,
  IControl,
  LayerSpecification,
  SourceSpecification,
} from 'maplibre-gl';
import { EntitiesWatchCenter } from 'src/app/watch-center/entities-watch-center';
import { MapExt } from './mapExt';
import { MapUtilIds } from './maputil-ids';
import { GeometryGhost } from './geodesy/geometryGhost';
import { ConfigurationStore, MapUtilStore } from './maputil-store';
import { IdbMapLayer, MapIndexDBWrap, RecordState } from './indexDBWrap';
import { mapUtilStyleFillSpec1, mapUtilStyleLineSpec1 as mapUtilStyleLineSpec1 } from './maputil-stylespecs';

type DrawLayer = {
  setCoordinates(latitude: any, longitude: any);
  id: string;
  drawControl: MapboxDraw;
  features: any;
};
class DrawOptionsControl implements IControl {
  map: any;
  container: HTMLElement;

  constructor(private env: {geodesic: boolean}) {}

  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this.container.style.backgroundColor = 'yellow';
    const button = document.createElement('button');
    button.className = `mapbox-gl-draw_ctrl-draw-btn active`;
    button.style.backgroundColor = 'green';
    button.setAttribute('title', 'Geodesic');
    this.container.appendChild(button);

    button.addEventListener(
      'click',
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.env.geodesic = !this.env.geodesic;
        button.style.backgroundColor = this.env.geodesic ? 'yellow' : 'green';
        const clickedButton = e.target;
      },
      true
    );

    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

export class MapUtilDraw {
  private getId(id1: string): string {
    return this.confStore.conf.id + '/' + id1;
  }

  elayers = ['drawlayer1', 'drawlayer2', 'drawlayer3'];
  drawLayers: Map<string, DrawLayer> = new Map<string, DrawLayer>();

  private currentDrawLayer: DrawLayer;

  drawOptions: { geodesic: boolean } = { geodesic: false };

  private drawOptionsControl = new DrawOptionsControl(this.drawOptions);

  private static baseId = 'draw_';

  constructor(
    private map: MapExt,
    private watch: EntitiesWatchCenter,
    private confStore: ConfigurationStore
  ) {
    // https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md
    // https://www.npmjs.com/package/mapbox-gl-draw-geodesic

    this.elayers.forEach((el) => {
      const dl = {
        id: this.getId(el),
        drawControl: new MapDrawExt(this.getId(el),this.drawOptions),
        features: undefined,
        // eslint-disable-next-line object-shorthand, prefer-arrow/prefer-arrow-functions
        setCoordinates: function(latitude, longitude) {
          this.drawControl?.setCoordinates(latitude, longitude);
        },
      };
      this.drawLayers.set(el, dl);
      this.initDrawLayer(el, dl);
    });
  }
  private storeDrawControl() {
    try {
      const properties = this.currentDrawLayer.features?.properties;
      const features = (this.currentDrawLayer.features =
        this.currentDrawLayer.drawControl.getAll());
      features.properties = properties;
      const sourceId = this.currentDrawLayer.id;
      MapUtilIds.setFeaturesId(MapUtilDraw.baseId, features);
      MapUtilStore.saveToIndexDB(this.map.getConfigStore(), {
        newSources: [
          {
            id: sourceId,
            specification: this.getDrawSourceSpecification(features),
          },
        ],
      }).then(() => {
        this.map.setGeoJSONWrapSource(sourceId, features);
      });
    } catch {}
  }

  private loadDrawControl() {
    GeometryGhost.ghostGeometryBackward(this.currentDrawLayer.features);
    this.currentDrawLayer.drawControl.set(this.currentDrawLayer.features);
  }

  enableDrawControl(id: string) {
    if (this.currentDrawLayer) {
      this.storeDrawControl();
      try {
        this.map.removeControl(
          this.currentDrawLayer.drawControl as unknown as IControl
        );
      } catch (e) {
        const i = 0;
      }
      this.map.removeControl(this.drawOptionsControl);
      this.currentDrawLayer = undefined;
    }
    this.currentDrawLayer = this.drawLayers.get(id);

    this.map.addControl(this.drawOptionsControl);
    this.map.addControl(
      this.currentDrawLayer.drawControl as unknown as IControl
    );
    this.loadDrawControl();
    this.map.setSourceInvisible(this.currentDrawLayer.id);
  }

  disableDrawControl(mode: string) {
    if (this.currentDrawLayer) {
      if (mode === 'save') {
        this.storeDrawControl();
      }
      try {
        this.map.removeControl(
          this.currentDrawLayer.drawControl as unknown as IControl
        );
      } catch (e) {
        const i = 0;
      }
      this.map.removeControl(this.drawOptionsControl);
    }
    this.map.setSourceVisible(this.currentDrawLayer.id);
    this.currentDrawLayer = undefined;
  }

  setCoordinates(latitude, longitude) {
    this.currentDrawLayer?.setCoordinates(latitude, longitude);
  }

  private getDrawSourceSpecification(data?): SourceSpecification {
    if (!data) {
      data = {
        type: 'FeatureCollection',
        features: [],
      };
    }
    return {
      type: 'geojson',
      data,
      promoteId: 'id',
    };
  }

  private initDrawLocal(id1: string, dl: DrawLayer, sourceSpec) {
    if (sourceSpec.type === 'geojson') {
      const features: any = (sourceSpec as GeoJSONSourceSpecification).data;
      dl.features = features;
    }

    const drawLayers: IdbMapLayer[] = [
      {
        id: dl.id + '-line',
        state: RecordState.active,
        specification: {
          source: dl.id,
          id: dl.id + '-line',
          type: 'line',
          filter: mapUtilStyleLineSpec1.filter as FilterSpecification, /* [
            'all',
            ['!', ['all', ['has', '_hide'], ['get', '_hide']]],
            [
              'match',
              ['geometry-type'],
              ['LineString', 'MultiLineString'],
              true,
              false,
            ],
          ],*/
          layout: mapUtilStyleLineSpec1.layout as unknown ,
          paint: mapUtilStyleLineSpec1.paint as unknown,
        },
      },
      {
        id: dl.id + '-fill',
        state: RecordState.active,
        specification: {
          source: dl.id,
          id: dl.id + '-fill',
          type: 'fill',
          filter: mapUtilStyleFillSpec1.filter as FilterSpecification,
/*          [
            'all',
            ['!', ['all', ['has', '_hide'], ['get', '_hide']]],
            [
              'match',
              ['geometry-type'],
              ['Polygon', 'MultiPolygon'],
              true,
              false,
            ],
          ],
*/
    layout: mapUtilStyleFillSpec1.layout as unknown,
    paint: mapUtilStyleFillSpec1.paint as unknown/*{
            'fill-color': [
              'case',
              ['boolean', ['has', 'color'], false],
              ['get', 'color'],
              'blue',
            ],
            'fill-opacity': 0.5,
          },*/
        },
      },
      {
        id: dl.id + '-circle',
        state: RecordState.active,
        specification: {
          source: dl.id,
          id: dl.id + '-circle',
          type: 'circle',
          filter: [
            'all',
            ['!', ['all', ['has', '_hide'], ['get', '_hide']]],
            ['match', ['geometry-type'], ['Circle'], true, false],
          ],
        },
      },
    ];

    drawLayers.forEach((layer)=>{
      const index = this.map
      .getConfigStore()
      .layers.findIndex((dblayer) => dblayer.id === layer.id);
      if(index === -1) {
        MapUtilStore.saveToIndexDB(this.map.getConfigStore(), {
          newLayers: [layer],
        });
        this.map.addLayerExt(layer.specification as
          LayerSpecification & { source?: string | SourceSpecification });
      }
    });
  }
  private initDrawLayer(id1: string, dl: DrawLayer) {
    let sourceSpec: SourceSpecification;

    const index = this.map
      .getConfigStore()
      .sources.findIndex((source) => source.id === dl.id);
    if (index !== -1) {
      this.initDrawLocal(
        id1,
        dl,
        this.map.getConfigStore().sources[index].specification
      );
    } else {
      const dbwrap = new MapIndexDBWrap();
      dbwrap
        .getSources({ sources: [dl.id] })
        .then((dbsources) => {
          if (dbsources.length > 0) {
            sourceSpec = dbsources[0].specification;
          } else {
            sourceSpec = this.getDrawSourceSpecification();
          }
        })
        .catch(() => {
          sourceSpec = this.getDrawSourceSpecification();
        })
        .then(() => {
          MapUtilStore.saveToIndexDB(this.map.getConfigStore(), {
            newSources: [
              {
                id: dl.id,
                specification: sourceSpec,
              },
            ],
          });
          this.map.addSourceExt(dl.id, sourceSpec);
          this.initDrawLocal(id1, dl, sourceSpec);
        });
    }
  }
}
