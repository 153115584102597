/* eslint-disable @typescript-eslint/naming-convention */

export enum GeoJSONObjectTypes {
    FeatureCollection = 'FeatureCollection',
    Feature = 'Feature',
    Point = 'Point',
    MultiPoint = 'MultiPoint',
    LineString = 'LineString',
    MultiLineString = 'MultiLineString',
    Polygon = 'Polygon',
    MultiPolygon = 'MultiPolygon',
    GeometryCollection = 'GeometryCollection'
}

class GeoJSONObject {
  crs?;
  bbox?;
  coordinates?:
    | Array<number>
    | Array<Array<number>>
    | Array<Array<Array<number>>>;
  geometries?;
}
class GeoJSONFeature extends GeoJSONObject {
  type;
  geometry;
  properties;
  id?;
}
class GeoJSONFeatureCollection extends GeoJSONObject {
  name?;
  features;
}

export class GeoJSONWrap {
  container: GeoJSONWrap;
  contained: GeoJSONWrap[] = [];
  rootId?: string;
  wrap: any;
  obj: any;
  crs: string;
  constructor(
    container: GeoJSONWrap,
    crs: string,
    obj: any,
    wrapper: (wrap) => any,
  ) {
    this.container = container;
    this.contained = [];
    this.obj = obj;
    this.crs = crs;
    if (this.obj.crs) {
      this.crs = this.obj.crs;
    }

    this.wrap = wrapper(this);

    if (this.obj instanceof Array) {
      // error
      return;
    }
    if (!this.obj.type) {
      // error
      return;
    }

    if(container) {
      container.contained.push(this);
    }
  /*
    switch (this.obj.type) {
      case 'FeatureCollection':
        this.obj.features.forEach((feature) => {
          this.children.push(
            GeoJSONWrap.getObject(this.wrap, this.crs, feature, wrapper)
          );
        });
        break;
      case 'Feature':
        if (this.obj.geometry) {
          this.children.push(
            GeoJSONWrap.getObject(
              this.wrap,
              this.crs,
              this.obj.geometry,
              wrapper)
          );
        }
        break;
      case 'Point':
      case 'MultiPoint':
      case 'LineString':
      case 'MultiLineString':
      case 'Polygon':
      case 'MultiPolygon':
          break;
      case 'GeometryCollection':
        if (this.obj.geometries) {
          this.obj.geometries.forEach((geometry) => {
            this.children.push(
              GeoJSONWrap.getObject(this.wrap, this.crs, geometry, wrapper)
            );
          });
          break;
        }
    }
*/
  }
  static getObject(wrap, crs, obj, wrapper) {
    return new GeoJSONWrap(wrap, crs, obj, wrapper);
  }
  getRoot(): GeoJSONWrap {
    if(this.container instanceof GeoJSONWrap) {
      return this.container.getRoot();
    }
    else {
      return this;
    }
  }
}
