/* eslint-disable @typescript-eslint/naming-convention */

import { ObjectEditor } from 'src/app/object-editor/object-editor';

export const mapUtilStyleLineSpec1 = {
  id: 'styleId',
  type: 'line',
  source: 'sourceId',
  // https://maplibre.org/maplibre-gl-js-docs/style-spec/expressions/
  uioptions: {
    layout: {
      'sort-key': ObjectEditor.schemes.number,
    },
    paint: {
      'line-color': ObjectEditor.schemes.color,
      color: ObjectEditor.schemes.color,
      'line-width': ObjectEditor.schemes.number,
      'line-blur': ObjectEditor.schemes.number,
//TODO      'line-dasharray': [ObjectEditor.schemeTypes.number],
      'line-gap-width': ObjectEditor.schemes.number,
      'line-offset': ObjectEditor.schemes.number,
      'line-opacity': ObjectEditor.schemes.number,
      opacity: ObjectEditor.schemes.number,
//TODO      'line-pattern': ObjectEditor.schemeTypes.image,
//TODO      'line-translate':  [ObjectEditor.schemeTypes.number],
//TODO 'line-translate-anchor': ObjectEditor.schemeTypes.enum,
    }
  },
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
/*    'line-sort-key': [
      'case',
      ['has', 'sort-key', ['feature-state', 'style']],
      ['get', 'sort-key', ['feature-state', 'style']],
      10
    ],
  */  },
  paint: {
    'line-color': [
      'case',
      ['has', 'line-color', ['feature-state', 'style']],
      ['get', 'line-color', ['feature-state', 'style']],
      ['has', 'color', ['feature-state', 'style']],
      ['get', 'color', ['feature-state', 'style']],
//      ['has', 'color',['get','style']],
//      ['get', 'color',['get','style']],
      ['has', 'color'],
      ['get', 'color'],
      'blue',
    ],
    'line-width': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      3,
      ['boolean', ['feature-state', 'highlight'], false],
      3,
      ['has', 'line-width', ['feature-state', 'style']],
      ['get', 'line-width', ['feature-state', 'style']],
      1,
    ],
    'line-blur': [
      'case',
      ['has', 'line-blur', ['feature-state', 'style']],
      ['get', 'line-blur', ['feature-state', 'style']],
      0
    ],
    'line-gap-width': [
      'case',
      ['has', 'gap-width', ['feature-state', 'style']],
      ['get', 'gap-width', ['feature-state', 'style']],
      0
    ],
    'line-offset': [
      'case',
      ['has', 'offset', ['feature-state', 'style']],
      ['get', 'offset', ['feature-state', 'style']],
      0
    ],
    'line-opacity': [
      'case',
      ['has', 'line-opacity', ['feature-state', 'style']],
      ['get', 'line-opacity', ['feature-state', 'style']],
      ['has', 'opacity', ['feature-state', 'style']],
      ['get', 'opacity', ['feature-state', 'style']],
      1
    ],
  },
  filter: [
    'all',
    ['!', ['coalesce',['get','_hide'],false]],
    [
      'match',
      ['geometry-type'],
      ['LineString', 'MultiLineString','Polygon', 'MultiPolygon'],
      true,
      false,
    ],
  ],
};

export const mapUtilStyleFillSpec1 = {
  id: 'styleId',
  type: 'fill',
  source: 'sourceId',
  // https://maplibre.org/maplibre-gl-js-docs/style-spec/expressions/
  uioptions: {
    layout: {
      'sort-key': ObjectEditor.schemes.number,
    },
    paint: {
      color: ObjectEditor.schemes.color,
      'outline-color': ObjectEditor.schemes.color,
      opacity: ObjectEditor.schemes.number,
//TODO      'fill-pattern': ObjectEditor.schemeTypes.image,
//TODO      'fill-translate':  [ObjectEditor.schemeTypes.number],
//TODO      'fill-translate-anchor': ObjectEditor.schemeTypes.enum,
    }
  },
  layout: {
/*    'fill-sort-key': [
      'case',
      ['has', 'sort-key', ['feature-state', 'style']],
      ['get', 'sort-key', ['feature-state', 'style']],
      10
    ],
  */  },
  paint: {
    'fill-color': [
      'case',
      ['has', 'fill-color', ['feature-state', 'style']],
      ['get', 'fill-color', ['feature-state', 'style']],
      ['has', 'color', ['feature-state', 'style']],
      ['get', 'color', ['feature-state', 'style']],
//      ['has', 'color',['get','style']],
//      ['get', 'color',['get','style']],
      ['has', 'color'],
      ['get', 'color'],
      'blue',
    ],
    'fill-outline-color': [
      'case',
//      ['has', 'outline-color', ['feature-state', 'style']],
//      ['get', 'outline-color', ['feature-state', 'style']],
//      ['has', 'fill-color', ['feature-state', 'style']],
//      ['get', 'fill-color', ['feature-state', 'style']],
//      ['has', 'color', ['feature-state', 'style']],
//      ['get', 'color', ['feature-state', 'style']],
      ['has', 'color'],
      ['get', 'color'],
      'blue',
    ],
    'fill-opacity': [
      'case',
      ['has', 'fill-opacity', ['feature-state', 'style']],
      ['get', 'fill-opacity', ['feature-state', 'style']],
      ['has', 'opacity', ['feature-state', 'style']],
      ['get', 'opacity', ['feature-state', 'style']],
      0.5
    ],
  },
  filter:  [
    'all',
    ['!', ['coalesce',['get','_hide'],false]],
    [
      'match',
      ['geometry-type'],
      ['Polygon', 'MultiPolygon'],
      true,
      false,
    ],
  ],
};

export const mapUtilStyleSpec2 = {
  id: 'styleId',
  type: 'line',
  source: 'sourceId',
  filter: [
    'all',
    ['!', ['coalesce',['get','_hide'],false]],
    ['match', ['geometry-type'], ['Polygon', 'MultiPolygon'], true, false],
  ],
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['has', 'color'], false],
      ['get', 'color'],
      'yellow',
    ],
    'fill-opacity': 0.5,
  },
};
