import * as BABYLON from '@babylonjs/core';
import { BabyContext } from '../babymap/babymap/babycontext';

export class SceneTest1 {

  scene: BABYLON.Scene;
  service: BabyContext;

  constructor(service: BabyContext) {
    this.scene = service.scene;
    this.service = service;
  }

  setScene() {

    const camera = new BABYLON.ArcRotateCamera(
      'Camera',
      -Math.PI / 2,
      Math.PI / 3,
      30,
      BABYLON.Vector3.Zero(),
      this.scene
    );
    camera.attachControl(this.service.canvas, true);
    const light = new BABYLON.HemisphericLight(
      'hemiLight',
      new BABYLON.Vector3(10, 10, 0),
      this.scene
    );
    const box = BABYLON.MeshBuilder.CreateBox('box', {}, this.scene);
    box.enablePointerMoveEvents = true;
    const sphere = BABYLON.MeshBuilder.CreateSphere('sphere', {}, this.scene);
    sphere.position = new BABYLON.Vector3(6, -1, -10);
    sphere.enablePointerMoveEvents = true;
    const cylinder = BABYLON.MeshBuilder.CreateCylinder(
      'cylinder',
      {
        height: 2,
        diameter: 1,
      },
      this.scene
    );
    cylinder.position = new BABYLON.Vector3(2, -5, 10);
    cylinder.enablePointerMoveEvents = true;
    const sphere2 = BABYLON.MeshBuilder.CreateSphere(
      'sphere2',
      {
        diameter: 1,
      },
      this.scene
    );
    sphere2.position = new BABYLON.Vector3(-3, 5, -3);
    sphere2.enablePointerMoveEvents = true;
    // Add the highlight layer.
    const hl = new BABYLON.HighlightLayer('hl1', this.scene);
    const linePoints = [];
    this.service.addPointerUpHandler((e, pickResult: BABYLON.PickingInfo) => {
      if (e.button === 0) {
        if (pickResult.hit) {
          console.log(pickResult.pickedMesh.name);
          linePoints.push(pickResult.pickedMesh.position);
          console.log(pickResult.pickedMesh.position);
          hl.addMesh(
            pickResult.pickedMesh as BABYLON.Mesh,
            BABYLON.Color3.Green()
          );
          const lines = BABYLON.MeshBuilder.CreateLines('lines', {
            points: linePoints,
            updatable: true,
          });
		  lines.color = new BABYLON.Color3(1, 0, 0);
		  lines.enablePointerMoveEvents = true;
        }
      }
      if (e.button === 2) {
        if (pickResult.hit) {
          const meshName = pickResult.pickedMesh.name;
          console.log('pickedMesh.nameR: ' + pickResult.pickedMesh.name);
          hl.removeMesh(pickResult.pickedMesh as BABYLON.Mesh);
        }
      }
    }); // End onPointerUp
    this.service.addPointerMoveHandler( (
		evt: BABYLON.IPointerEvent,
		pickInfo: BABYLON.PickingInfo,
		type: BABYLON.PointerEventTypes) => {
			if (pickInfo && pickInfo.hit) {
				console.log('pickedMesh: ' + pickInfo.pickedMesh.name);
			}
		});
  }
}
