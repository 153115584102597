import { Geodesy } from './geodesy';

export class GeodesicCircle {
  static createGeodesicCircle(center, radius, bearing, steps) {
    const coordinates = [];
    for (let i = 0; i < steps; ++i) {
      coordinates.push(
        Geodesy.destinationPoint(center, radius, bearing + (360 * -i) / steps)
      );
    }
    coordinates.push(coordinates[0]);

    return coordinates;
  }
}
