import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

// https://capacitorjs.com/docs/web/pwa-elements
defineCustomElements(window);

// to lock landscape
// https://capacitorjs.com/docs/guides/screen-orientation
window.screen.orientation.lock('landscape').catch(()=>{});

//window.screen.orientation.lock('portrait');

// To unlock orientation which will default back to the global setting:
//window.screen.orientation.unlock();
