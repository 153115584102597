<div style="border: 1px solid lightblue; padding: 20px">
  <div>
    <p-button
      class="p-button-sm"
      [style]="{ margin: '5px', padding: '5px', 'background-color': 'lightblue' }"
      label="Copy"
      (onClick)="copy()"
    ></p-button>
    <p-button
      class="p-button-sm"
      [style]="{ margin: '5px', padding: '5px', 'background-color': 'lightblue' }"
      label="Paste"
      [hidden]="!canPaste()"
      (onClick)="paste()"
    >
    </p-button>
    <p-button
      class="p-button-sm"
      [style]="{ margin: '5px', padding: '5px', 'background-color': 'lightblue' }"
      label=">>>"
      (onClick)="shiftUp()"
    >
    </p-button>
    <p-button
      class="p-button-sm"
      [style]="{ margin: '5px', padding: '5px', 'background-color': 'lightblue' }"
      label="<<<"
      (onClick)="shiftDown()"
    >
    </p-button>
  </div>
  <p-button
    [style]="{ margin: '5px', padding: '5px', 'background-color': 'lightblue' }"
    label="Deactivate"
    [hidden]="!active || !uptodate"
    (onClick)="active = false"
  ></p-button>
  <p-button
    [style]="{ margin: '5px', padding: '5px', 'background-color': 'lightblue' }"
    label="Activate"
    [hidden]="active || !uptodate"
    (onClick)="active = true"
  ></p-button>
  <p-button
    [style]="{ margin: '5px', padding: '5px', 'background-color': 'lightblue' }"
    label="Apply"
    [hidden]="uptodate"
    (onClick)="apply()"
  ></p-button>
  <p-button
    [style]="{ margin: '5px', padding: '5px', 'background-color': 'lightblue' }"
    label="Cancel"
    [hidden]="uptodate"
    (onClick)="cancel()"
  ></p-button>
  <span [hidden]="!uptodate || !active"> matches: {{ matches }}</span>
  <span [hidden]="uptodate" style="color: red">
    click on Apply/ Cancel button</span
  >
  <div [hidden]="!stateData.parseError" style="color: red">
    Selection Parse Error: {{ stateData.parseError }}
  </div>
  <div [hidden]="stateData.evalWarnings.size === 0" style="color: red">
    Selection Eval Warnings:
    <ul>
      <li *ngFor="let w of evalWarningKeys">
        {{ getEvalWarning(w) }}: {{ w }}
      </li>
    </ul>
  </div>
</div>
<div style="border: 1px solid lightblue; padding: 20px">
  <input
    type="text"
    pInputText
    [(ngModel)]="filter"
    placeholder="Selection Criteria"
    style="font-size: 0.7rem"
  />
</div>
<p-tabView [activeIndex]="0">
  <ng-container *ngFor="let p of getProperties()">
    <p-tabPanel [header]="p">
      <app-object-editor [context]="objeditorctxs[p]"></app-object-editor>
    </p-tabPanel>
  </ng-container>
</p-tabView>
