import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorldwindComponent } from './worldwind.component';
import { OverlayModule } from '@angular/cdk/overlay';


@NgModule({
  declarations: [WorldwindComponent],
  imports: [
    CommonModule,
    OverlayModule
  ],
  exports: [WorldwindComponent]
})
export class WorldwindModule { }
