import proj4 from 'proj4';
import * as BABYLON from '@babylonjs/core';
import rgba from 'color-rgba';
import { ArgumentError } from './worldwind/ArgumentError';
import { GeoJSONParser } from './worldwind/GeoJSONParser';
import { Logger } from './worldwind/Logger';
import { Vector3 } from '@babylonjs/core';
import { BabyContext } from './babycontext';
import { GeoJSONWrap, GeoJSONObjectTypes } from './worldwind/GeoJSONWrap';
import { WebMercator } from './webmercator';
import { geometryPixelShader } from '@babylonjs/core/Shaders/geometry.fragment';
import { BabyEarcut } from './babyearcut';

export class BabyGeoJSON {
  /**
   * The GeoJSON data source as specified to this GeoJSON's constructor.
   *
   * @memberof GeoJSONParser.prototype
   * @type {String|Object}
   * @readonly
   */
  private dataSource: any;
  /**
   * The GeoJSON object resulting from the parsing of GeoJSON string.
   *
   * @memberof GeoJSONParser.prototype
   * @type {Object}
   * @readonly
   */
  private geoJSONObject: any;
  /**
   * The type of the GeoJSON. The type can be one of the following:
   * <ul>
   *     <li>GeoJSONConstants.TYPE_POINT</li>
   *     <li>GeoJSONConstants.TYPE_MULTI_POINT</li>
   *     <li>GeoJSONConstants.TYPE_LINE_STRING</li>
   *     <li>GeoJSONConstants.TYPE_MULTI_LINE_STRING</li>
   *     <li>GeoJSONConstants.TYPE_POLYGON</li>
   *     <li>GeoJSONConstants.TYPE_MULTI_POLYGON</li>
   *     <li>GeoJSONConstants.TYPE_GEOMETRY_COLLECTION</li>
   *     <li>GeoJSONConstants.TYPE_FEATURE</li>
   *     <li>GeoJSONConstants.TYPE_FEATURE_COLLECTION</li>
   * </ul>
   * This value is defined after GeoJSON parsing.
   *
   * @memberof GeoJSONParser.prototype
   * @type {String}
   * @readonly
   */
  private geoJSONType: any;
  /*
   *
   */
  private crs: any;
  /**
   * The layer containing the shapes representing the geometries in this GeoJSON, as specified to this
   * GeoJSON's constructor or created by the constructor if no layer was specified.
   *
   * @memberof GeoJSONParser.prototype
   * @type {RenderableLayer}
   * @readonly
   */
  private layer: any;

  private center: Vector3;

  private radius: number;

  private parser: GeoJSONParser;
  private defColor: any;
  private defName: any;
  private context: BabyContext;

  constructor(
    context: BabyContext, // the environment
    dataSource: string | object, // source of geojson data, an URL, a local file or a geojson object
    center: Vector3, // center of the sphere
    radius: number, // the radius of the sphere on which to draw the objects
    precision: number, // the angular precision to create intermediate curve points
    crs: string // coordinate reference system of the client
    ) {
    if (!dataSource) {
      throw new ArgumentError(
        Logger.logMessage(
          Logger.LEVEL_SEVERE,
          'GeoJSON',
          'constructor',
          'missingDataSource'
        )
      );
    }

    this.context = context;

    this.dataSource = dataSource;

    this.center = center;

    this.radius = radius;

    this.crs = crs;

    // Documented in defineProperties below.
    this.geoJSONObject = null;

    // Documented in defineProperties below.
    this.geoJSONType = null;

    // Documented in defineProperties below.
    this.crs = null;

    // Documented in defineProperties below.
    this.layer = null;

    this.parser = new GeoJSONParser(dataSource, proj4.WGS84);

    this.parser.load(
      this,
      () => {},
      (ref: any, wrap: GeoJSONWrap) => {
        switch (wrap.obj.type) {
          case GeoJSONObjectTypes.FeatureCollection:
            break;
          case GeoJSONObjectTypes.Feature:
            if (wrap.obj.properties && wrap.obj.properties.color) {
              this.defColor = rgba(wrap.obj.properties.color);
            }
            if (wrap.obj.properties && wrap.obj.properties.name) {
              this.defName = wrap.obj.properties.name;
            }
            break;
          case GeoJSONObjectTypes.Point:
            break;
          case GeoJSONObjectTypes.MultiPoint:
            break;
          case GeoJSONObjectTypes.LineString:
            this.arcLine(wrap.obj.geometry,this.radius,precision,wrap.crs,this.defColor);
            break;
          case GeoJSONObjectTypes.MultiLineString:
            for(const i of wrap.obj.coordinates) {
              this.arcLine(i,this.radius,precision,this.crs,this.defColor);
            };
            break;
          case GeoJSONObjectTypes.Polygon:
            this.arcPoly(wrap.obj.geometry,this.radius,precision,wrap.crs,this.defColor);
            break;
          case GeoJSONObjectTypes.MultiPolygon:
            for(const i of wrap.obj.coordinates) {
              this.arcPoly(i,this.radius,precision,this.crs,this.defColor);
            };
            break;
        }
      }
    );
  }

  static arcSegment(
    start: { lat: number; lon: number },
    end: { lat: number; lon: number },
    radius: number,
    it: number
  ): BABYLON.Curve3 {
    const firstc = { lat: start.lat, lon: start.lon };
    const lastc = { lat: end.lat, lon: end.lon };
    const midc = {
      lat: (lastc.lat + firstc.lat) / 2,
      lon: (lastc.lon + firstc.lon) / 2,
    };
    const first = WebMercator.getLonLatXYZ({
      lon: firstc.lon,
      lat: firstc.lat,
      r: radius,
    });
    const mid = WebMercator.getLonLatXYZ({
      lon: midc.lon,
      lat: midc.lat,
      r: radius,
    });
    const last = WebMercator.getLonLatXYZ({
      lon: lastc.lon,
      lat: lastc.lat,
      r: radius,
    });
    const firstv = new BABYLON.Vector3(first.x, first.y, first.z);
    const midv = new BABYLON.Vector3(mid.x, mid.y, mid.z);
    const lastv = new BABYLON.Vector3(last.x, last.y, last.z);
    return BABYLON.Curve3.ArcThru3Points(firstv, midv, lastv, it, false, false);
  }
  arcLine(
    geometry: number[][],
    radius: number,
    precision: number,
    crs: string,
    color: number[]
  ) {
    const proj4f = proj4('EPSG:4326','EPSG:3857');
    const points: BABYLON.Vector3[] = [];
    for (let i = 0; i < geometry.length - 1; i += 2) {
      //points.pop(); // to remove duplicate coordinates
      const latlon00 = [geometry[i][1],geometry[i][0]];
      const latlon10 = [geometry[i+1][1],geometry[i+1][0]];
      const latlon0 = proj4f.forward(latlon00);
      const latlon1 = proj4f.forward(latlon10);
//      const latlon1 = proj4Functions.forward(latlon10);

      const dist = Math.max(Math.round(
        Math.sqrt(
          (geometry[i + 1][1] - geometry[i][1]) ** 2 +
            (geometry[i + 1][0] - geometry[i][0]) ** 2
        )
      ),1);
      const lpoints: BABYLON.Vector3[] = BabyGeoJSON.arcSegment(
        {
          lat: (geometry[i][1] / 180) * Math.PI,
          lon: (geometry[i][0] / 180) * Math.PI,
        },
        {
          lat: (geometry[i + 1][1] / 180) * Math.PI,
          lon: (geometry[i + 1][0] / 180) * Math.PI,
        },
        this.radius,
        dist*20
      ).getPoints();
      points.push(...lpoints);
    }
    const sphereLine = BABYLON.MeshBuilder.CreateLines(
      this.defName,
      {
        points,
        updatable: false,
      },
      this.context.scene
    );
    sphereLine.color = new BABYLON.Color3(
      color[0]/255,
      color[1]/255,
      color[2]/255
    );
    sphereLine.enablePointerMoveEvents = true;
  }
  arcPoly(
    geometry: number[][],
    radius: number,
    precision: number,
    crs: string,
    color: number[]
  ) {
    BabyEarcut.jsonSphareEarcut(geometry);
  }

}


