/* eslint-disable @typescript-eslint/member-ordering */

//https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/MODES.md#available-custom-modes

export interface IMapDrawEnv {
  option: {
    geodesic: boolean;
  };
  event: (eventid: string, state: any, event: any, target: any) => void;
};

export abstract class IMapDraw implements Record<string, any> {
  [x: string]: any;

  constructor(private env: IMapDrawEnv, private chain?: any) {
    // when extending functionality from another 'chain' object.
    if (chain) {
      Object.getOwnPropertyNames(chain).forEach((p) => {
        this[p] = Object.getOwnPropertyDescriptor(chain, p).value;
      });
//      Object.assign(this,chain);
//      Object.setPrototypeOf(this,chain);
    }
  }

  //shall override the following to implement functionality
  //onSetup(opts): object
    //Triggered while a mode is being transitioned into.
    //Parameters
    // opts {Object} - this is the object passed via draw.changeMode('mode', opts);
    //Returns Object this object will be passed to all other life cycle functions

  //onDrag(state, e) {
    //Triggered when a drag event is detected on the map
    //Parameters
    //    state {Object} - a mutible state object created by onSetup
    //    e {Object} - the captured event that is triggering this life cycle event

  //onClick(state, e) {
    //Triggered when the mouse is clicked
    //Parameters
    //    state {Object} - a mutible state object created by onSetup
    //    e {Object} - the captured event that is triggering this life cycle event

  //onMouseMove(state, e) {
    //Triggered with the mouse is moved
    //Parameters
    //    state {Object} - a mutible state object created by onSetup
    //    e {Object} - the captured event that is triggering this life cycle event

  //onMouseDown(state, e) {
    //Triggered when the mouse button is pressed down
    //Parameters
    //    state {Object} - a mutible state object created by onSetup
    //    e {Object} - the captured event that is triggering this life cycle event

  //onMouseUp(state, e) {
    //Triggered when the mouse button is released
    //Parameters
    //    state {Object} - a mutible state object created by onSetup
    //    e {Object} - the captured event that is triggering this life cycle event

  //onMouseOut(state, e) {
    //Triggered when the mouse leaves the map's container
    //Parameters
    //    state {Object} - a mutible state object created by onSetup
    //    e {Object} - the captured event that is triggering this life cycle event

  //onKeyup(state, e) {
    //Triggered when a key up event is detected
    //Parameters
    //    state {Object} - a mutible state object created by onSetup
    //    e {Object} - the captured event that is triggering this life cycle event

  //onKeyDown(state, e) {
    /*
Triggered when a key down event is detected

Parameters

    state {Object} - a mutible state object created by onSetup
    e {Object} - the captured event that is triggering this life cycle event
*/
  //onTouchStart(state, e) {
    /*
Triggered when a touch event is started

Parameters

    state {Object} - a mutible state object created by onSetup
    e {Object} - the captured event that is triggering this life cycle event
*/
  //onTouchMove(state, e) {
    /*
Triggered when one drags their finger on a mobile device

Parameters

    state {Object} - a mutible state object created by onSetup
    e {Object} - the captured event that is triggering this life cycle event
*/
  //onTouchEnd(state, e) {
    /*
Triggered when one removes their finger from the map

Parameters

    state {Object} - a mutible state object created by onSetup
    e {Object} - the captured event that is triggering this life cycle event
*/
  //onTap(state, e) {
    /*
Triggered when one quickly taps the map

Parameters

    state {Object} - a mutible state object created by onSetup
    e {Object} - the captured event that is triggering this life cycle event
*/
//  onStop(state) {
    /*
Triggered when the mode is being exited, to be used for cleaning up artifacts such as invalid features

Parameters

    state {Object} - a mutible state object created by onSetup
*/
//  onTrash(state) {
    /*
Triggered when draw.trash() is called.

Parameters

    state {Object} - a mutible state object created by onSetup
*/
//  onCombineFeature(state) {
    /*
Triggered when draw.combineFeatures() is called.

Parameters

    state {Object} - a mutible state object created by onSetup
*/
//  onUncombineFeature(state) {
    /*
Triggered when draw.uncombineFeatures() is called.

Parameters

    state {Object} - a mutible state object created by onSetup
*/
//  toDisplayFeatures(state, geojson, display) {
    /*
Triggered per feature on render to convert raw features into set of features for display on the map
See styling draw for information about what geojson properties Draw uses as part of rendering.

Parameters

    state {Object} - a mutible state object created by onSetup
    geojson {Object} - a geojson being evaulated. To render, pass to display.
    display {Function} - all geojson objects passed to this be rendered onto the map
*/
  //the following are overriden by mapdraw
//  setSelected(features) {
  //Sets Draw's internal selected state
  //Parameters
  //    features
  //    null-null Array<DrawFeature> whats selected as a DrawFeature

//  setSelectedCoordinates(coords) {
  //Sets Draw's internal selected coordinate state
  //Parameters
  //    coords Array<Object> a array of {coord_path: 'string', feature_id: 'string'}

//  getSelected(): Array<DrawFeature> {
  //Get all selected features as a DrawFeature
  //Returns Array<DrawFeature>

//  getSelectedIds(): Array<string> {
  //Get the ids of all currently selected features
  //Returns Array<String>

//  isSelected(id: string): boolean {
  //Check if a feature is selected
  //Parameters
  //    id String a feature id
  //Returns Boolean

//  getFeature(id: string): DrawFeature {
  //Get a DrawFeature by its id
  //Parameters
  //    id String a feature id
  //Returns DrawFeature

//  select(id: string) {
  //Add a feature to draw's internal selected state
  //Parameters
  //    id String

//  delete(id: string) {
  //Remove a feature from draw's internal selected state
  //Parameters
  //    id String

//  deleteFeature(id: string | string[], opts?: any) {
  //Delete a feature from draw
  //Parameters
  //    id String a feature id
  //    opts (optional, default {})

//  addFeature(feature: DrawFeature) {
  //Add a DrawFeature to draw. See this.newFeature for converting geojson into a DrawFeature
  //Parameters
  //    feature DrawFeature the feature to add

//  clearSelectedFeatures() {
  //Clear all selected features
  //clearSelectedCoordinates
  //Clear all selected coordinates

//  setActionableState(actions?: object) {
  //Indicate if the different actions are currently possible with your mode See draw.actionalbe for a list of possible actions.
  //All undefined actions are set to false by default
  //Parameters
  //    actions Object (optional, default {})

//  changeMode(mode: string, opts?: object, eventOpts?: object) {
  //Trigger a mode change
  //Parameters
  //    mode String the mode to transition into
  //    opts Object the options object to pass to the new mode (optional, default {})
  //    eventOpts Object used to control what kind of events are emitted. (optional, default {})

//  updateUIClasses(opts: object) {
  //Update the state of draw map classes
  //Parameters
  //    opts Object

//  activateUIButton(name?: string) {
  //If a name is provided it makes that button active, else if makes all buttons inactive
  //Parameters
  //    name String? name of the button to make active, leave as undefined to set buttons to be inactive

//  featuresAt(event, bbox, bufferType?: string) {
  //Get the features at the location of an event object or in a bbox
  //Parameters
  //    event
  //    bbox
  //    bufferType String is this click or tap event, defaults to click (optional, default 'click')

//  newFeature(geojson: object): DrawFeature {
  /*
    Create a new DrawFeature from geojson
    Parameters
        geojson GeoJSONFeature
    Returns DrawFeature
    */

//  isInstanceOf(type: string, feature: object): boolean {
  /*
    Check is an object is an instance of a DrawFeature
    Parameters

        type String Point, LineString, Polygon, MultiFeature
        feature Object the object that needs to be checked

    Returns Boolean
    */

//  doRender(id: string) {
  /*
    Force draw to rerender the feature of the provided id
    Parameters
        id String a feature id
*/
//  dragVertex(state, e, delta) {
//  dragFeature(state, e, delta) {
}
