// https://stackblitz.com/edit/angular-file-picker-directive

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';

import { FilePickerDirective } from './file-picker.directive';
import { SelectedFilesTableComponent } from './selected-files-table/selected-files-table.component';
import { FilePickerComponent } from './file-picker.component';


@NgModule({
  declarations: [
    FilePickerDirective,
    FilePickerComponent,
    SelectedFilesTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatDividerModule,
    MatTabsModule,
    MatTableModule,

  ],
  exports: [
    FilePickerComponent
  ]
})
export class FilePickerModule { }
