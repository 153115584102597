import { LayerSpecification, SourceSpecification } from 'maplibre-gl';
import { EntitiesWatchCenter } from 'src/app/watch-center/entities-watch-center';
import { MapExt } from './mapExt';
import { LayerGroup } from './layergroup';
import { EntitiesWatcher } from 'src/app/watch-center/entities-watcher';

export class LayersControl extends EntitiesWatchCenter {
  private sourceLayersGroup: Map<string, LayerGroup> = new Map<
    string,
    LayerGroup
  >();

  private layers: Map<
    string,
    LayerSpecification & { source?: string | SourceSpecification }
  > = new Map<
    string,
    LayerSpecification & { source?: string | SourceSpecification }
  >();

  constructor(
    private mapExt: MapExt,
    propertyName: string
  ) {
    super(propertyName);
    const cthis = this;
    new (class extends EntitiesWatcher {
      add(
        i: any,
        properties: { [key: string]: any },
        position: { [key: string]: any; container?: any; before?: any }
      ) {
        //throw new Error('Method not implemented.');
      }
      remove(i: any, options?: { [key: string]: any }) {
        //throw new Error('Method not implemented.');
      }
      removeAll(i: any[]) {
        //throw new Error('Method not implemented.');
      }
      update(i: any, properties: { [key: string]: any }) {}
      stUpdate(i: any, propertyNames: [key: string]) {
        const toUpdate: string[] = ['selected'].filter((value) =>
          propertyNames.includes(value)
        );
        const properties = cthis.getMergedSTProperties(
          i,
          toUpdate
        );
        if (i.id && properties?.selected != null) {
          cthis.mapExt.setLayoutProperty(
            i.id,
            'visibility',
            properties.selected ? 'visible' : 'none'
          );
        }
      }
      move(
        entity: any,
        position: { [key: string]: any; container?: any; before?: any }
      ) {
        //throw new Error('Method not implemented.');
      }
      command(i: any, o: { [key: string]: any }) {
        //throw new Error('Method not implemented.');
      }
      broadcast(i: any, o: { [key: string]: any }) {
        //throw new Error('Method not implemented.');
      }
    })(this);
  }

  setGroup(id: string, group: LayerGroup) {
    this.sourceLayersGroup.set(id, group);
  }

  setLayer(
    id: string,
    layer: LayerSpecification & { source?: string | SourceSpecification }
  ) {
    this.layers.set(id, layer);
    this.add(layer, { name: layer.id, id: layer.id, selected: true });
  }

  getLayers(): Map<
    string,
    LayerSpecification & { source?: string | SourceSpecification }
  > {
    return this.layers;
    //    return this.mapExt?.getLayers();
  }

  setSourceVisible(sourceId: string) {
    this.getLayers().forEach((layer, id) => {
      if (layer.source === sourceId) {
        this.mapExt.setLayoutProperty(id, 'visibility', 'visible');
      }
    });
  }
  setSourceInvisible(sourceId: string) {
    this.getLayers().forEach((layer, id) => {
      if (layer.source === sourceId) {
        this.mapExt.setLayoutProperty(id, 'visibility', 'none');
      }
    });
  }
}
