<!--
<app-window>
  <app-geo-entities-tree></app-geo-entities-tree>
</app-window>
-->

<!-- https://swiperjs.com/angular -->

<!-- https://material.angular.io/components/badge/overview -->

<mat-sidenav-container #sidenavContainer class="sidenav-container">
  <!-- https://fonts.google.com/icons?selected=Material+Icons -->
  <!-- https://www.angularjswiki.com/angular/angular-material-icons-list-mat-icon-list/ -->
  <mat-sidenav opened mode="side">
    <mat-action-list>
      <mat-list-item>
        <button
        [aTooltip]="{text: 'Save Geo-Objects Data'}"
          class="button-sidenav"
          (click)="saveEntities()"
        >
        <mat-icon>save</mat-icon>
        </button>
    </mat-list-item>
    <mat-list-item>
          <button
          [aTooltip]="{text: 'Geo-Object Editor'}"
            class="button-sidenav"
            (click)="panelDisplaySet('edit', drawer)"
          >
            <i class="gg-pen"></i>
            <span class="badge1-sidenav" style="background-color: yellowgreen;color: red;">9</span>
          </button>
      </mat-list-item>
      <mat-list-item>
          <button
          [aTooltip]="{text: 'Geo-Objects Tree'}"
          class="button-sidenav"
          (click)="panelDisplaySet('items', drawer)"
          >
            <i class="gg-list-tree"></i>
            <span class="badge1-sidenav" style="background-color: blue;color: red;">9</span>
          </button>
      </mat-list-item>
      <mat-list-item>
        <div>
          <button
          [aTooltip]="{text: 'Map Layers'}"
          class="button-sidenav"
          (click)="panelDisplaySet('layers', drawer)"
          >
            <i class="gg-stack"></i>
          </button>
        </div>
      </mat-list-item>
      <mat-list-item>
        <div>
          <button
          [aTooltip]="{text: 'Data Sources & Layers Configuration'}"
          class="button-sidenav"
          (click)="panelDisplaySet('confstore', drawer)"
          >
            <i class="gg-play-list-add"></i>
          </button>
        </div>
      </mat-list-item>
      <mat-list-item>
        <button
        [aTooltip]="{text: 'Load/ Save'}"
        class="button-sidenav"
        (click)="panelDisplaySet('file', drawer)"
        >
          <i class="gg-database"></i>
        </button>
      </mat-list-item>
      <mat-list-item>
        <div>
          <button
          [aTooltip]="{text: 'Map Snapshot'}"
          class="button-sidenav"
          (click)="copy2clipboard()"
            style="position: relative"
          >
            <fa-icon [icon]="faCamera"></fa-icon>
          </button>
        </div>
      </mat-list-item>
    </mat-action-list>
  </mat-sidenav>
  <!--
  <mat-sidenav opened mode="side" position="end">End content</mat-sidenav>
 -->
  <mat-drawer-container class="sidenav-container" hasBackdrop="false">
    <mat-drawer #drawer mode="over">
      <app-watched-entities-tree 
        [hidden]="panelContent !== 'items'"
        [watchCenter]="map?.watchCenter" 
        [properties]="getProperties()" 
        [icons]="['gg-add-r gg-icon-wet','gg-abstract gg-icon-wet']" 
        [getContextMenu]="map.getEntityContextMenu"
        [datastore]="stpData"
        stpSize="10"
        header="Geo Entities"
        footer="-- end of entities --"
        >
      </app-watched-entities-tree>

      <div [hidden]="panelContent !== 'confstore'">
        <p>Layer Edit/ reorder to implement</p>
        <i class="icon icon-menu"></i>
        <div #configEditor></div>
      </div>
      <div [hidden]="panelContent !== 'layers'">
        <p>Layer Edit/ reorder to implement</p>
<!--        <app-layers-ui [layers]="map?.map?.layersControl"></app-layers-ui>
-->
        <app-watched-entities-tree 
          [watchCenter]="map?.map?.layersControl" 
          [properties]="{tags: {}}" 
          [icons]="['gg-add-r gg-icon-wet']"
          stpSize="0"
          header="Layers"
          footer="-- end of layers --">
        </app-watched-entities-tree>
      </div>

      <div [hidden]="panelContent !== 'file'">
        <button
          mat-button
          onclick="showOpenFilePicker().then((file) => 
          getElementById('pickfile001').dispatchEvent(
            new CustomEvent('load-config', { detail: file })))"
        >
          Pick a configuration
        </button>
        <button
          mat-button
          onclick="showSaveFilePicker().then((file) => 
          getElementById('pickfile001').dispatchEvent(
            new CustomEvent('save-config',{detail: file})))"
        >
          Save configuration
        </button>
        <div
          id="pickfile001"
          (save-config)="emitEventToChild($event)"
          (load-config)="emitEventToChild($event)"
        ></div>
        <app-file-picker></app-file-picker>
      </div>
      <div [hidden]="panelContent !== 'edit'">
        <mat-button-toggle-group
          [(value)]="draw"
          name="favoriteColor"
          aria-label="Favorite Color"
          vertical
        >
          <mat-button-toggle [hidden]="!drawIsEditing(draw)" value="save"
            >Save&Close</mat-button-toggle
          >
          <mat-button-toggle [hidden]="!drawIsEditing(draw)" value="cancel"
            >Cancel&Close</mat-button-toggle
          >
          <mat-button-toggle [hidden]="drawIsEditing(draw)" value="drawlayer1"
            >Draw Layer-1</mat-button-toggle
          >
          <mat-button-toggle [hidden]="drawIsEditing(draw)" value="drawlayer2"
            >Draw Layer-2</mat-button-toggle
          >
          <mat-button-toggle [hidden]="drawIsEditing(draw)" value="drawlayer3"
            >Draw Layer-3</mat-button-toggle
          >

          <div [hidden]="!drawIsEditing(draw)">
            <mat-form-field>
              <mat-label>Latitude</mat-label>
              <input matInput type="text" [(ngModel)]="latitude">
              <button *ngIf="latitude" matSuffix mat-icon-button aria-label="Clear" (click)="latitude=''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field><br>
            <mat-form-field>
              <mat-label>Longitude</mat-label>
              <input matInput type="text" [(ngModel)]="longitude">
              <button *ngIf="longitude" matSuffix mat-icon-button aria-label="Clear" (click)="longitude=''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field><br>
            <button mat-button (click)="setCoordinates()">Apply</button>
          </div>
          
        </mat-button-toggle-group>
      </div>
    </mat-drawer>
    <!-- side - over - push -->
    <mat-drawer-content>
      <app-maplibre
        #map
        [draw]="draw"
        (stpDataEvent)="stpData = $event"
        [events]="eventsSubject.asObservable()"
      ></app-maplibre>
    </mat-drawer-content>
  </mat-drawer-container>
</mat-sidenav-container>
