import { Component, Directive, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BabymapService } from './babymap.service';

@Component({
  selector: 'app-babymap',
  templateUrl: './babymap.component.html',
  styleUrls: ['./babymap.component.scss'],
})
export class BabymapComponent implements OnInit {

  @ViewChild('rendererCanvas', { static: true })
  public rendererCanvas: ElementRef<HTMLCanvasElement>;

  constructor(private bmServ: BabymapService) { }

  ngOnInit() {
    this.bmServ.createScene(this.rendererCanvas);
    this.bmServ.animate();
  }

}
