
import { Component, AfterViewInit } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-leafletmap',
  templateUrl: './leafletmap.component.html',
  styleUrls: ['./leafletmap.component.scss'],
})
export class LeafletmapComponent implements AfterViewInit {

  private map;
  private geolayer;
  private clonedFeature = [];

  constructor() { }

  ngAfterViewInit() {
    this.initMap();
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [ 39.8282, -98.5795 ],
      zoom: 0,
      worldCopyJump: true
      });
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 2,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    fetch(
      '/assets/geomap/cable-geo.json'
    ).then(
      res => res.json()
    ).then(
      data => this.geolayer = L.geoJSON(data, {
        onEachFeature: (feature,layer) => {
        }
      }).addTo(this.map)
    );

    const geo1: GeoJSON.Feature = {
      type: 'Feature',
      properties: {
        name: 'toto'
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [-80,10],
          [-178,40]
        ]
      }
    };

    const geo2: GeoJSON.Feature = {
      type: 'Feature',
      properties: {
        name: 'toto'
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [-80+360,10],
          [-178+360,40]
        ]
      }
    };

//    this.geolayer.addData(geo1);
//    this.geolayer.addData(geo2);

//    L.geoJSON(geo1).addTo(this.map);
//    L.geoJSON(geo2).addTo(this.map);

  }

}
