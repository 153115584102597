// https://www.npmjs.com/package/earcut

import * as earcut from 'earcut';

export class BabyEarcut {
    static jsonSphareEarcut(coordinates) {
        const data = earcut.flatten(coordinates);

        const triangles = earcut(data.vertices, data.holes, data.dimensions);


    }
}
