//https://stackoverflow.com/questions/5559712/how-to-reliably-hash-javascript-objects
// https://www.npmjs.com/package/object-hash

import hash from 'object-hash';

const randomIdString = (base: string): string => {
  const randomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;
  return base + randomInt(0, 2 ** 53 - 1).toString(36);
};

export class MapUtilIds {
  static setFeaturesId(base: string, features: any) {
    if (Array.isArray(features)) {
      features.forEach((item) => {
        MapUtilIds.setFeaturesId(base, item);
      });
      return;
    }
    switch (features.type) {
      case 'FeatureCollection':
        features.features?.forEach((feature) =>
          MapUtilIds.setFeaturesId(base, feature)
        );
      // eslint-disable-next-line no-fallthrough
      case 'Feature':
        if (!features.id) {
          if (features.properties?.id) {
            features.id = features.properties.id;
          } else if (features.properties?.name) {
            features.id = features.properties.name;
          } else if (features.name) {
            features.id = features.name;
          } else {
            //features.id = randomIdString(base+features.type);
            features.id = 'id'+hash(features,{algorithm: 'md5',encoding: 'base64',excludeKeys: (key)=>(key==='coordinates')});
          }
        }
        if (!features.properties) {
          features.properties = {};
        }
        if (!features.properties.id) {
          features.properties.id = features.id;
        }
      break;
    }
  }
}
