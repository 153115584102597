/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDrawer, MatSidenavContainer } from '@angular/material/sidenav';
import { Subject } from 'rxjs';
import { JSONEditor } from '@json-editor/json-editor';
import { MapUtilStore } from '../maplibre/maplibre/maputil-store';
import { Clipboard } from '@capacitor/clipboard';
import { MaplibreComponent } from '../maplibre/maplibre/maplibre.component';
import { faCoffee, faCamera } from '@fortawesome/free-solid-svg-icons';
import { DataStore } from '../data-store/data-store';
import { ObjectEditorContext } from '../object-editor/st-editor-int';
import { ObjectEditor } from '../object-editor/object-editor';

@Component({
  selector: 'app-map-page',
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.scss'],
})
export class MapPageComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('sidenavContainer')
  private sidenavContainer!: MatSidenavContainer;

  @ViewChild('configEditor')
  private configEditor!: ElementRef<HTMLElement>;

  @ViewChild('map')
  private mapComponent: MaplibreComponent;

  public editMenu = false;

  //fontawesome icons
  faCoffee = faCoffee;
  faCamera = faCamera;

  latitude = '';
  longitude = '';

  //
  public panelDisplay = false;
  public panelContent = '';

  // associated with draw panel, select drawing rhumb or geodesic lines
  public draw = '';

  eventsSubject: Subject<void> = new Subject<void>();

  _stpData: DataStore;
  set stpData(d: DataStore) {
    this._stpData = d;
  }
  get stpData(): DataStore {
    return this._stpData;
  }

  getProperties(): { [key: string]: ObjectEditorContext } {
    return {
      tags: {},
      style: {
        list: {
          'line-color': {
            scheme: ObjectEditor.schemes.color,
            default: 'green',
          },
          'line-width': {
            scheme: ObjectEditor.schemes.number,
            default: 2,
          }
        },
      },
    };
  }

  constructor() {
    const toto = 0;
  }
  ngOnChanges(changes: SimpleChanges): void {
    const i = 0; // TODO
  }

  ngAfterViewInit(): void {
    // https://json-schema.org/
    // https://github.com/json-editor/json-editor#readme
    // https://github.com/picturepan2/spectre
    MapUtilStore.requestUrl(
      '/assets/maplibre/map-json-schema.json',
      'json'
    ).then((value: any) => {
      const editor = new JSONEditor(this.configEditor.nativeElement, {
        theme: 'spectre',
        iconlib: 'spectre',
        keep_oneof_values: false,
        remove_button_labels: true,
        disable_edit_json: true,
        disable_properties: true,
        compact: true,
        schema: value['source-specification'],
      });
    });
  }

  emitEventToChild(evt) {
    this.eventsSubject.next(evt);
  }

  setCoordinates() {
    this.emitEventToChild(
      new CustomEvent('set-coordinates', {
        detail: {
          latitude: this.latitude,
          longitude: this.longitude,
        },
      })
    );
  }

  saveEntities() {
    this.emitEventToChild(new CustomEvent('save-entities'));
  }

  ngOnInit() {
    this.stpData = this.mapComponent?.stpData;
  }

  drawIsEditing(drawState: string): boolean {
    return drawState && drawState !== 'cancel' && drawState !== 'save';
  }

  copy2clipboard = async () => {
    const dataUrl = await this.takeScreenshot();
    Clipboard.write({ image: dataUrl });
  };

  takeScreenshot(): Promise<string> {
    const lthis = this;
    return new Promise((resolve, reject) => {
      this.mapComponent.map.once('render', () => {
        resolve(lthis.mapComponent.map.getCanvas().toDataURL());
      });
      /* trigger render */
      // eslint-disable-next-line no-underscore-dangle
      this.mapComponent.map._render(0);
    });
  }
  panelDisplaySet(contentId: string, drawer: MatDrawer) {
    if (contentId === this.panelContent) {
      drawer.toggle().then(() => this.mapComponent.map.redraw());
    } else {
      this.panelContent = contentId;
      if (!drawer.opened) {
        drawer.toggle().then(() => this.mapComponent.map.redraw());
      }
    }
  }
}
