/* eslint-disable no-underscore-dangle */
import * as Constants from '../geodesy/constants';
import { CircleGeoJson } from '../geodesy/circle-geojson';
import { Geodesy } from '../geodesy/geodesy';
import { IMapDraw, IMapDrawEnv } from './IMapDraw';
import { initialBearing } from '../geodesy/spherical';
import { extDisplay } from './extDisplay';

export class ExtDirectSelect extends IMapDraw {
  constructor(env: IMapDrawEnv, chain?: any) {
    super(env,chain);
  }
  clickNoTarget = function(state, e) {
    this.chain.clickNoTarget.call(this, state, e);
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    if (typeof this.env?.event === typeof function() {}) {
      this.env.event('clickNoTarget', state, e, this);
    }
  };
  onStop = function(state, e) {
    this.chain.onStop.call(this, state, e);
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    if (typeof this.env?.event === typeof function() {}) {
      this.env.event('clickNoTarget', state, e, this);
    }
  };
  dragVertex = function(state, e, delta) {
    const geojson = state.feature.toGeoJSON();

    if (CircleGeoJson.isCircle(geojson)) {
      if (state.selectedCoordPaths[0] === '0.1') {
        const center = CircleGeoJson.getCircleCenter(geojson);
        const handle = [e.lngLat.lng, e.lngLat.lat];
        const radius = Geodesy.distance(center, handle);
        const handleBearing = initialBearing(center, handle);
        state.feature.properties[Constants.properties.CIRCLE_RADIUS] = radius;
        state.feature[Constants.properties.CIRCLE_HANDLE_BEARING] =
          handleBearing;
        state.feature.changed();
      } else {
        this.chain.dragFeature.call(this, state, e, delta);
      }
    } else {
      this.chain.dragVertex.call(this, state, e, delta);
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      if (typeof this.env?.event === typeof function() {}) {
        this.env.event('dragVertex', state, e, this);
      }
    }
  };

  onVertex = function(t, e) {
    this.chain?.onVertex.call(this, t, e);
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    if (typeof this.env?.event === typeof function() {}) {
      this.env.event('onVertex', t, e, this);
    }
  };

  toDisplayFeatures = function(state, geojson, display) {
    extDisplay(this, state, geojson, display);
  };
}
