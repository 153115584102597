/* eslint-disable max-len */
import { ElementRef } from '@angular/core';
import { BabylonFileLoaderConfiguration, double } from '@babylonjs/core';
import * as BABYLON from '@babylonjs/core';
import * as BABYLONGUI from '@babylonjs/gui';
import { WebMercator } from './webmercator';
import { circleOfConfusionPixelShader } from '@babylonjs/core/Shaders/circleOfConfusion.fragment';
import { BabyGeoScene } from './babygeoscene';
import { BabyContext } from './babycontext';

class BabyTileId {
  x: number;
  y: number;
  zoom: number;
  angle: number;

  constructor(x, y, zoom) {
    this.x = x;
    this.y = y;
    this.zoom = zoom;
    this.angle = this.getTileAngle(y, zoom);
  }

  private getTileAngle(tileY, tileZ) {
    const tileR = 2 ** tileZ;
    const tileS = (2 * Math.PI) / tileR;
    let y = Math.PI - tileY * tileS;

    if (2 * tileY < tileR) {
      // northern hemisphere, use bottom edge
      y -= tileS;
    }

    const gd = 2 * Math.atan(Math.exp(y)) - Math.PI / 2;
    return tileS * Math.cos(gd);
  }
}

class BabyTileContext {
  msLifeTime = 10 * 60 * 1000; // lifetime in milli-seconds
  maxMeshes = 200; // threshold of meshes triggering the discard of meshes exceeding msLifeTime
  minzoom: number;
  maxzoom: number;
  context: BabyContext;
  meshMap: Map<string, BabyTileMesh> = new Map<string, BabyTileMesh>([]);
  visibleMeshMap: Map<string, BabyTileMesh> = new Map<string, BabyTileMesh>([]);
  fullScreenUI: BABYLONGUI.AdvancedDynamicTexture;

  zoom: number;

  updateCount = 0;

  settled = 0;

  private vertexDataMap = {};

  constructor(
    context: BabyContext,
    minzoom: number,
    maxzoom: number,
    msLifeTime?: number,
    maxMeshes?: number
  ) {
    this.context = context;
    this.minzoom = minzoom;
    this.maxzoom = maxzoom;
    this.msLifeTime = msLifeTime ? msLifeTime : this.msLifeTime;
    this.maxMeshes = maxMeshes ? maxMeshes : this.maxMeshes;
  }

  getMesh(x, y, z): Promise<BabyTileMesh> {
    let mesh: BabyTileMesh = this.meshMap.get(BabyTileMesh.getName(x, y, z));
    if (!mesh) {
      mesh = new BabyTileMesh(this, new BabyTileId(x, y, z));
    }
    return mesh.load();
  }

  getVertexDataForTile(tileY, tileZ) {
    const key = tileY + ':' + tileZ;

    if (key in this.vertexDataMap) {
      // TODO develop a cleaner to remove outdated vextexData
      return this.vertexDataMap[key];
    }

    const tileR = 2 ** tileZ; // number of tiles on a 360° latitude
    const tileS = (2 * Math.PI) / tileR; // angular latitudinal section of a tile in radian
    const ty = Math.PI - tileY * tileS; // latitude + 180° of tile base

    //Set arrays for positions and indices
    const positions = [];
    const indices = [];
    const uvs = [];
    const subdivisions = 8;

    for (let sy = 0; sy <= subdivisions + 0.5; sy++) {
      const v = sy / subdivisions;
      const y = ty - tileS * (0.003 + v * 0.994);
      const gd = 2 * Math.atan(Math.exp(y)) - Math.PI / 2;
      const pz = Math.sin(gd);
      const cos = Math.cos(gd);

      for (let sx = 0; sx <= subdivisions + 0.5; sx++) {
        const u = sx / subdivisions;
        const x = tileS * (0.003 + u * 0.994);
        const px = Math.cos(x);
        const py = Math.sin(x);
        const p = new BABYLON.Vector3(px * cos, pz, py * cos).scale(this.context.dimension);
        positions.push(p.x);
        positions.push(p.y);
        positions.push(p.z);
        uvs.push(u);
        uvs.push(1 - v);

        if (sx < subdivisions && sy < subdivisions) {
          const idx = sy * (subdivisions + 1) + sx;
          indices.push(idx);
          indices.push(idx + subdivisions + 1);
          indices.push(idx + 1);
          indices.push(idx + 1);
          indices.push(idx + subdivisions + 1);
          indices.push(idx + subdivisions + 2);
        }
      }
    }

    //Create a vertexData object
    const vertexData = new BabyTileVertexData();

    //Assign positions and indices to vertexData
    vertexData.positions = positions;
    vertexData.indices = indices;
    vertexData.uvs = uvs;
    vertexData.normals = positions;

    this.vertexDataMap[key] = vertexData;
    return vertexData;
  }

  visibilityUpdate() {
    this.visibleMeshMap.forEach((mesh: BabyTileMesh) => {
      mesh.visibilityUpdate();
    });
  }
}

class BabyTileVertexData extends BABYLON.VertexData {}

enum LoadStatus {
  unkicked = 1,
  loading,
  loaded,
}

class BabyTileMesh extends BABYLON.Mesh {
  updated: Date;
  context: BabyTileContext;
  private tileId: BabyTileId;
  private loadStatus: LoadStatus = LoadStatus.unkicked;

  constructor(context: BabyTileContext, tile: BabyTileId) {
    super(BabyTileMesh.getName(tile.x, tile.y, tile.zoom), context.context.scene);
    this.loadStatus = LoadStatus.unkicked;
    this.tileId = tile;
    this.updated = new Date();
    this.context = context;
    this.isVisible = false;
    this.isPickable = false;
    context.meshMap.set(this.name, this);
  }
  static getName(x: number, y: number, z: number): string {
    return x + '-' + y + '-' + z;
  }
  getName() {
    return BabyTileMesh.getName(this.tileId.x, this.tileId.y, this.tileId.zoom);
  }

  load(): Promise<BabyTileMesh> {
    if (this.loadStatus === LoadStatus.loaded) {
      return new Promise<BabyTileMesh>((resolve, reject) => {
        this.updated = new Date();
        this.setVisible();
        resolve(this);
      });
    }

    const tileR = 2 ** this.tileId.zoom;
    const tileS = (2 * Math.PI) / tileR;
    const tx = this.tileId.x * tileS - Math.PI;
    const ty = Math.PI - this.tileId.y * tileS;

    const up = new BABYLON.Vector3(0, 1, 0);

    super.rotate(up, (-2 * Math.PI * this.tileId.x) / tileR + Math.PI);

    //Apply vertexData to custom mesh
    const vertexData = this.context.getVertexDataForTile(
      this.tileId.y,
      this.tileId.zoom
    );
    vertexData.applyToMesh(this);

    // Create material from tile imagery
    const mat = new BABYLON.StandardMaterial(
      'mat ' + this.getName(),
      this.context.context.scene
    );
    mat.wireframe = false;
    mat.backFaceCulling = true;
    mat.ambientColor = new BABYLON.Color3(1, 1, 1);
    mat.specularColor = new BABYLON.Color3(0.5, 0.5, 0.5);
    let quadkey = '';
    let tileZ = this.tileId.zoom;

    const scene = this.context.context.scene;

    while (tileZ > 0) {
      tileZ--;
      quadkey += '0123'[
        // eslint-disable-next-line no-bitwise
        ((this.tileId.y >> tileZ) & 1) * 2 + ((this.tileId.x >> tileZ) & 1)
      ];
    }
    const vearthTile =
      'https://t.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/' +
      quadkey +
      '?it=A&n=z';

    const osmTile =
      'https://tile.openstreetmap.org/' +
      this.tileId.zoom +
      '/' +
      this.tileId.x +
      '/' +
      this.tileId.y +
      '.png';

    let promise: Promise<BabyTileMesh>;
    if (false) {
      promise = new Promise<BabyTileMesh>((resolve, reject) => {
        const texture = new BABYLON.Texture(
          osmTile,
          this.context.context.scene,
          false,
          true,
          BABYLON.Texture.BILINEAR_SAMPLINGMODE,
          async () => {
            mat.diffuseTexture = texture;
            mat.diffuseTexture.wrapU = 0;
            mat.diffuseTexture.wrapV = 0;
            mat.freeze();
            super.material = mat;
            scene.addMesh(this);
            super.freezeWorldMatrix();
            super.freezeNormals();
            super.doNotSyncBoundingInfo = true;
            super.cullingStrategy =
              BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
            this.loadStatus = LoadStatus.loaded;
            this.setVisible();
            //console.log(osmTile);
            resolve(this);
          },
          () => {
            reject();
          }
        );
      });
    } else {
      promise = new Promise<BabyTileMesh>((resolve, reject) => {
        const image = new Image();
        image.setAttribute('crossorigin', 'anonymous');
        image.onerror = () => {
          reject();
        };
        image.onload = () => {
          const texture = new BABYLON.DynamicTexture(
            'dt' +
              BabyTileMesh.getName(
                this.tileId.x,
                this.tileId.y,
                this.tileId.zoom
              ),
            { width: image.width, height: image.height },
            this.context.context.scene
          );
          const ctx = texture.getContext();
          ctx.drawImage(image, 0, 0);
          /* texture.drawText(
            this.tileId.x + ',' + this.tileId.y,
            2,
            100,
            '100px fontana',
            'red',
            'transparent',
            false,
            true
          );*/
          texture.update();
          mat.diffuseTexture = texture;
          mat.diffuseTexture.wrapU = 0;
          mat.diffuseTexture.wrapV = 0;
          mat.freeze();
          super.material = mat;
          scene.addMesh(this);
          super.freezeWorldMatrix();
          super.freezeNormals();
          super.doNotSyncBoundingInfo = true;
          super.cullingStrategy =
            BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
          this.loadStatus = LoadStatus.loaded;
          this.setVisible();
          //console.log(osmTile);
          resolve(this);
        };
        image.src = osmTile;
      });
    }
    return promise;
  }

  clean() {
    if (
      !this.isVisible &&
      new Date().getTime() > this.updated.getTime() + this.context.msLifeTime &&
      this.context.meshMap.size > this.context.maxMeshes
    ) {
      this.context.meshMap.delete(this.name);
      this.context.context.scene.removeMesh(this);
      this.dispose();
    }
  }

  visibilityUpdate() {
    if (this.tileId.zoom !== this.context.zoom) {
      this.setInvisible();
    }
  }

  setVisible() {
    if (!this.isVisible) {
      this.isVisible = true;
      this.context.visibleMeshMap.set(this.name, this);
    }
    this.updated = new Date();
  }

  setInvisible() {
    if (this.isVisible) {
      this.isVisible = false;
      this.context.visibleMeshMap.delete(this.name);
      this.updated = new Date();
    }
  }
}

export class Babytiles {

  private geoScene: BabyGeoScene;

  private meshContext: BabyTileContext;

  private earth: BABYLON.Mesh;
  private sun: BABYLON.Mesh;

  private loadedTiles = [];
  private visibleTiles = [];

  constructor(
    geoScene: BabyGeoScene
  ) {
    this.geoScene = geoScene;
    this.meshContext = new BabyTileContext(geoScene.context, 3, 18, 10 * 60 * 1000, 200);
  }

  mod(n: number, m: number) {
    return ((n % m) + m) % m;
  }

  updateTiles2(count: number) {
    if (count !== -1 && count !== this.meshContext.updateCount) {
      return;
    }
    console.log('udate count : ' + count);

    // https://cp-algorithms.com/geometry/circle-line-intersection.html
    //    fov is camera field of view
    const cameraRadius: double = Math.abs(this.geoScene.camera.radius);
    const hfov: double = this.geoScene.camera.fov / 2;
    const r: double = this.meshContext.context.dimension; // sphereradius

    const theta = WebMercator.getSphereViewAngle(cameraRadius,hfov,r);
    const sAlpha = this.geoScene.camera.alpha;
    const sBeta = Math.PI / 2 - this.geoScene.camera.beta; // from +PI/2 to -PI/2 instead of 0 to PI
    // tiles zoom level to have 4 tiles in the fov
    const nbTilesHeigh = this.geoScene.engine.getRenderHeight() / 256;
    const zoom = Math.max(
      Math.min(WebMercator.getZoomLevel(sBeta, theta, 2)),
      this.meshContext.minzoom
    );
    this.meshContext.zoom = zoom;
    const tileS = Math.pow(2, zoom);
    const tile = WebMercator.getXYTile(sAlpha, sBeta, zoom);
    const xt = tile.x;
    const yt = tile.y;

    //console.log('d= '+this.camera.radius+' alpha= '+this.camera.alpha+
    //' beta= '+this.camera.beta+' ==> theta= '+theta/Math.PI*180 +'  x= '+xt+'  y= '+yt+'  z= '+zoom);
    //console.log('d= '+this.camera.radius+' alpha= '+this.camera.alpha+
    //' beta= '+this.camera.beta+' ==> theta= '+theta/Math.PI*180 +'  x= '+xt+'  y= '+yt+'  z= '+zoom);
    let vtiles = Math.min(Math.ceil((theta / Math.PI) * tileS), tileS / 2);
/*    console.log(
      'd= ' +
        this.camera.radius +
        ' fov= ' +
        Math.round((this.camera.fov / Math.PI) * 180 * 100) / 100,
      ' alpha= ' +
        (this.camera.alpha / Math.PI) * 180 +
        ' beta= ' +
        (this.camera.beta / Math.PI) * 180 +
        ' ==> theta= ' +
        (theta / Math.PI) * 180 +
        '  x= ' +
        xt +
        '  y= ' +
        yt +
        '  z= ' +
        zoom +
        '  v= ',
      vtiles
    );
*/    if (vtiles > 10) {
      vtiles = 10;
    }
    const htiles = Math.min(2 * vtiles, tileS / 2);
    const newMeshes = [];
    for (let x = xt - htiles; x < xt + htiles; x++) {
      const xi = this.mod(x, tileS);
      for (let y = yt - vtiles; y < yt + vtiles; y++) {
        const yi = this.mod(y, tileS);
        if (xi < 0 || xi >= tileS || yi < 0 || yi >= tileS) {
          console.log('ERROR : zoom= ' + zoom + '  xi= ' + xi + '  yi= ' + yi);
        }
        newMeshes.push(this.meshContext.getMesh(xi, yi, zoom));
      }
    }
    this.meshContext.settled++;
    Promise.race([
      Promise.allSettled(newMeshes).then((meshes) => {
        this.meshContext.settled--;
        this.meshContext.visibilityUpdate();
        this.geoScene.scene.createOrUpdateSelectionOctree();
      }),
      new Promise((resolve, reject) => {
        const id = setTimeout(() => {
          clearTimeout(id);
          this.meshContext.settled--;
          this.meshContext.visibilityUpdate();
          this.geoScene.scene.createOrUpdateSelectionOctree();
        }, 2000);
      }),
    ]);
  }

  setScene(): void {

    //    this.getMeshes(new BabyTileId(0, 0, 0));
    this.updateTiles2(this.meshContext.updateCount);
    let frameCount = 0;
    let refresh = false;
    let lastViewChange = Date.now();

    this.geoScene.scene.registerAfterRender(() => {
      frameCount++;
      if (frameCount % 30 === 0) {
        if (this.geoScene.viewHasChanged()) {
          lastViewChange = Date.now();
          refresh = true;
        } else if (lastViewChange < Date.now() + 2000 && refresh) {
          refresh = false;
          const count = ++this.meshContext.updateCount;
          this.updateTiles2(-1);
          /*        setTimeout(() => {
          this.updateTiles2(count);
        }, 1000);
*/
        }
        if (frameCount % 1000 === 0) {
          // TODO clean outdated meshes
        }
      }
    });
  }


}
