// https://www.npmjs.com/package/dexie
import Dexie, { Table } from 'dexie';
import { FilterSpecification, LayerSpecification, SourceSpecification, StyleSpecification } from 'maplibre-gl';
import { EntityURNStore } from 'src/app/watch-center/entities-watch-center';

const indexDBVersion = 3;
export enum RecordState {
    active = 1,
    inactive = 2,
    zombi = 4,
    delete = 8,
    archive = 16
}
const basicKeys = '&id, createdOn, updatedOn, state, tags';
interface RecordManagement {
    id?: string;
    createdOn?: Date;
    updatedOn?: Date;
    state?: RecordState;
    tags?: string[];
}

export interface IdbMapConfiguration extends RecordManagement {
    active?: boolean;
    description?: string;
    parent?: IdleDeadline;
    style?: string|StyleSpecification;
    position?: {
        lat: number;
        lon: number;
        zoom: number;
    };
    sources?: string[];
    layers?: string[];
};

export interface IdbMapSource extends RecordManagement {
    description?: string;
    specification?: SourceSpecification;
    filters?: {
        id?: string;
        description?: string;
        filter?: FilterSpecification;
    } [];
};

export interface IdbMapLayer extends RecordManagement {
    description?: string;
    groupId?: string;
    beforeId?: string;
    specification?: LayerSpecification & {source: string};
    defaults?: {
        filterId?: string;
        layoutId?: string;
        paintId?: string;
    };
};

export interface IdbMapStyle extends RecordManagement {
    description?: string;
    specification: StyleSpecification;
}

export interface IdbMapEntity extends RecordManagement {
    entity: EntityURNStore;
}

export interface IdbMapScopeProperties extends RecordManagement {
    view: string;
    level: number;
    active: boolean;
    value: string;
}

export class MapIndexDBWrap extends Dexie {
    mapConfigurations!: Dexie.Table<IdbMapConfiguration,number>;
    mapSources!: Dexie.Table<IdbMapSource,number>;
    mapLayers!: Dexie.Table<IdbMapLayer,number>;
    mapStyles!: Dexie.Table<IdbMapStyle,number>;
    mapEntities!: Dexie.Table<IdbMapEntity,number>;

    constructor() {
        super('net-maplibre') ;
        this.version(indexDBVersion).stores({
            mapConfigurations: basicKeys+', active',
            mapSources: basicKeys,
            mapLayers: basicKeys,
            mapStyles: basicKeys,
            mapEntities: basicKeys,
            mapScopeProperties: basicKeys
        });
    }

    putConfiguration(config: IdbMapConfiguration) {
        if(!config.createdOn) {
            config.createdOn = new Date();
        }
        config.updatedOn = new Date();
        this.mapConfigurations.put(config);
    };

    putSource(source: IdbMapSource) {
        this.mapSources.put(source);
    }

    putLayer(layer: IdbMapLayer) {
        this.mapLayers.put(layer);
    }

    putStyle(style: IdbMapStyle) {
        this.mapStyles.put(style);
    }

    putEntity(entity: IdbMapEntity) {
        this.mapEntities.put(entity);
    }

    put(table: string, record: RecordManagement) {
        return this[table].put(record);
    }

    getConfigurations(options?: {state?: RecordState}): Promise<IdbMapConfiguration[]> {
        const state = !options ? RecordState.active : options?.state;
        return this.mapConfigurations.where('state').equals(state).toArray();
    }

    getSources(options: {sources: string[]}): Promise<IdbMapSource[]> {
        return this.mapSources.where('id').anyOf(options.sources).toArray();
    }

    getLayers(options: {layers: string[]}): Promise<IdbMapLayer[]> {
        return this.mapLayers.where('id').anyOf(options.layers).toArray();
    }

    getStyles(options: {styles: string[]}): Promise<IdbMapStyle[]> {
        return this.mapStyles.where('id').anyOf(options.styles).toArray();
    }

    getEntities(options: {where: {entities?: string[]; startWith?: string}}): Promise<IdbMapEntity[]> {
        if (options.where.entities) {
            return this.mapEntities.where('id').anyOf(options.where.entities).toArray();
        }
        if (options.where.startWith) {
            return this.mapEntities.where('id').startsWith(options.where.startWith).toArray();
        }
    }

    get(table: string,options: {where: {ids?: string[]; startWith?: string}}): Promise<RecordManagement[]> {
        if (options.where.ids) {
            return this[table].where('id').anyOf(options.where.ids).toArray();
        }
        if (options.where.startWith) {
            return this[table].where('id').startsWith(options.where.startWith).toArray();
        }
    }

};
