import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MapPageRoutingModule } from './map-page-routing.module';
import { LeafletmapModule } from '../leafletmap/leafletmap.module';
import { WorldwindModule } from '../worldwind/worldwind.module';
import { MapPageComponent } from './map-page.component';
import { OpenlayerModule } from '../openlayer/openlayer.module';
import { SplitterModule } from 'primeng/splitter';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { IonicModule } from '@ionic/angular';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenubarModule } from 'primeng/menubar';
import { TreeModule } from 'primeng/tree';
import { FilePickerModule } from '../file-picker/file-picker.module';
import { WindowModule } from '../window/window.module';
import { EntitiesTreeModule } from '../watched-entities-tree/watched-entities-tree.module';
import { PanelModule } from 'primeng/panel';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MaplibreModule } from '../maplibre/maplibre.module';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {BadgeModule} from 'primeng/badge';
import { ATooltipModule } from '../a-tooltip/a-tooltip.module';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [MapPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    SplitterModule,
    MapPageRoutingModule,
    LeafletmapModule,
    WorldwindModule,
    EntitiesTreeModule,
    OpenlayerModule,
    FilePickerModule,
    WindowModule,
    SplitterModule,
    PanelModule,
    BadgeModule,
    MatSidenavModule,
    TreeModule,
    MenubarModule,
    ContextMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatSelectModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatButtonToggleModule,
    MaplibreModule,
    FontAwesomeModule,
    ATooltipModule,
    IonicModule.forRoot(),
    // CesiumModule
  ],
})
export class MapPageModule {}
