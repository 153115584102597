
<canvas height="50" width="100" id="scene" #scene cdkOverlayOrigin #originOverlay="cdkOverlayOrigin"></canvas>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="originOverlay" 
  [cdkConnectedOverlayOffsetX]="overlay.offsetX",
  [cdkConnectedOverlayOffsetY]="overlay.offsetY",
  [cdkConnectedOverlayPositions]="overlay.positions",
  [cdkConnectedOverlayOpen]="overlay.isOpen"
>
  <a>{{overlay.text}}</a>

</ng-template>

