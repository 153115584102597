/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ObjectEditor } from './object-editor';
import { ObjectEditorContext } from './st-editor-int';

@Component({
  selector: 'app-object-editor',
  templateUrl: './object-editor.component.html',
  styleUrls: ['./object-editor.component.scss'],
})
export class ObjectEditorComponent implements OnInit {
  @ViewChild('objectcontainer')
  private objectContainer!: ElementRef<HTMLElement>;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  context: ObjectEditorContext;

  private _sel: string;

  properties: string[];
  typeoptions: string[];
  newobject = {
    property: '',
    type: undefined
  };

  editing: string;

  constructor() {}

  hasListSel(): boolean {
    return this.getListSel().length > 0;
  }

  getListSel(): string[] {
    if(!this.context.list) {
      return [];
    }
    const isel = Object.getOwnPropertyNames(this.context.list);
    const rsel = [];
    isel.forEach((s)=> {
      if(!this.context.value[s]) {
        rsel.push(s);
      }
    });
    return rsel;
  }

  set sel(s: string) {
    if(this.context.list[s]) {
      this._sel = s;
      this.newobject.property = s;
      this.newobject.type = this.context.list[s].scheme.type;
    }
  }

  get sel() {
    return this._sel;
  }

  isediting(p: string) {
    return p === this.editing;
  }

  edittoggle(p: string) {
    if (this.editing === p) {
      this.editing = undefined;
      this.callback(p);
    } else {
      this.editing = p;
    }
  }

  setProperties(): void {
    this.properties = [];
    Object.getOwnPropertyNames(this.context.value).filter((e) => {
      if (e !== '_scheme') {
        this.properties.push(e);
      }
    });
  }

  new() {
    if (this.context.value === undefined) {
      this.context.value = {};
    }
    if (this.context.scheme === undefined) {
      this.context.scheme = {};
    }
    if (
      this.newobject.property &&
      this.newobject.property !== '' &&
      this.context.value[this.newobject.property] === undefined &&
      ObjectEditor.isScheme(this.newobject.type)
    ) {
      this.context.value[this.newobject.property] = null;
      this.context.scheme[this.newobject.property] = {
        ...ObjectEditor.getScheme(this.newobject.type),
      };
      this.editing = this.newobject.property;
      this.setProperties();
      this.newobject = {
        property: '',
        type: undefined
      };
      this.callback(this.editing);
    }
  }

  delete(p: string) {
    delete this.context.value[p];
    delete this.context.scheme[p];
    this.setProperties();
    if (this.context.callback) {
      this.context.callback(this.context, p);
    }
  }

  ngOnInit() {
    this.setProperties();
    this.typeoptions = ObjectEditor.getSchemes();
    this.context.update = (context) => {
      //this.ref.detectChanges();
      //this.reloadComponent();
      this.context = context;
      this.setProperties();
    };
  }

  private callback(p: string) {
    if (this.context.callback) {
      this.context.value[p] = ObjectEditor.convert(
        this.context.value[p],
        this.context.scheme[p]
      );
      this.context.callback(this.context, p);
    }
  }
}
