/* eslint-disable @typescript-eslint/naming-convention */
export class Logger {

    static LEVEL_SEVERE = 1;
    static LEVEL_WARNING = 2;

    static log(LEVEL_WARNING: any, arg1: string) {
        throw new Error('Method not implemented.');
    }

    static logMessage(severity: any, arg1: string, arg2: string, arg3: string): any {
        throw new Error('Method not implemented.');
    }
}
