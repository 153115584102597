/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { MenuItem, TreeNode } from 'primeng/api';
import { EntitiesWatcher } from '../watch-center/entities-watcher';
import { EntitiesWatchCenter } from '../watch-center/entities-watch-center';
import { IMenuItem } from './IMenuItem';
import { JSEeval } from '../jse-eval/JSE-eval';
import { ObjectEditorContext } from '../object-editor/st-editor-int';

interface TreeNodeF extends TreeNode {
  visible: boolean;
  show: { [key: string]: boolean };
  showTags: boolean;
  showStyle: boolean;
  isRenaming: boolean;
}

@Component({
  selector: 'app-watched-entities-tree',
  templateUrl: './watched-entities-tree.component.html',
  styleUrls: ['./watched-entities-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WatchedEntitiesTreeComponent implements OnInit, OnChanges {
  @Input() hideTree = false;

  _watchCenter: EntitiesWatchCenter;
  @Input()
  set watchCenter(wc: EntitiesWatchCenter) {
    this._watchCenter = wc;
    if (wc) {
      this.init();
    }
  }
  get watchCenter() {
    return this._watchCenter;
  }
  @Input() properties: { [key: string]: ObjectEditorContext };
  @Input() icons: string[];
  @Input() header = '';
  @Input() footer = '';
  @Input() stpSize;
  @Input() datastore;
  @Input() getContextMenu: (data: any) => IMenuItem[];

  contextMenuItems: MenuItem[] = [];

  entitiesTree: TreeNodeF[] = [];

  nodeFilterRefreshNeeded = false;

  renameLabel = '';

  _nodeFilterFlag = false;
  get nodeFilterFlag() {
    // eslint-disable-next-line no-underscore-dangle
    return this._nodeFilterFlag;
  }
  set nodeFilterFlag(v: boolean) {
    // eslint-disable-next-line no-underscore-dangle
    this._nodeFilterFlag = v;
    this.nodeFilterRefresh();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  _nodeFilterValue: string;
  get nodeFilterValue(): string {
    // eslint-disable-next-line no-underscore-dangle
    return this._nodeFilterValue;
  }

  set nodeFilterValue(v: string) {
    // eslint-disable-next-line no-underscore-dangle
    this._nodeFilterValue = v;
    this._nodeFilterFlag = v.length !== 0 || this._nodeFilterSel;
    this.nodeFilterRefresh();
  }

  _nodeFilterSel = false;
  get nodeFilterSel() {
    return this._nodeFilterSel;
  }

  _nodeFilterUnsel = false;
  get nodeFilterUnsel() {
    return this._nodeFilterUnsel;
  }

  _nodeFilterHighlight = false;
  get nodeFilterHighlight() {
    return this._nodeFilterHighlight;
  }

  nodeFilterRefresh() {
    this.nodeFilterRefreshNeeded = false;
    this.entitiesTree.forEach((node) =>
      this.nodeDisplayFilter(node, this.nodeFilterValue)
    );
  }

  nodeFilterSelToggle() {
    this._nodeFilterSel = !this._nodeFilterSel;
    if (this._nodeFilterSel) {
      this._nodeFilterUnsel = false;
    }
    this._nodeFilterFlag ||= this._nodeFilterSel;
    this.nodeFilterRefresh();
  }

  nodeFilterUnselToggle() {
    this._nodeFilterUnsel = !this._nodeFilterUnsel;
    if (this._nodeFilterUnsel) {
      this._nodeFilterSel = false;
    }
    this._nodeFilterFlag ||= this._nodeFilterUnsel;
    this.nodeFilterRefresh();
  }

  nodeFilterHighlightToggle() {
    this._nodeFilterHighlight = !this._nodeFilterHighlight;
    this._nodeFilterFlag ||= this._nodeFilterHighlight;
    this.nodeFilterRefresh();
  }

  nodeFilterToggle() {
    this.nodeFilterFlag = !this.nodeFilterFlag;
    this.nodeFilterRefresh();
  }

  isFiltering(): boolean {
    return this.nodeFilterFlag;
  }

  getContext(node, property) {
    const value = this.getNodeProperty(node, property);
    const scheme = this.getNodePropertyScheme(node, property);
    return {
      component: this,
      node,
      property,
      value,
      scheme,
      callback: this.updatecallback,
      update: (context) => {},
    };
  }

  getProperties(): string[] {
    return Object.getOwnPropertyNames(this.properties);
  }

  updatecallback(context, p: string) {
    const properties: { [k: string]: any } = {};
    properties[context.property] = context.value;
    context.component.watchCenter.update(context.node.data, properties);
  }

  filteredEntitiesTree: TreeNodeF[] = [];

  selectedEntities: TreeNodeF[] = [];

  selectedEntities2: TreeNodeF[] = [];

  dynId: number = Math.round(Math.random() * 10000);

  private geoEntitiesObserver: EntitiesWatcher;

  private nodeMap: Map<any, TreeNodeF> = new Map<any, TreeNodeF>();

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    const i = 0; // TODO
  }

  contextMenuId(): string {
    return 'contextMenu' + this.dynId;
  }
  nodeSelection(node) {
    if (node.data == null) {
      if (this.selectedEntities.some((e) => e === node)) {
        this.lnodeUnselect(node);
        this.selectedEntities = this.selectedEntities.filter((e) => e !== node);
      } else {
        this.lnodeSelect(node);
        this.selectedEntities.push(node);
      }
    } else if (
      this.watchCenter.getProperties(node.data, ['selected']).selected
    ) {
      this.lnodeUnselect(node);
    } else {
      this.lnodeSelect(node);
    }
  }
  nodeHover(node, h: boolean) {
    this.watchCenter.broadcast(node.data, { hover: h });
  }
  nodeClick(node) {
    if (node?.data) {
      this.watchCenter.command(node.data, {
        zoomTo: node.data,
      });
    }
  }
  nodeHighlightToggle(node) {
    let h = this.watchCenter.getProperties(node.data, ['highlight']).highlight;
    h = !(h ?? false);
    this.watchCenter.update(node.data, { highlight: h });
  }
  private regexp = new RegExp(
    '([a-zA-Z0-9_]+)(?:.([a-zA-Z0-9_]+))?(?:.([a-zA-Z0-9_]+))?(?:.([a-zA-Z0-9_]+))?(?:=([a-zA-Z0-9_-]+))?'
  );
  private nodeFilterTest(node, filtervalue) {
    const labeltest =
      filtervalue?.length > 0
        ? node.label.toLowerCase().includes(filtervalue.toLowerCase())
        : true;
    let filtertest;
    const filter1 = filtervalue.split('=');
    // filter1a array of properties separated by a dot on the left end of the = separator
    const filter1a = filter1?.length > 0 ? filter1[0].split('.') : [undefined];
    // filter1b value on the right end of the = separator
    let filter1b = '';
    for (let i = 1; i < filter1.length; i++) {
      filter1b += filter1[i];
    }
    if (filter1a[0]) {
      const properties = this._watchCenter.getProperties(node.data, [
        filter1a[0],
      ]);
      let subproperty = properties[filter1a[0]];
      for (let i = 1; i < filter1a.length && subproperty; i++) {
        subproperty = subproperty[filter1a[i]];
      }
      filtertest =
        filter1b?.length > 0 && subproperty
          ? subproperty.toLowerCase().includes(filter1b.toLowerCase())
          : subproperty !== undefined;
    }
    return labeltest || filtertest;
  }
  private _nodeFilterTest(node, filtervalue: string, jseEval: JSEeval) {
    const labeltest =
      filtervalue?.length > 0
        ? node.label.toLowerCase().includes(filtervalue.toLowerCase())
        : true;
    const filtertest = jseEval.evaluate(
      this._watchCenter.getProperties(node.data)
    );
    return labeltest || filtertest;
  }
  private _nodeDisplayFilter(
    node: TreeNodeF,
    filtervalue: string,
    jseEval: JSEeval
  ) {
    let displayedChildren = false;
    node.children?.forEach((child) => {
      const dc = this._nodeDisplayFilter(
        child as TreeNodeF,
        filtervalue,
        jseEval
      );
      displayedChildren ||= dc;
    });
    if (
      this.nodeFilterFlag &&
      //      ((this.nodeFilterValue?.length > 0 ? !node.label.toLowerCase().includes(this.nodeFilterValue.toLowerCase()) : false) ||
      (!this._nodeFilterTest(node, this.nodeFilterValue, jseEval) ||
        (this._nodeFilterSel ? !node.visible : false) ||
        (this._nodeFilterUnsel ? node.visible : false) ||
        (this._nodeFilterHighlight ? !this.isHighlighted(node) : false)) &&
      !displayedChildren
    ) {
      node.style = 'display: none';
      return false;
    } else {
      node.style = null;
      if (displayedChildren) {
        node.expanded = true;
      }
      return true;
    }
  }
  nodeDisplayFilter(node: TreeNodeF, filterValue) {
    const jseEval = new JSEeval(filterValue);
    return this._nodeDisplayFilter(node, filterValue, jseEval);
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  nodeFilter_sav(node: TreeNodeF) {
    let displayedChildren = false;
    node.children?.forEach((child) => {
      const dc = this.nodeFilter_sav(child as TreeNodeF);
      displayedChildren ||= dc;
    });
    if (
      this.nodeFilterFlag &&
      //      ((this.nodeFilterValue?.length > 0 ? !node.label.toLowerCase().includes(this.nodeFilterValue.toLowerCase()) : false) ||
      (!this.nodeFilterTest(node, this.nodeFilterValue) ||
        (this._nodeFilterSel ? !node.visible : false) ||
        (this._nodeFilterUnsel ? node.visible : false) ||
        (this._nodeFilterHighlight ? !this.isHighlighted(node) : false)) &&
      !displayedChildren
    ) {
      node.style = 'display: none';
      return false;
    } else {
      node.style = null;
      if (displayedChildren) {
        node.expanded = true;
      }
      return true;
    }
  }
  /*  isNodeHidden(node: TreeNodeF) {
    const x = true;
    return node?.label?.includes('sea');
  }
*/ isSelected(node): boolean {
    if (node.data == null) {
      return this.selectedEntities.some((e) => e === node);
    } else {
      return this.watchCenter.getProperties(node.data, ['selected']).selected;
    }
  }
  isPUnselected(node): boolean {
    if (node.data == null) {
      return true;
    } else {
      return !(this.watchCenter.getProperties(node.data, ['pselected']).pselected ?? true);
    }
  }
  isHighlighted(node): boolean {
    if (node.data != null) {
      return this.watchCenter.getProperties(node.data, ['highlight']).highlight;
    }
  }
  getNodeProperty(node: TreeNodeF, propertyName: string): object {
    let property = this._watchCenter.getProperties(node.data, [propertyName])[
      propertyName
    ];
    if (!property) {
      property = { _scheme: new Object() };
      const properties: { [key: string]: any } = {};
      properties[propertyName] = property;
      this._watchCenter.update(node.data, properties);
    }
    return property;
  }
  getNodePropertyScheme(node: TreeNodeF, propertyName: string): object {
    return (this.getNodeProperty(node, propertyName) as { [key: string]: any })
      ._scheme;
  }
  toggleShowNodeProperty(node: TreeNodeF, property: string) {
    node.show[property] = !node.show[property];
    Object.getOwnPropertyNames(this.properties).forEach((p) => {
      if (p !== property) {
        node.show[p] = false;
      }
    });
  }
  isShowingNodeProperty(node: TreeNodeF, propertyName: string): boolean {
    return node.show[propertyName];
  }

  renamingKeyUp(event, node) {
    if (event.keyCode === 13) {
      node.isRenaming = false;
      node.label = this.renameLabel;
      this.watchCenter.update(node.data, { name: node.label });
    } else if (event.keyCode === 27) {
      node.isRenaming = false;
    }
  }

  menuSelect(event) {
    this.contextMenuItems = [
      {
        label: 'rename',
        command: (e) => {
          this.renameLabel = event.node.label;
          event.node.isRenaming = true;
        },
      },
    ];
    if (this.getContextMenu) {
      this.contextMenuItems.push({ separator: true });
      this.contextMenuItems.push(...this.getContextMenu(event.node.data));
    }
    /*
    [
      {
        label: 'Center To',
        icon: 'fa fa-magnifying-glass',
        command: (e) => {
          if (event.node?.data) {
            this.nodeClick(event.node);
          }
        },
      },
      {
        label: 'File',
        items: [
          {
            label: 'New',
            icon: 'pi pi-fw pi-plus',
            items: [{ label: 'Project' }, { label: 'Other' }],
          },
          { label: 'Open' },
          { label: 'Quit' },
        ],
      },
      {
        label: 'Edit',
        icon: 'pi pi-fw pi-pencil',
        items: [
          { label: 'Delete', icon: 'pi pi-fw pi-trash' },
          { label: 'Refresh', icon: 'pi pi-fw pi-refresh' },
        ],
      },
    ];
    */
  }
  nodeSelect(event) {
    // select / unselect is only with the left mouse button
    // right click is for the context menu,
    if (event.originalEvent.button === 0) {
      this.lnodeSelect(event.node);
    }
    // the following code is only to get around primeng treenode selection on right click
    /*    else if(this.geoEntitiesService.isSelected(event.node.data)) {
      if(!this.geoSelectedEntities.some(e => e === event.node)) {
        this.geoSelectedEntities.push(event.node);
        console.log('select');
      }
    }
    else {
      console.log('unselect');
      this.geoSelectedEntities = this.geoSelectedEntities.filter(e => e !== event.node);
    }
  */
  }

  nodeUnselect(event) {
    // select / unselect is only with the left mouse button
    // right click is for the context menu,
    if (event.originalEvent.button === 0) {
      this.lnodeUnselect(event.node);
    }
    // the following code is only to get around primeng treenode selection on right click
    /*    else if(this.geoEntitiesService.isSelected(event.node.data)) {
      if(!this.geoSelectedEntities.some(e => e === event.node)) {
        this.geoSelectedEntities.push(event.node);
        console.log('select');
      }
    }
    else {
      console.log('unselect');
      this.geoSelectedEntities = this.geoSelectedEntities.filter(e => e !== event.node);
    }
  */
  }

  private init(): void {
    if (!this.watchCenter) {
      return;
    }

    const cthis = this;

    new (class extends EntitiesWatcher {
      add(
        entity: any,
        properties: { [key: string]: any },
        position: { [key: string]: any }
      ) {
        cthis.add(entity, properties, position);
      }
      update(x: any, properties: { [key: string]: any }) {
        // TODO manage the stacked transient properties
        const node: TreeNodeF[] | null = cthis.getNode(
          cthis.entitiesTree,
          x,
          null
        );
        if (properties.selected != null && node) {
          if (properties.selected) {
            cthis.selectedEntities.push(node[0]);
          } else {
            cthis.selectedEntities = cthis.selectedEntities.filter(
              (lnode, index, ltree) => lnode !== node[0]
            );
          }
          node[0].visible = properties.selected;
          if (cthis._nodeFilterFlag && cthis._nodeFilterSel) {
            cthis.nodeFilterRefreshNeeded = true;
          }
          //            cthis.nodeFilter(node[0]);
        }
        if (properties.name && node) {
          node[0].label = properties.name;
        }
      }
      stUpdate(i: any, propertyNames: [key: string]) {
        // TODO manage the stacked transient properties
      }
      move(
        entity: any,
        position: { [key: string]: any; container?: any; before?: any }
      ) {
        // TODO implement entity move position
      }
      command(i: any, o: { [key: string]: any }) {}
      broadcast(i: any, o: { [key: string]: any }) {}
      remove(x) {
        const node: TreeNodeF[] | null = cthis.getNode(
          cthis.entitiesTree,
          x,
          null
        );
        if (node != null) {
          cthis.selectedEntities = cthis.selectedEntities.filter(
            (lnode, index, ltree) => lnode.data !== x
          );
        }
        if (node != null) {
          node[1].children = node[1].children?.filter(
            (lnode, index, ltree) => lnode.data !== x
          );
        }
      }

      removeAll() {
        cthis.entitiesTree = [];

        cthis.selectedEntities = [];

        cthis.selectedEntities2 = [];
      }
    })(this.watchCenter);
  }

  ngOnInit(): void {
    //this.init();
  }

  private add(
    entity: any,
    properties?: { [key: string]: any },
    position?: { [key: string]: any }
  ) {
    const parentNode = this.nodeMap.get(position?.container);
    const treeNode: TreeNodeF & { visible: boolean } = {
      label: properties.name,
      data: entity,
      icon: 'pi cloud',
      expandedIcon: 'pi pi-folder-open',
      collapsedIcon: 'pi pi-folder',
      //styleClass: 'geo-node',
      expanded: false,
      draggable: true,
      droppable: true,
      selectable: true,
      parent: parentNode,
      visible: true,
      showTags: false,
      showStyle: false,
      isRenaming: false,
      show: {},
      children: [],
      /*    label?: string;
              data?: any;
              icon?: any;
              expandedIcon?: any;
              collapsedIcon?: any;
              children?: TreeNode[];
              leaf?: boolean;
              expanded?: boolean;
              type?: string;
              parent?: TreeNode;
              partialSelected?: boolean;
              styleClass?: string;
              draggable?: boolean;
              droppable?: boolean;
              selectable?: boolean;
              key?: string;
        */
    };
    if (parentNode) {
      parentNode.children.push(treeNode);
    } else {
      this.entitiesTree.push(treeNode);
    }
    this.nodeMap.set(entity, treeNode);
  }

  private lnodeSelect(node: TreeNodeF) {
    if (!node.data) {
      this.selectedEntities.push(node);
      node.visible = true;
      if (this._nodeFilterFlag && this._nodeFilterSel) {
        this.nodeFilterRefreshNeeded = true;
      }
      //      this.nodeFilter(node);
    }
    //    const ppselected = this.watchCenter.getProperties(node?.data,['pselected']).pselected ?? true;
    this.watchCenter.traverseTree(node.data, (cdata, pdata) => {
      if (cdata === node.data) {
        this.watchCenter.update(cdata, { selected: true });
      } else {
        const lppselected =
          (this.watchCenter.getProperties(pdata, ['pselected']).pselected ??
            true) &&
          (this.watchCenter.getProperties(pdata, ['selected']).selected ??
            true);
        this.watchCenter.update(cdata, { pselected: lppselected ?? true });
      }
      return true;
    });
    /*    node.children?.forEach((element) => {
      this.lnodeSelect(element);
    });
  */
  }

  private lnodeUnselect(node: TreeNodeF) {
    if (!node.data) {
      this.selectedEntities = this.selectedEntities.filter(
        (value, index, arr) => value !== node
      );
      node.visible = false;
      if (this._nodeFilterFlag && this._nodeFilterSel) {
        this.nodeFilterRefreshNeeded = true;
      }
      //      this.nodeFilter(node);
    }
    this.watchCenter.traverseTree(node.data, (cdata) => {
      if (cdata === node.data) {
        this.watchCenter.update(cdata, { selected: false });
      } else {
        this.watchCenter.update(cdata, { pselected: false });
      }
      return true;
    });
    /*    node.children?.forEach((element) => {
      this.lnodeUnselect(element);
    });
  */
  }

  // returns tree node holding data and parent node
  private getNode(
    nodes: TreeNodeF[],
    data,
    pnode: TreeNodeF | null
  ): TreeNodeF[] | null {
    let rnode: (TreeNodeF | null)[] | null = null;
    nodes.some((node) => {
      if (node.data === data) {
        rnode = [node, pnode];
      } else if (node.children) {
        rnode = this.getNode(node.children as TreeNodeF[], data, node);
      }
      return rnode != null;
    });
    return rnode;
  }
}
