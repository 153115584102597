import { ElementRef, Injectable, NgZone } from '@angular/core';
import * as BABYLON from '@babylonjs/core';
import { BabyGeoJSON } from './babygeojson';
import { BabyGeoScene } from './babygeoscene';
import { Babytiles } from './babytiles';
import { WindowService } from './window-service/window.service';
import { SceneTest1 } from '../../babytest/babytestlines';
import { Babylines } from './babylines';
import { BabyUI } from './babyUI';
import { BabyContext } from './babycontext';
import { Vector3 } from '@babylonjs/core';

@Injectable({ providedIn: 'root' })
export class BabymapService {

  private camera: BABYLON.FreeCamera;
  private light: BABYLON.Light;

  private sphere: BABYLON.Mesh;
  private sun: BABYLON.Mesh;
  private geoScene: BabyGeoScene;
  private babyUI: BabyUI;
  private context: BabyContext;


  public constructor(
    private ngZone: NgZone,
    private windowRef: WindowService
  ) {}

  public createScene(canvas: ElementRef<HTMLCanvasElement>): void {

    this.context = new BabyContext(canvas.nativeElement,100);

    if(false) {
      (this.babyUI = new BabyUI(this.context)).setScene(null);
      new SceneTest1(this.context).setScene();
      new Babylines(this.context).setScene();
    }
    else {

    this.geoScene = new BabyGeoScene(this.context);

    this.geoScene.setScene();

    new Babytiles(this.geoScene).setScene();

    new BabyUI(this.context).setScene(this.geoScene.sphere);

    new Babylines(this.context).setScene();

    new BabyGeoJSON(this.context,'/assets/geomap/cable-geo.json',new Vector3(0,0,0),102,1,'EPSG:3857');

    new BabyGeoJSON(this.context,'/assets/geomap/world-administrative-boundaries.geojson',new Vector3(0,0,0),101,1,'EPSG:3857');

    //new BabyGeoJSON().parse('/assets/geomap/cable-geo.json');

    // generates the world x-y-z axis for better understanding
//    this.geoScene.showWorldAxis(1.2);
    }
}


  public animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      const rendererLoopCallback = () => {
        this.context.scene.render();
      };

      if (this.windowRef.document.readyState !== 'loading') {
        this.context.engine.runRenderLoop(rendererLoopCallback);
      } else {
        this.windowRef.window.addEventListener('DOMContentLoaded', () => {
          this.context.engine.runRenderLoop(rendererLoopCallback);
        });
      }

      this.windowRef.window.addEventListener('resize', () => {
        this.context.engine.resize();
      });
    });
  }

}
