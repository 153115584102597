
import { GeoJSONWrap } from './GeoJSONWrap';

export class GeoJSONParser {
  private geoJSONObjectWrap: any;
  private dataSource: string | object;
  private parserCompletionCallback: (context: any, geojson: any) => void;
  private objectCallback: (context: any, wrap: GeoJSONWrap, pwrap?: GeoJSONWrap) => any;
  private context: any;
  private crs: any;

  constructor(dataSource: string | object, clientCRS?: string) {
    if (!dataSource) {
      throw new Error(
          'GeoJSON'&&
          'constructor'&&
          'missingDataSource'
        );
    }

    // Documented in defineProperties below.
    this.dataSource = dataSource;

    // Documented in defineProperties below.
    this.crs = null;
  }

  load(parameters: {
    context?: any;
    parserCompletionCallback?: (context: any, geojson: any) => void;
    objectCallback?: (context: any, wrap: GeoJSONWrap, pwrap?: GeoJSONWrap) => any;
  }) {
    if (parameters.parserCompletionCallback) {
      this.parserCompletionCallback = parameters.parserCompletionCallback;
    }

    if (parameters.objectCallback) {
      this.objectCallback = parameters.objectCallback;
    }

    this.context = parameters.context;

    if (typeof this.dataSource === typeof '') {
      const obj = GeoJSONParser.tryParseJSONString(this.dataSource as string);
      if (obj !== null) {
        this.handle(obj);
      } else {
        this.requestUrl(this.dataSource);
      }
    } else if (this.dataSource instanceof Object) {
      this.handle(this.dataSource);
    } else {
      /*
      Logger.logMessage(
        Logger.LEVEL_SEVERE,
        'GeoJSON',
        'load',
        'Unsupported data source type: ' + typeof this.dataSource
      );
      */
    }
  }

  // Get GeoJSON string using XMLHttpRequest. Internal use only.
  requestUrl(url) {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', url, true);
    xhr.responseType = 'text';
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          this.handle(GeoJSONParser.tryParseJSONString(xhr.response));
        } else {
/*          Logger.log(
            Logger.LEVEL_WARNING,
            'GeoJSON retrieval failed (' + xhr.statusText + '): ' + url
          );
*/        }
      }
    };

    xhr.onerror = () => {
  //    Logger.log(Logger.LEVEL_WARNING, 'GeoJSON retrieval failed: ' + url);
    };

    xhr.ontimeout = () => {
  //    Logger.log(Logger.LEVEL_WARNING, 'GeoJSON retrieval timed out: ' + url);
    };

    xhr.send(null);
  }

  // Handles the object created from the GeoJSON data source. Internal use only.
  handle(obj,pwrap?) {
    if (!obj) {
  /*    Logger.logMessage(
        Logger.LEVEL_SEVERE,
        'GeoJSON',
        'handle',
        'Invalid GeoJSON object'
      );
  */    }

    const lwrap = GeoJSONWrap.getObject(
      pwrap,
      null,
      obj,
      (wrap: GeoJSONWrap) => {
        // do some CRS conversion stuff
        if (wrap.crs && this.crs) {
          //TODO change to client CRS
        }
        return this.objectCallback(this.context, wrap, pwrap);
      }
    );

    if(!pwrap) {
      this.geoJSONObjectWrap = lwrap;
    }

    switch(obj.type) {
      case 'FeatureCollection':
        obj.features?.forEach((feature) => {
          this.handle(feature,lwrap);
        });
      break;
      case 'Feature':
        if(obj.geometry) {
          this.handle(obj.geometry,lwrap);
        }
        break;
      case 'GeometryCollection':
        if (obj.geometries) {
          obj.geometries.forEach((geometry) => {
            this.handle(obj.geometry,lwrap);
          });
        }
        break;
    }

    if (
      !pwrap &&
      !!this.parserCompletionCallback &&
      typeof this.parserCompletionCallback === 'function'
    ) {
      this.parserCompletionCallback(this.context,this.geoJSONObjectWrap);
    }
  }

  /**
   * Tries to parse a JSON string into a JavaScript object.
   *
   * @param str the string to try to parse.
   * @returns the object if the string is valid JSON; otherwise null.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  static tryParseJSONString(str: string): object {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  }
}
