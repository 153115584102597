/* eslint-disable @typescript-eslint/member-ordering */
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-selected-files-table',
  templateUrl: './selected-files-table.component.html',
  styleUrls: ['./selected-files-table.component.scss']
})
export class SelectedFilesTableComponent implements OnInit, AfterViewInit {
  _displayedColumns = ['name', 'type', 'size', 'lastModified'];

  @Input()
  files: File[] = [];

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
  }


}
