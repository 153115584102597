<p-accordion
  styleClass="watched-entities-accordion"
  [multiple]="true"
  [activeIndex]="[1]"
>
  <p-accordionTab *ngIf="stpSize > 0" header="Conditional Properties">
    <app-st-properties-stack
      [watchCenter]="watchCenter"
      [datastore]="datastore"
      [properties]="properties"
      [stpSize]="stpSize"
    ></app-st-properties-stack>
  </p-accordionTab>
  <p-accordionTab [header]="header">
    <fieldset style="border: 1px solid lightblue; padding: 20px">
      <label>filter</label>
      <div class="p-inputgroup" styleClass="watched-entities-tree">
        <span class="p-inputgroup-addon">
          <i
            class="pi pi-refresh"
            (click)="nodeFilterRefresh()"
            [hidden]="!nodeFilterRefreshNeeded"
            style="color: red"
          ></i>
          <span
            style="
              background-color: white;
              vertical-align: top;
              outline: solid 1px lightblue;
            "
          >
            <i
              class="pi pi-eye"
              (click)="nodeFilterSelToggle()"
              style="position: relative; display: block"
              [style.color]="nodeFilterSel ? 'blue' : 'darkgrey'"
            ></i>
            <i
              class="pi pi-eye-slash"
              (click)="nodeFilterUnselToggle()"
              style="position: relative; display: block"
              [style.color]="nodeFilterUnsel ? 'blue' : 'darkgrey'"
            ></i>
          </span>
          <i
            class="pi pi-sun"
            (click)="nodeFilterHighlightToggle()"
            [style.color]="nodeFilterHighlight ? 'blue' : 'darkgrey'"
          ></i>
          <input
            type="text"
            pInputText
            [(ngModel)]="nodeFilterValue"
            placeholder="Search"
            style="font-size: 0.7rem"
          />
        </span>
      </div>
    </fieldset>
    <p-tree
      [value]="entitiesTree"
      scrollHeight="500px"
      styleClass="watched-entities-tree"
      [hidden]="hideTree"
      [propagateSelectionUp]="false"
      [propagateSelectionDown]="false"
      [selection]="selectedEntities2"
      [contextMenu]="contextMenu"
      (onNodeContextMenuSelect)="menuSelect($event)"
    >
      <ng-template pTemplate="header">{{ header }}</ng-template>
      <ng-template let-node pTemplate="default">
        <input
          type="checkbox"
          (click)="nodeSelection(node)"
          [checked]="isSelected(node)"
          [ngStyle]="{
            'vertical-align': 'middle',
            'accent-color': isPUnselected(node) ? 'lightgrey' : 'inherit'
          }"
        />
        <i
          *ngFor="let p of getProperties(); let index = index"
          class="{{ icons[index] }}"
          [style.color]="isShowingNodeProperty(node, p) ? 'green' : 'inherit'"
          (click)="toggleShowNodeProperty(node, p)"
        ></i>
        <label
          *ngIf="!node.isRenaming; else renaming"
          style="padding-left: 10px"
          [style.font-weight]="isHighlighted(node) ? 'bold' : 'normal'"
          (mouseover)="nodeHover(node, true)"
          (mouseleave)="nodeHover(node, false)"
          (click)="nodeClick(node); nodeHighlightToggle(node)"
        >
          {{ node.label }}</label
        >
        <ng-template #renaming>
          <input
            type="string"
            [(ngModel)]="renameLabel"
            (keyup)="renamingKeyUp($event, node)"
          />
        </ng-template>
        <ng-container *ngFor="let p of getProperties()">
          <app-object-editor
            *ngIf="isShowingNodeProperty(node, p)"
            [context]="getContext(node, p)"
          ></app-object-editor>
        </ng-container>
      </ng-template>
      <ng-template pTemplate="empty"> No records found </ng-template>
      <ng-template pTemplate="footer">{{ footer }}</ng-template>
      <p-scrollTop
        target="parent"
        [threshold]="20"
        icon="pi pi-arrow-up"
      ></p-scrollTop>
    </p-tree>
    <p-contextMenu
      #contextMenu
      styleClass="watched-tree-menu"
      [model]="contextMenuItems"
      appendTo="body"
    ></p-contextMenu>
  </p-accordionTab>
</p-accordion>
