import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BabymapRoutingModule } from './babymap-routing.module';
import { BabymapComponent } from './babymap/babymap.component';
import { WindowService } from './babymap/window-service/window.service';


@NgModule({
  declarations: [BabymapComponent],
  imports: [
    CommonModule,
    BabymapRoutingModule
  ],
  providers: [WindowService],
})
export class BabymapModule { }
