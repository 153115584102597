/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { Component, Input, OnInit } from '@angular/core';
import { LayerSpecification } from 'maplibre-gl';
import { LayersControl } from '../maplibre/layersControl';

@Component({
  selector: 'app-layers-ui',
  templateUrl: './layers-ui.component.html',
  styleUrls: ['./layers-ui.component.scss'],
})
export class LayersUiComponent implements OnInit {
  private _layersControl: LayersControl;

  @Input('layers')
  get layersControl(): LayersControl {
    return this._layersControl;
  }
  set layersControl(o: LayersControl) {
    this._layersControl = o;
    if(this._layersControl) {
      this.sourceItems = [];
      this._layersControl.getLayers().forEach((layer,id)=>{
        this.sourceItems.push(layer);
      });
    }
  }

  sourceItems: LayerSpecification[] = [];
  targetItems: LayerSpecification[] = [];

  constructor() {
    for(let i = 0; i < 12; i++) {
      const l1: LayerSpecification = {
        id: 'coucou'+i,type: 'line',source:'source'+i
      };
      this.sourceItems.push(l1);
    }
   }

  refresh() {

  };

  ngOnInit() {}

}
